import { BaseWidget, WidgetCategory, WidgetDefinition } from './base.types';

export enum PieSubType {
  Pie = 'PIE',
  Donut = 'DONUT',
  PieWithNeedle = 'PIE_WITH_NEEDLE',
}

export enum PieLegendStyles {
  Table = 'TABLE',
  Aside = 'ASIDE',
  Basic = 'BASIC',
  Off = 'OFF',
}

export enum PieBasicLegendPosition {
  Top = 'TOP',
  Bottom = 'BOTTOM',
}

export type PieLegend = {
  style: PieLegendStyles;
  position?: PieBasicLegendPosition;
  showValues?: boolean;
  showPercentage?: boolean;
};

export type PieDefinition = {
  legend: PieLegend;
  fieldsPalette: Record<string, string>;
  showTotals?: boolean;
};
export type PieWidget = BaseWidget & {
  category: WidgetCategory.Pie;
  type: PieSubType;
  definition: WidgetDefinition & { custom: PieDefinition };
};
export type DonutTypeWidget = PieWidget & {
  type: PieSubType.Donut;
};

export type PieTypeWidget = PieWidget & {
  type: PieSubType.Pie;
};
export type PieWithNeedleTypeWidget = PieWidget & {
  type: PieSubType.PieWithNeedle;
};

export type PieCategoryWidgetTypes = DonutTypeWidget | PieTypeWidget | PieWithNeedleTypeWidget;
