import React from 'react';
import { ColumnIconButtonProp, HeadCellType } from './types';

type TableContextType = {
  resizingColId?: string;
  headCells: HeadCellType[];
  columnWidths: Record<string, string | number>;
  rows: Record<string, unknown>[];
  filters: any;
  setFilters: any;
  hasHeaderFilters: boolean;
  onResizeColumn: (e: any, colId: any) => void;
  autoFitCol: (colId: any) => void;
  resizable: boolean;
  handleChangePage: (event: any, newPage: any) => void;
  containerRef?: React.MutableRefObject<any>;
  columnRefs: Record<string, any>;
  freeSpaceRef: React.MutableRefObject<any>;
  columnIconButtonProp?: ColumnIconButtonProp;
};

// eslint-disable-next-line import/prefer-default-export
export const TableContext = React.createContext<TableContextType>({
  autoFitCol(): void {},
  columnRefs: {},
  columnWidths: {},
  containerRef: undefined,
  filters: undefined,
  freeSpaceRef: {} as any,
  handleChangePage(): void {},
  hasHeaderFilters: false,
  headCells: [],
  onResizeColumn(): void {},
  resizable: false,
  rows: [],
  setFilters: undefined,
});
