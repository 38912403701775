import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { FeatureFlags, PAGE_PATHS } from '../types';
import avLazy from '../utils/AvLazy';
import { MODES } from '../views/Measurements/types';

const Measurements = avLazy(() => import('../views/Measurements/Measurements'));
const EditMeasurement = avLazy(() => import('../views/Measurements/EditMeasurement'));
const AuditMeasurement = avLazy(() => import('../views/Measurements/AuditMeasurement'));

const MeasurementsRoute = () => {
  const path = PAGE_PATHS.MEASUREMENTS;

  const {
    userPermissions: { isInternalRole },
    featureFlags,
  } = useAvContext();

  const allowedFFMeasurements = featureFlags[FeatureFlags.ShowMeasurementsPage];
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={isInternalRole || allowedFFMeasurements}>
            <Measurements />
          </ProtectedRoute>
        }
      />

      <Route
        path="create/"
        element={
          <ProtectedRoute isAllowed={isInternalRole || allowedFFMeasurements}>
            <EditMeasurement mode={MODES.CREATE_MODE} />
          </ProtectedRoute>
        }
      />

      <Route
        path="edit/:systemName"
        element={
          <ProtectedRoute isAllowed={isInternalRole || allowedFFMeasurements}>
            <EditMeasurement mode={MODES.EDIT_MODE} />
          </ProtectedRoute>
        }
      />

      <Route
        path="audit/:id"
        element={
          <ProtectedRoute isAllowed={isInternalRole}>
            <AuditMeasurement />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default MeasurementsRoute;
