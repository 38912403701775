import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { FeatureFlags, PAGE_PATHS } from '../types';
import avLazy from '../utils/AvLazy';

const Logs = avLazy(() => import('../views/Logs/NewVersion/Logs'));
const NewLogs = avLazy(() => import('../views/Logs/OldVersion/NewLogs'));

const DataExplorationRoute = () => {
  const {
    userPermissions: { hasAllowedPermission },
    featureFlags,
  } = useAvContext();
  const logsPath = PAGE_PATHS.LOGS;
  return (
    <Route path={logsPath}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path: logsPath })}>
            {featureFlags[FeatureFlags.NewLogsScreen] ? <Logs /> : <NewLogs />}
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default DataExplorationRoute;
