import { useMemo } from 'react';
import { startOfDay, subMonths } from 'date-fns';
import { entityViewConfig } from '../../utils/entityViewConfig';
import { useMeasurementsList } from '../Reports/hooks';

export const getDefaultStartDate = () => subMonths(startOfDay(new Date().setDate(1)), 2).toISOString();

export const useRemediationMeasurementOptions = () => {
  const { data: measurements } = useMeasurementsList({
    entityName: entityViewConfig.Ticket.entityTypeId,
    filterFunc: (v: any) => v.context.includes(entityViewConfig.Ticket.entityTypeId),
  });
  return useMemo(
    () =>
      (measurements || [])
        .filter(measurement => measurement.timeExpression)
        .reduce(
          (acc, { displayName, systemName }) => {
            const selectValue = `${systemName} AS ${systemName}`;
            acc.measurementOptions = [...acc.measurementOptions, { value: systemName, selectValue, title: displayName }];
            acc.selectValueMap[systemName] = selectValue;
            return acc;
          },
          {
            measurementOptions: [],
            selectValueMap: {},
          }
        ),
    [measurements]
  );
};
