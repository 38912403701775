import React from 'react';
import { alpha, useTheme } from '@mui/material';
import { Area, Bar, Cell, ComposedChart, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { abbreviateNumber } from '../../utils/Utils';

interface IFunnel {
  data?: Array<Record<string, any>>;
  height?: number | string;
  barSize?: number;
  labelsSize?: number | string;
}

const label = ({ value: [value, ratio], x, width, y, height, leftStyle, rightStyle, isInner = width > 51 }) => (
  <text x={x + width + (isInner ? (ratio ? 32 : -12) : 10)} y={y + height / 2 - 1} textAnchor={isInner ? 'end' : 'start'}>
    <tspan style={leftStyle(isInner)}>{abbreviateNumber(value)}</tspan>
    {ratio ? (
      <tspan style={rightStyle} dx={isInner ? 16 : 0}>
        {' '}
        ({ratio}:1)
      </tspan>
    ) : undefined}
  </text>
);

const Funnel: React.FC<IFunnel> = ({ data = [], height = 300, barSize = 24, labelsSize = 14 }) => {
  const {
    palette: { colors },
  } = useTheme();

  // @ts-ignore
  const tickLabel = ({ y, payload: { index, value } = {} }) => (
    <text
      y={y}
      textAnchor="start"
      style={{ fontSize: labelsSize, fill: colors.neutrals[index === 2 ? 600 : 500], alignmentBaseline: 'central' }}>
      {value}
    </text>
  );

  return (
    <ResponsiveContainer width="99%" height={height}>
      <ComposedChart data={data} layout="vertical" margin={{ top: 0, right: 10, bottom: 0, left: 45 }}>
        <XAxis type="number" hide scale="linear" domain={[0.01, 'auto']} />
        <YAxis type="category" dataKey="name" axisLine={false} ticks={undefined} tickLine={false} width={100} tick={tickLabel} />
        <Area dataKey="value" stroke="none" fill={alpha(colors.primary[400], 0.1)} />
        <Bar dataKey="value" barSize={barSize}>
          <>
            {data.map(({ name, color }) => (
              <Cell key={name} fill={color} />
            ))}
            <LabelList
              dataKey="displayValues"
              leftStyle={isInner => ({
                fill: isInner ? colors.neutrals[100] : colors.neutrals[800],
                fontWeight: 600,
                fontSize: 14,
                alignmentBaseline: 'central',
              })}
              rightStyle={{ fill: colors.neutrals[500], fontStyle: 'italic', fontSize: 12, alignmentBaseline: 'central' }}
              // @ts-ignore
              content={label}
            />
          </>
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default Funnel;
