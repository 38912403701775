import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BrowserRouter } from 'react-router-dom';
import AuthorizedApolloProvider from '../components/AuthorizedApolloProvider';
import AuthProvider from '../components/authProvider';
import AvThemeProvider from '../components/AvThemeProvider';
import { ErrorBoundary } from '../components/ErrorBoundary';
import AvContextProvider from './AvContextProvider';
import AvQueryProvider from './AvQueryProvider';
import AvSnackBarProvider from './AvSnackBarProvider';
import LayoutContextProvider from './LayoutContext';
import AvNotificationProvider from './NotificationProvider';
import OAuthContext from './OAuthContext';
import SearchContextProvider from './SearchContextProvider';
import ZendeskAuthProvider from './ZendeskAuthProvider';

const RootContext: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <BrowserRouter>
    <AvThemeProvider>
      <ErrorBoundary>
        <AvNotificationProvider>
          <AvQueryProvider>
            <OAuthContext>
              <AuthProvider>
                <AuthorizedApolloProvider>
                  <ZendeskAuthProvider>
                    <AvContextProvider>
                      <AvSnackBarProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <LayoutContextProvider>
                            <SearchContextProvider>{children}</SearchContextProvider>
                          </LayoutContextProvider>
                        </LocalizationProvider>
                      </AvSnackBarProvider>
                    </AvContextProvider>
                  </ZendeskAuthProvider>
                </AuthorizedApolloProvider>
              </AuthProvider>
            </OAuthContext>
          </AvQueryProvider>
        </AvNotificationProvider>
      </ErrorBoundary>
    </AvThemeProvider>
  </BrowserRouter>
);

export default RootContext;
