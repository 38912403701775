import { DateGap } from '../../utils/date.utils';

export type ExportDetails = {
  enabled: boolean;
  intervalExpression: string;
  deliveryConfig?: DeliveryConfig;
} & JSONDeliveryConfiguration;

export enum FileFormat {
  CSV_FORMAT = 'CSV_FORMAT',
  PDF_FORMAT = 'PDF_FORMAT',
  JSON_FORMAT = 'JSON_FORMAT',
  R360_JSON_FORMAT = 'R360_JSON_FORMAT',
  EXCEL_FORMAT = 'EXCEL_FORMAT',
}

export enum ChannelType {
  PUBLIC_CHANNEL = 'PUBLIC_CHANNEL',
  PRIVATE_CHANNEL = 'PRIVATE_CHANNEL',
}
export type JSONDeliveryConfiguration = {
  awsAccount?: string;
  region?: string;
  bucket?: string;
  roleArn?: string;
  path?: string;
  externalId?: string;
};

export type DeliveryConfig = {
  integrationId: string | null;
  title?: string;
  message?: string;
  type: DeliveryMethodOptions;
  attachmentMetadata?: {
    fileFormat?: FileFormat;
  };
  recipients?: string[];
  channel?: string;
  channelType?: ChannelType;
  channelId?: string;
  compressFile?: boolean;
};

export enum DeliveryMethodOptions {
  Email = 'EMAIL',
  S3 = 'S3',
  SLACK = 'SLACK',
  TEAMS = 'TEAMS',
}
export enum FieldTypes {
  Measurement = 'Measurement',
}

export const defaultExportDetails: ExportDetails = {
  enabled: false,
  intervalExpression: '0 0 * * *',
  deliveryConfig: {
    integrationId: null,
    type: DeliveryMethodOptions.Email,
    recipients: [],
    title: '',
    message: '',
    attachmentMetadata: {
      fileFormat: FileFormat.CSV_FORMAT,
    },
  },
};

export type ReportModal = {
  tempProjName: string;
  isOpen: boolean;
};

export enum MeasurementCategory {
  TIME_TO = 'TIME_TO',
  POPULATION_COUNT = 'POPULATION_COUNT',
  POPULATION_AGGS = 'POPULATION_AGGS',
  MATH_EXPRESSION = 'MATH_EXPRESSION',
  PERCENTAGE = 'PERCENTAGE',
  NUMERIC = 'NUMERIC',
}

export enum MeasurementUnit {
  Percentage = 'Percentage',
}

export enum FilterTimeType {
  CURRENT = 'CURRENT',
  HISTORICAL = 'HISTORICAL',
}

export const TimeBucket = {
  BUCKET_DAY: 'BUCKET_DAY',
  BUCKET_WEEK: 'BUCKET_WEEK',
  BUCKET_MONTH: 'BUCKET_MONTH',
};

export const TimeBucketToDateGap = {
  [TimeBucket.BUCKET_DAY]: DateGap.Day,
  [TimeBucket.BUCKET_WEEK]: DateGap.Week,
  [TimeBucket.BUCKET_MONTH]: DateGap.Month,
};

export const TimeBucketName = {
  [TimeBucket.BUCKET_DAY]: 'Day',
  [TimeBucket.BUCKET_WEEK]: 'Week',
  [TimeBucket.BUCKET_MONTH]: 'Month',
};

export const TimeBucketOrder = {
  [TimeBucket.BUCKET_DAY]: 1,
  [TimeBucket.BUCKET_WEEK]: 7,
  [TimeBucket.BUCKET_MONTH]: 30,
};

export const SortedTimeBucket = Object.keys(TimeBucket).sort((a, b) => TimeBucketOrder[a] - TimeBucketOrder[b]);

export const SortedTimeBucketOptions = SortedTimeBucket.map(option => ({ label: TimeBucketName[option], value: option }));

export const TimeBucketSet = new Set(SortedTimeBucket);
