import { keepPreviousData } from '@tanstack/react-query';
import useQuerySql from '../../hooks/useQuerySql';
import { generateOption } from './Utils';

export const useSourceOptions = sourceFilterWhere => {
  const sourceTypesSql = `SELECT distinct EXPLODE(asset.source_names) as name FROM uber_assets ${sourceFilterWhere}`;
  return useQuerySql({
    key: 'sourceTypes',
    sql: sourceTypesSql,
    options: { placeholderData: keepPreviousData },
  });
};

export const useOwnerOptions = ownerWhereParts => {
  const ownerSql = `SELECT DISTINCT asset.owner_id as owner FROM uber_assets ${
    ownerWhereParts.length ? `WHERE ${ownerWhereParts.join(' AND ')}` : ''
  } LIMIT 500`;
  return useQuerySql({
    key: 'owner',
    sql: ownerSql,
    options: { placeholderData: keepPreviousData },
    onSuccess: allOwners => allOwners.map(({ owner }) => (owner === null ? { title: 'No Owner', value: null } : generateOption(owner))),
  });
};

export const useAssetTypeOptions = assetFilterWhere => {
  const assetTypeSql = `SELECT DISTINCT asset.type as assetType
                          FROM uber_assets ${assetFilterWhere.length ? `WHERE ${assetFilterWhere}` : ''}`;
  return useQuerySql({
    key: 'assetType',
    sql: assetTypeSql,
    options: { placeholderData: keepPreviousData },
    onSuccess: allAssetType => [...new Set(allAssetType?.map(({ assetType }) => assetType).flat(1))].map(generateOption),
  });
};
