export enum AuditEntityType {
  DATA_SOURCE_INSTANCE = 'DATA_SOURCE_INSTANCE',
  USER = 'USER',
  ACCOUNT = 'ACCOUNT',
  RULE = 'RULE',
  LOOKUP_TABLE = 'CUSTOM_QUERY',
  PERMISSION_SETS = 'PERMISSION_SETS',
  GLOBAL_SCRIPT = 'GLOBAL_SCRIPT',
  SPF = 'ACCOUNT_AGG_PROJECTION_EVALUATION',
  UI_CONFIG = 'UI_CONFIG',
  DATA_MODEL_MAPPING = 'DATA_MODEL_MAPPING',
  USER_ROLE = 'USER_ROLE',
  INTEGRATION_INSTANCE = 'INTEGRATION_INSTANCE',
  INTEGRATION_INSTANCE_MAPPING = 'INTEGRATION_INSTANCE_MAPPING',
  USER_DASHBOARD_REPORT = 'USER_DASHBOARD_REPORT',
  ACCOUNT_MEASUREMENT = 'ACCOUNT_MEASUREMENT',
  GROUPING_RULE_SET = 'GROUPING_RULE_SET',
  SEVERITY_SCORING = 'SEVERITY_SCORING',
}

export type AuditEntry = {
  entryId: string;
  entityId: string;
  accountId: string;
  actorId: string;
  operation: string;
  oldRowData: object;
  newRowData: object;
  transactionId: string;
  dmlTimestamp: number;
};
