import React, { useMemo } from 'react';
import { alpha, Box, IconButton, useTheme } from '@mui/material';
import { flex } from '../../../components/AvThemeProvider';
import AvTable from '../../../components/Table/AvTable';
import useQuerySql from '../../../hooks/useQuerySql';
import { Filter } from '../../../types/filter.types';
import { extractKeyValues, recursiveKeyUpdate, uniqBy } from '../../../utils/Utils';
import { ReactComponent as Close } from '../../../assets/X.svg';

interface Props {
  filter: Filter;
  projectionName: string;
  onClose: () => void;
}

const projectionFields = {
  uber_findings: ['finding.description', 'finding.type', 'finding.source_names', 'finding.severity'],
};

const LIMIT = 5000;

const RulePreview: React.FC<Props> = ({ filter, projectionName, onClose }) => {
  const { palette }: any = useTheme();
  const fixedFilter = recursiveKeyUpdate(
    filter,
    'fieldName',
    value => value && (value.startsWith('findings.') || value.startsWith('alerts')), // kombina cause query doesnt know findings/alerts
    value => value && value.replace('findings.', 'finding.').replace('alerts.', 'alert.') // kombina cause query doesnt know findings/alerts
  );

  const filterFields = extractKeyValues(fixedFilter, 'fieldName');
  const fields = uniqBy([`${projectionName}._key`, ...filterFields, ...(projectionFields[projectionName] || [])], v => v);
  const headCells = fields.map(k => ({
    isKey: k === `${projectionName}._key`,
    id: k,
    label: k,
    sx: { background: filterFields.includes(k) ? alpha(palette.colors.primary[150], 0.5) : '' },
  }));
  const options = { gcTime: 0, enabled: fixedFilter !== undefined && filterFields.every(f => !!f) };
  const { data, isLoading } = useQuerySql({
    key: JSON.stringify(fixedFilter),
    sql: `select ${fields.join(', ')} from ${projectionName} limit ${LIMIT}`,
    additionalFilter: fixedFilter,
    options,
  });

  const slicedData = useMemo(() => (data ? data.slice(0, 1000) : []), [data]);

  // use this to get exact count when bug with filters is fixed
  // const { data: countData } = useQuerySql({
  //   key: `count-${JSON.stringify(fixedFilter)}`,
  //   sql: `select count(${projectionName}._key) as count from ${projectionName}`,
  //   additionalFilter: fixedFilter,
  //   options,
  // });

  const hasData = !isLoading && data?.length > 0;
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 64,
        px: 3,
        py: 3,
        left: 'var(--side-bar-width)',
        width: 'calc(100% - var(--side-bar-width))',
        zIndex: theme => theme.zIndex.drawer,
        maxHeight: '50%',
        background: 'white',
        height: '100%',
        '> :first-of-type': { borderTop: `5px solid ${palette.colors.neutrals[150]}` },
        overflow: 'auto',
      }}>
      <Box sx={{ my: 3 }} />
      <Box sx={{ ...flex.justifyBetweenCenter, gap: 0.5, fontSize: 14, color: palette.colors.neutrals[500], fontWeight: 400 }}>
        <Box sx={{ ...flex.itemsCenter, gap: 0.5 }}>
          <Box>{isLoading ? 'Loading Preview' : !hasData ? 'No findings match filter condition' : 'Preview '}</Box>
          {hasData && (
            <Box sx={{ ...flex.row, gap: 0.5, color: palette.colors.neutrals[800], flex: 1 }}>
              <Box sx={{ fontWeight: 600 }}>
                {data.length.toLocaleString()}
                {data.length === LIMIT ? '+' : ''}
              </Box>{' '}
              Matching Findings
            </Box>
          )}
        </Box>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{ my: 2 }}>
        Note! Preview does not account for rule order and may include findings that will eventually be grouped by other rules with higher
        priority in the rule set
      </Box>
      <Box sx={{ overflow: 'auto', height: '100%' }}>
        {(isLoading || !!data?.length) && (
          <AvTable
            rows={slicedData}
            headCells={headCells}
            hasHeaderFilters={false}
            size="xSmall"
            enableSelectionColor={false}
            allowEdit={false}
            loading={isLoading}
            resizable
            pagination
          />
        )}
      </Box>
    </Box>
  );
};

export default RulePreview;
