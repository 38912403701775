import React from 'react';
import { useParams } from 'react-router-dom';
import AuditLogs from '../../AuditLogs/AuditLogs';
import { AuditEntityType } from '../../AuditLogs/types';
import { useGroupingRulesSet } from './hooks';

const RuleSetAuditLogs = ({ projectionName }: { projectionName: string }) => {
  const { id } = useParams();
  const { data: rules = [], isLoading } = useGroupingRulesSet(projectionName);
  const currentRule = rules.find(r => r.id === id) || { name: 'Grouping' };
  const title = `"${currentRule.name}" Rule - Audit Logs `;

  return <AuditLogs id={id} entityType={AuditEntityType.GROUPING_RULE_SET} title={title} isQueryLoading={isLoading} />;
};

export default RuleSetAuditLogs;
