import React, { useContext, useState } from 'react';
import { gql } from '@apollo/client';
import { Alert, Box, Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AvDialog from '../../components/AvDialog';
import { flex } from '../../components/AvThemeProvider';
import { ItemWithLogo } from '../../components/ItemWithLogo';
import RadioButton from '../../components/RadioButton';
import { useAvContext } from '../../context/AvContextProvider';
import { NotificationContext } from '../../context/AvSnackBarProvider';
import { WorkflowType } from '../../context/SnackBar.types';
import { ReactComponent as Delete } from '../../assets/Delete.svg';

export enum SupportedSchedulesType {
  NONE = 'NONE',
  All = 'ALL',
  FULL_ONLY = 'FULL_ONLY',
}

export enum SyncMode {
  FULL_REFRESH = 'FULL_REFRESH',
  INCREMENTAL = 'INCREMENTAL',
}

export const syncModeMap = {
  incremental: 'Incremental',
  full_refresh: 'Full',
};

export const getSourceAuditTitle = dsiData => (
  <>
    <ItemWithLogo type={dsiData?.mappedSourceName} variant="sourcesMapByName" logoWidth={40} hideText />
    Audit Logs {dsiData?.name}
  </>
);

const options = [
  { title: 'Keep the data source, delete the underlying data', value: 'true' },
  { title: 'Delete all', value: 'false' },
];
interface DeleteDialogProps {
  dsis: any[];
  onClose(isApplied): unknown;
}
export const DeleteDSIsDialog: React.FC<DeleteDialogProps> = ({ dsis, onClose }) => {
  const { api } = useAvContext();
  const queryClient = useQueryClient();
  const { runInfo, setRunInfo } = useContext(NotificationContext);
  const [keepDsi, setKeepDsi] = useState(options[0].value);
  const { mutate } = useMutation({
    mutationFn: () =>
      api(DELETE, { options: { ids: dsis.map(({ id }) => id), keepDsi: keepDsi === 'true' } }).then(({ data }) => {
        const runInfoRes = Object.values(data)[0];
        (Array.isArray(runInfoRes) ? runInfoRes : [runInfoRes]).forEach(({ workflowRun: { id, runId } }) =>
          setRunInfo({
            wfId: id,
            runId,
            returnStatusOnly: true,
            workflowType: WorkflowType.DataDeletion,
            onSuccess: () => runInfo.length === 0 && queryClient.refetchQueries({ queryKey: ['sourcesManagement'] }),
          })
        );
      }),
  });
  const apply = () => {
    mutate();
    onClose(true);
  };

  return (
    <AvDialog
      open={!!dsis}
      onClose={() => onClose(false)}
      titleVariant="error"
      noPadding
      title={`Delete data from source${dsis.length > 1 ? 's' : ''}`}
      footer={
        <Box sx={{ ...flex.justifyEndCenter, gap: 2 }}>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button onClick={apply} variant="contained" color="error">
            <Delete />
            Delete
          </Button>
        </Box>
      }>
      <Box sx={{ px: 3, ...flex.col, gap: 2, maxWidth: 620 }}>
        <div>
          You are about to delete data from <span style={{ fontWeight: 600 }}>{dsis.map(({ name }) => name).join(', ')}</span>
          <Box sx={{ ...flex.col, mt: 3 }}>
            <span>Please choose one of the following options:</span>
            <RadioButton value={keepDsi} onChange={setKeepDsi} options={options} isHorizontal={false} />
          </Box>
        </div>
        <Alert severity="error" icon={false}>
          All data will be permanently deleted from the system and cannot be restored
        </Alert>
        Are you sure you want to delete?
      </Box>
    </AvDialog>
  );
};

const DELETE = gql`
  mutation ($ids: [String!]!, $keepDsi: Boolean) {
    deleteDsiData(dsiIds: $ids, keepDsi: $keepDsi) {
      workflowRun {
        id
        runId
      }
    }
  }
`;
