import React from 'react';
import { ItemWithLogo } from '../../components/ItemWithLogo';

export const renderOption = ({ name }) => <ItemWithLogo variant="sourcesMapByName" type={name} />;

export enum RulesTypes {
  SCHEDULED_QUERY_RULE = 'SCHEDULED_QUERY_RULE',
  DATA_RETRIEVAL_RULE = 'DATA_RETRIEVAL_RULE',
}

export const runOnOptions: SelectOption[] = [
  { title: 'Last 15 minutes', value: 0 },
  { title: 'Last hour', value: 1 },
  { title: 'Last 3 hours', value: 3 },
  { title: 'Last 6 hours', value: 6 },
];
export type SelectOption = {
  title: string;
  value: string | number;
};
export const getTimeOptions = (addCurrent = false, minuteInterval = 15) => {
  const currentDate = new Date();
  const hoursList: SelectOption[] = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += minuteInterval) {
      const formattedHour = (hour % 12 || 12).toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      const ampm = hour < 12 ? 'AM' : 'PM';
      const timeObject: SelectOption = {
        title: `${formattedHour}:${formattedMinute} ${ampm}`,
        value: `${hour}:${minute}`,
      };
      hoursList.push(timeObject);
    }
  }
  return addCurrent
    ? ([
        {
          title: 'current',
          value: `${currentDate.getHours()}:${currentDate.getMinutes()}`,
        },
        ...hoursList,
      ] as SelectOption[])
    : hoursList;
};
