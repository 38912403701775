export const defaultColumnsForEntity = {
  uber_findings: [
    'source_key',
    'type',
    'source_names',
    'state',
    'title',
    'severity',
    'severity_score',
    'asset_key',
    'cve_id',
    'first_seen',
    'last_seen',
    'grouping_key',
    'description',
  ],
  tickets: [
    '_key',
    'title',
    'type',
    'source_names',
    'severity',
    'severity_score',
    'current_status.name',
    'is_fixable',
    'assignee_id',
    'sla',
    'finding_count',
    'active_finding_count',
    'asset_count',
    'active_asset_count',
  ],
  uber_assets: [
    'source_key',
    'type',
    'source_names',
    'name',
    'os',
    'hostname',
    'site',
    'ip',
    'fqdn',
    'owner_id',
    'business_service',
    'critical_asset',
    'behind_firewall',
    'has_pii',
    'has_edr',
    'relevant_for_pci',
    'crown_jewel',
    'domain_controller',
  ],
  uber_org_entities: ['id', 'name', 'email', 'type', 'parent_id'],
  uber_global_vulnerabilities: [
    'source_key',
    'source_names',
    'cve',
    'cvss_3_1_base_score',
    'cvss_2_base_score',
    'epss_score',
    'cwe',
    'cpe',
    'kev',
    'rce',
    'description',
  ],
  uber_components: ['source_key', 'type', 'source_names', 'name', 'vendor', 'version', 'app_group'],
  uber_component_instances: ['source_key', 'source_names', 'asset_key', 'component_key', 'path'],
};

export const defaultFiltersForEntity = aggProjs => projName => {
  const defaultFiltersObj = {
    uber_findings: ['source_names', 'state', 'type', 'grouping_key'],
    tickets: ['source_names', 'type', 'assignee_id', 'current_status.name', 'is_fixable'],
    uber_assets: ['source_names', 'type', 'name', 'os'],
    uber_org_entities: ['email', 'type'],
    uber_global_vulnerabilities: ['cve', 'cpe', 'source_names'],
    uber_components: ['source_names', 'type', 'name', 'version'],
    uber_component_instances: ['source_names', 'asset_key', 'component_key', 'path'],
  };
  return (
    defaultFiltersObj[projName]
      ? defaultFiltersObj[projName].map(field => `${aggProjs[projName].pathAlias}.${field}`)
      : aggProjs[projName].fieldList.INTERACTIVE.slice(0, 3).map(f => f.value)
  ).reduce((obj, filter) => ({ ...obj, [filter]: [] }), {});
};
