import React from 'react';
import { keepPreviousData } from '@tanstack/react-query';
import AvFilters, { getOptionsByField } from '../../components/AvFilters';
import DataSourceSelect from '../../components/DataSourceSelect';
import { FieldFilter } from '../../components/FieldFilter';
import Select from '../../components/Select';
import SeveritySelect from '../../components/SeveritySelect';
import { useAvContext } from '../../context/AvContextProvider';
import useQuerySql from '../../hooks/useQuerySql';
import { useExcludedStates } from '../../utils/dashboardDataUtils';
import { entityViewConfig } from '../../utils/entityViewConfig';
import { severityOptionsWithNone } from '../../utils/severity.utils';
import { noop } from '../../utils/Utils';
import { useBuildWhereClause } from './hooks';
import { getFieldLabel, staticFiltersFieldsV2 } from './ticket.types';

interface TicketFiltersProps {
  filters: Record<string, any>;
  updateFilters: (field: string, val: any) => void;
  setFilters: React.Dispatch<React.SetStateAction<object>>;
  showSeverity?: boolean;
  size?: string;
  disabled?: boolean;
  overrideExtra?: string[];
  activeProjName: string;
  isActiveFilter?: boolean;
  onClearFilters?: () => void;
  currentTypeLabels?: { [key: string]: string };
  extraWhere?: string;
  fields?: any[];
}

type FixableOptionsType = { title: string; value: boolean }[];

const fixableOptions: FixableOptionsType = [
  { title: 'Yes', value: true },
  { title: 'No', value: false },
];

const TicketFilters: React.FC<TicketFiltersProps> = ({
  filters,
  showSeverity = true,
  size = 'xSmall',
  disabled,
  updateFilters,
  setFilters,
  overrideExtra = [],
  isActiveFilter,
  activeProjName,
  onClearFilters = noop,
  currentTypeLabels: ctl,
  extraWhere = '',
  fields = [],
}) => {
  const {
    accountEntities: { aggProjs, fieldMap },
  } = useAvContext();

  const {
    entityTypeId: { name: activeEntityId },
  } = aggProjs[activeProjName];
  const { pathAlias } = aggProjs[activeProjName];
  const statusField = `${pathAlias}.current_status.name`;
  const sourcesField = `${pathAlias}.source_names`;
  const severityField = `${pathAlias}.severity`;
  const fixableField = `${pathAlias}.is_fixable`;
  const commonProps = { size, disabled };
  const staticFilters = [...staticFiltersFieldsV2].map(f => `${pathAlias}.${f}`);

  const currentTypeLabels = ctl || entityViewConfig[activeEntityId].labelsV2;

  const excludedStates = useExcludedStates(activeProjName);
  const filterInactive = `${pathAlias}.state NOT IN ${excludedStates}`;

  const availableSourceOptionWhere = useBuildWhereClause({
    filters: { ...filters, [sourcesField]: [] },
    entityTypeId: activeEntityId,
    extra: [filterInactive, extraWhere],
    allowMetrics: true,
  });
  const { data: availableSourceOption = [], isLoading: isLoadingDetectionSources } = useQuerySql({
    key: 'detectionSources',
    sql: `SELECT distinct EXPLODE(${sourcesField}) as name FROM ${activeProjName} ${availableSourceOptionWhere}`,
    options: { placeholderData: keepPreviousData },
  });
  return (
    <AvFilters
      activeProjName={activeProjName}
      isActiveFilter={isActiveFilter}
      setFilters={newFilters => setFilters({ ...newFilters, ...(filters.headCells && { headCells: filters.headCells }) })}
      updateFilters={updateFilters}
      currentTypeLabels={currentTypeLabels}
      filters={filters}
      overrideExtra={[...overrideExtra, filterInactive]}
      staticFilters={staticFilters}
      fields={fields.length ? fields : aggProjs[activeProjName].fieldList.INTERACTIVE}
      onClearFilters={onClearFilters}>
      <>
        {activeProjName === entityViewConfig.Ticket.projectionName && (
          <Select
            showInput={false}
            label={getFieldLabel({ field: fixableField, fieldMap, projectionName: activeProjName })}
            variant="filter"
            isMultiple
            options={fixableOptions}
            {...commonProps}
            value={filters[fixableField]?.map(JSON.parse)}
            onChange={val => updateFilters(fixableField, val)}
          />
        )}
        <FieldFilter
          filters={filters}
          getOptions={getOptionsByField[statusField]}
          key={statusField}
          {...commonProps}
          onChange={updateFilters}
          field={statusField}
          overrideExtra={[...overrideExtra, extraWhere, filterInactive]}
          label={getFieldLabel({ field: statusField, fieldMap, projectionName: activeProjName })}
          activeProjName={activeProjName}
          isEnabled
        />
        {showSeverity && (
          <SeveritySelect
            {...commonProps}
            filterValue={filters[severityField] || []}
            onChange={val => updateFilters(severityField, val)}
            options={severityOptionsWithNone}
            label={getFieldLabel({ field: severityField, fieldMap, projectionName: activeProjName })}
          />
        )}
        <DataSourceSelect
          {...commonProps}
          filterValue={filters[sourcesField] || []}
          onChange={val => updateFilters(sourcesField, val)}
          options={availableSourceOption}
          selectProps={{ loading: isLoadingDetectionSources }}
          label={getFieldLabel({ field: sourcesField, fieldMap, projectionName: activeProjName })}
        />
      </>
    </AvFilters>
  );
};

export default TicketFilters;
