import { ComponentType, lazy } from 'react';

type ComponentPromise<T = any> = Promise<{ default: ComponentType<T> }>;

export default function avLazy(
  component: () => ComponentPromise,
  retries?: number,
  interval?: number
): React.LazyExoticComponent<React.ComponentType<any>> {
  return lazy(() => retry(component, retries, interval));
}

function retry(fn: () => ComponentPromise, retriesLeft = 4, interval = 500): ComponentPromise {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        const mode = import.meta.env.MODE;
        if (mode !== 'development') {
          setTimeout(() => {
            if (retriesLeft === 1) {
              reject(error);
              window.location.reload();
              return;
            }
            retry(fn, retriesLeft - 1, interval * 2).then(resolve, reject);
          }, interval);
        } else {
          throw error;
        }
      });
  });
}
