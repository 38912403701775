const categoryWidgets = ['timeSeries'];
export const DATE_DIM = 'date';

export const transformResponseToWidgetProps = ({ widgetType, response }) => {
  const res = {} as any;
  if (!response) {
    return res;
  }

  const dim = response.data.dims.filter(dim => dim !== DATE_DIM)?.[0];
  if (categoryWidgets.includes(widgetType)) {
    res.categories = Array.from(new Set(response.data.data.map(({ data }) => data[dim])));
  }
  const [valKey] = response.data.metrics;
  const [nameKey] = response.data.dims;

  if (widgetType === 'timeSeries') {
    res.processedData = Object.values(
      response.data.data.reduce((acc, { data }) => {
        if (!acc[data[DATE_DIM]]) {
          acc[data[DATE_DIM]] = {
            [DATE_DIM]: data[DATE_DIM],
          };
        }
        acc[data[DATE_DIM]][data[dim]] = data[response.data.metrics[0]];
        return acc;
      }, {})
    );

    res.valKey = valKey;
    res.dateKey = DATE_DIM;
  } else if (widgetType === 'pie') {
    res.totals = getTotalsFromResponse(response);
    res.processedData = response.data.data.map(({ data }) => data);
    res.valKey = valKey;
    res.nameKey = nameKey;
  }

  return res;
};

const getTotalsFromResponse = response =>
  response?.totals
    ? response?.totals?.data?.[0]?.data[response.totals.metrics?.[0]]
    : response?.data?.data?.[0]?.data[response.data.metrics?.[0]];

export const aggregateAllCounts = (counts, baseObj) =>
  Object.keys(counts).reduce((obj, key) => ({ ...obj, [key]: (obj[key] || 0) + counts[key] }), baseObj);

export function generateTopItemsPlusOthers(data, metricKey, numOfItems, customOthersHandler) {
  return data.reduce(
    (arr, { [metricKey]: name, total, ...counts }, i) =>
      i <= numOfItems
        ? [...arr, { [metricKey]: name, ...counts }]
        : [
            ...arr.slice(0, numOfItems),
            {
              ...arr.at(-1),
              ...(customOthersHandler ? customOthersHandler(counts, arr.at(-1), data) : aggregateAllCounts(counts, arr.at(-1))),
              [metricKey]: 'Others',
            },
          ],
    []
  );
}

export const getPieCustomLabelMx = ({ sign, innerRadius, showValue }) =>
  sign * Math.max(2.7 * innerRadius + (showValue ? 50 : 0), 3.7 * (100 - innerRadius));
