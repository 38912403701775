import React from 'react';
import { Box, Button, Divider, useMediaQuery, useTheme } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { flex } from '../../components/AvThemeProvider';
import AvTooltip from '../../components/AvTooltip';
import ScatterChartWidget from '../../components/Widgets/ScatterChartWidget';
import StackedBarSeverity from '../../components/Widgets/StackedBarSeverity';
import Widget from '../../components/Widgets/Widget';
import { useAvContext } from '../../context/AvContextProvider';
import { FeatureFlags } from '../../types';
import { useWidgetData } from '../../utils/dashboardDataUtils';
import { dotStyle, ellipsis } from '../../utils/Utils';
import { IRiskAndDrilldownWidgetProps } from './types';

const RiskAndDrilldownWidget: React.FC<IRiskAndDrilldownWidgetProps> = ({
  assetsList,
  selectedAsset,
  setSelectedAsset,
  slicerProps,
  scatterSql,
  drilldownSql,
}) => {
  const { featureFlags } = useAvContext();
  const theme = useTheme();
  const widgetData = useWidgetData();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1850));
  const isXsmallScreen = useMediaQuery(theme.breakpoints.down(1310));
  const stackedBarSeries = [
    {
      name: 'Risk',
      color: 'transparent',
      dataKey: 'risk',
      type: 'line',
      lineType: 'linear',
      dot: false,
      activeDot: false,
      shape: <div style={{ ...dotStyle(theme.palette.colors.neutrals[650]), flexShrink: 0, alignSelf: 'center' }} />,
    },
    {
      name: 'Assets',
      color: theme.palette.colors.neutrals[650],
      dataKey: 'ASSETS',
      type: 'line',
      lineType: 'linear',
      isRightAxis: true,
      shape: (
        <div
          style={{
            width: 16,
            height: 3,
            borderRadius: '7px',
            background: theme.palette.colors.neutrals[650],
            flexShrink: 0,
            alignSelf: 'center',
          }}
        />
      ),
    },
    {
      name: 'Total Findings',
      color: 'transparent',
      dataKey: 'totalFindings',
      type: 'line',
      lineType: 'linear',
      dot: false,
      activeDot: false,
      hide: true,
    },
    {
      name: 'Low',
      color: theme.palette.colors.blue[400],
      dataKey: 'Normalized.LOW',
      type: 'bar',
      stackId: 't',
    },
    {
      name: 'Medium',
      color: theme.palette.colors.yellow[400],
      dataKey: 'Normalized.MEDIUM',
      type: 'bar',
      stackId: 't',
    },
    {
      name: 'High',
      color: theme.palette.colors.orange[400],
      dataKey: 'Normalized.HIGH',
      type: 'bar',
      stackId: 't',
    },
    {
      name: 'Critical',
      color: theme.palette.colors.negative[400],
      dataKey: 'Normalized.CRITICAL',
      type: 'bar',
      stackId: 't',
    },
  ];

  const widgetsContent = (
    <>
      <Widget
        sx={{ flex: 1, p: 0, pt: 1 }}
        sliceProps={slicerProps}
        sql={scatterSql}
        transformData={data => widgetData.scatterAssetList.transformData(data, featureFlags[FeatureFlags.FormatNull])}
        queryOptions={{ placeholderData: keepPreviousData, enabled: !!selectedAsset?.id, gcTime: 0 }}>
        <ScatterChartWidget selectedId={selectedAsset?.id} />
      </Widget>
      <Widget
        historicalDataProps={{ hideText: false }}
        sx={{ flex: 1, p: 0, pt: 1 }}
        sliceProps={slicerProps}
        sql={drilldownSql}
        transformData={widgetData.riskScatter.transformData}
        queryOptions={{ placeholderData: keepPreviousData, enabled: !!assetsList?.length && !!selectedAsset?.id }}>
        <StackedBarSeverity
          options={assetsList}
          series={stackedBarSeries}
          selectedAssetId={selectedAsset?.id}
          setSelectedAsset={setSelectedAsset}
          hiddenLegends={['Risk', 'Total Findings']}
        />
      </Widget>
    </>
  );

  return (
    <Box sx={{ ...flex.row, gap: isSmallScreen ? 1 : 3, width: '100%', height: '100%' }}>
      <Box
        sx={{
          ...flex.row,
          gap: isSmallScreen ? 2 : 4,
          minWidth: isSmallScreen ? '80px' : '150px',
          flexShrink: 0,
          pt: 1,
        }}>
        <Box sx={{ ...flex.col, gap: '20px', backgroundColor: theme.palette.white.main }}>
          <Box
            sx={{
              whiteSpace: 'nowrap',
              fontSize: '16px',
              fontWeight: 600,
              color: theme.palette.colors.neutrals[500],
            }}>
            Showing Top 20
          </Box>
          <Box
            sx={{
              ...flex.colItemsStart,
              backgroundColor: theme.palette.white.main,
              gap: 1,
              ...(isSmallScreen ? { maxHeight: '410px' } : {}),
              width: isSmallScreen ? '160px' : '240px',
              overflowY: 'auto',
            }}>
            {assetsList?.map(asset => (
              <Button
                key={asset.id}
                disableRipple
                variant="filter"
                onClick={() => setSelectedAsset(asset)}
                sx={{
                  background: selectedAsset?.id === asset.id ? theme.palette.colors.neutrals[300] : 'transparent',
                  width: '100%',
                  ...flex.justifyStartCenter,
                  ...(isSmallScreen && { fontSize: '12px' }),
                }}>
                {asset.color && <Box sx={{ ...dotStyle(asset.color), flexShrink: 0 }} />}
                <Box sx={{ ...ellipsis, color: theme.palette.colors.neutrals[800], fontWeight: 400 }}>
                  <AvTooltip>{asset.title}</AvTooltip>
                </Box>
              </Button>
            ))}
          </Box>
        </Box>
        <Divider
          flexItem
          sx={{
            width: '1px',
            backgroundColor: theme.palette.colors.neutrals[350],
            overflow: 'hidden',
            height: '100%',
          }}
          orientation="vertical"
        />
      </Box>
      {isXsmallScreen ? (
        <Box sx={{ ...flex.col, width: '80%', height: '100%', overflow: 'hidden', gap: 5 }}>{widgetsContent}</Box>
      ) : (
        widgetsContent
      )}
    </Box>
  );
};

export default RiskAndDrilldownWidget;
