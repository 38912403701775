import { BaseWidget, WidgetCategory, WidgetDefinition } from './base.types';

export enum LineSubType {
  Spline = 'SPLINE',
  Line = 'LINE',
}

export enum LineLegendPosition {
  Top = 'TOP',
  Bottom = 'BOTTOM',
  None = 'NONE',
}

export type LineLegend = {
  position: LineLegendPosition;
};

export type LineCustomDefinition = {
  fieldsPalette: Record<string, string>;
  legend: LineLegend;
};

export type LineWidget = BaseWidget & {
  category: WidgetCategory.Line;
  definition: WidgetDefinition & { custom: LineCustomDefinition };
  type: LineSubType;
};

export type LineTypeWidget = LineWidget & {
  type: LineSubType.Line;
};

export type SplineTypeWidget = LineWidget & {
  type: LineSubType.Spline;
};

export type LineCategoryWidgetTypes = LineTypeWidget | SplineTypeWidget;
