import React from 'react';
import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { formatPercent } from '../../utils/dashboardDataUtils';
import { abbreviateNumber, dotStyle, generateStringList, isNullOrUndefined } from '../../utils/Utils';
import AvLegend from '../AvLegend.tsx';
import { flex } from '../AvThemeProvider.tsx';
import AvTooltip from '../AvTooltip.tsx';
import { ItemWithLogo } from '../ItemWithLogo';

const rangeStyle = {
  ...flex.row,
  width: '100%',
  '> div': {
    height: 24,
    cursor: 'pointer',
    borderStyle: 'solid',
    transition: theme =>
      theme.transitions.create(['border', 'box-shadow'], {
        duration: theme.transitions.duration.shortest,
      }),
    ':hover': {
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.22)',
    },
  },
};

function OverlappingRange({ data, total, legend, onChange, selectedIndex, overlappingAssetsBySource, name }) {
  return (
    <Box sx={rangeStyle}>
      {data.map((val, i) => {
        const percentage = formatPercent(val, total);
        const formatted = val.toLocaleString();
        const rectStyle = {
          width: val === 0 ? 0 : parseInt(percentage, 10) < 2 ? '10px' : percentage,
          backgroundColor: legend[i].color,
          opacity: overlappingAssetsBySource?.length && name !== overlappingAssetsBySource[0] ? 0.3 : 1,
          borderColor: theme => (selectedIndex === i ? theme.palette.colors.neutrals[800] : 'transparent'),
          borderWidth: val === 0 ? 0 : '2px',
        };

        const circle = <Box sx={dotStyle(legend[i].color)} />;
        return (
          <AvTooltip
            key={legend[i].name}
            variant="message"
            title={
              <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
                {circle} {legend[i].name}: {formatted} | {percentage}
              </Box>
            }>
            <Box sx={rectStyle} onClick={() => onChange(selectedIndex === i ? undefined : i)} />
          </AvTooltip>
        );
      })}
    </Box>
  );
}

OverlappingRange.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  total: PropTypes.number.isRequired,
  legend: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, color: PropTypes.string })).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number,
  overlappingAssetsBySource: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
};
OverlappingRange.defaultProps = {
  selectedIndex: undefined,
};

export default function OverlappingAssetsBySource({ data: [countsObj], dataSources, setQueryProps, isLoading, filters }) {
  const {
    palette: { colors },
  } = useTheme();

  const { overlappingAssetsBySource, seriesIndex } = filters;
  const selectedIndex = isNullOrUndefined(seriesIndex) ? -1 : parseInt(seriesIndex, 10);
  const series = useSeries(dataSources);

  const onChange = name => seriesIndex => {
    if (seriesIndex === undefined) {
      setQueryProps({ overlappingAssetsBySource: [], seriesIndex: [] });
    } else {
      setQueryProps({ overlappingAssetsBySource: [name], seriesIndex: [seriesIndex] });
    }
  };

  return (
    <Box sx={{ ...flex.col, pl: 3, pt: 1 }}>
      <Box sx={{ ...flex.col, gap: 1, position: 'sticky', top: 0, backgroundColor: colors.neutrals[150], pb: 2 }}>
        <Box
          sx={{
            ...flex.itemsBaseline,
            color: colors.neutrals[700],
            fontWeight: 600,
            fontSize: 18,
            whiteSpace: 'pre',
          }}>
          <span>Slice </span>
          <AvTooltip title={countsObj.totalAssets?.toLocaleString()}>
            <Typography variant="h3" sx={{ m: 0 }}>
              {isLoading ? (
                <Skeleton variant="text" height={36} width={50} />
              ) : (
                countsObj.totalAssets && abbreviateNumber(countsObj.totalAssets)
              )}
            </Typography>
          </AvTooltip>
          <span> Assets By Source</span>
        </Box>
        <AvLegend isHorizontal series={series} />
      </Box>
      <Box sx={{ ...flex.col, gap: 3, pr: 3 }}>
        {isLoading ? (
          <>
            <Skeleton variant="text" height={40} width="100%" />
            <Skeleton variant="text" height={40} width="100%" />
            <Skeleton variant="text" height={40} width="100%" />
          </>
        ) : (
          dataSources?.map(({ name }, index) => (
            <Box
              key={name}
              sx={{
                ...flex.itemsCenter,
                gap: 3,
                '> div:first-of-type': {
                  flexShrink: 0,
                  opacity: overlappingAssetsBySource?.length && name !== overlappingAssetsBySource[0] ? 0.3 : 1,
                },
              }}>
              <ItemWithLogo type={name} variant="sourcesMapByName" logoWidth={40} hideText />
              <OverlappingRange
                data={[
                  countsObj[`source${index + 1}all`] - countsObj[`source${index + 1}only`],
                  countsObj[`source${index + 1}only`],
                  countsObj.totalAssets - countsObj[`source${index + 1}all`],
                ]}
                total={countsObj.totalAssets}
                legend={series}
                onChange={onChange(name)}
                selectedIndex={overlappingAssetsBySource[0] === name ? selectedIndex : -1}
                overlappingAssetsBySource={overlappingAssetsBySource}
                name={name}
              />
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
}

OverlappingAssetsBySource.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  dataSources: PropTypes.arrayOf(PropTypes.shape()),
  setQueryProps: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool,
};

OverlappingAssetsBySource.defaultProps = {
  data: [{}],
  dataSources: undefined,
  isLoading: undefined,
};

export const useSeries = dataSources => {
  const {
    palette: { colors },
  } = useTheme();

  const getGenerateStringList = (name, isIn) => `asset.source_names${isIn ? ' IN ' : ' NOT IN '}(${generateStringList([name])})`;
  const getNotIncluded = name => generateStringList(dataSources.filter(st => st.name !== name).map(({ name }) => name));

  return [
    {
      name: 'Overlapping Data',
      color: colors.primary[300],
      query: name =>
        `${getGenerateStringList(name, true)} AND asset.source_names IN (${
          dataSources.length > 1 ? getNotIncluded(name) : "'avalor__nothing'"
        })`,
    },
    {
      name: 'Unique Data',
      color: colors.primary[400],
      query: name =>
        `${getGenerateStringList(name, true)} ${dataSources.length > 1 ? `AND asset.source_names NOT IN (${getNotIncluded(name)})` : ''}`,
    },
    { name: 'Not Covered', color: colors.neutrals[400], query: name => getGenerateStringList(name) },
  ];
};
