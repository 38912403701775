import React, { useEffect, useState } from 'react';
import { createFilterOptions } from '@mui/material';
import { generateOption, noop, uniqBy } from '../utils/Utils';
import Select from './Select';

const uniqueOptions = (options, value, isMultiple) =>
  uniqBy([...options, ...(isMultiple ? value : value ? [value] : []).map(generateOption)], ({ value }) => value);

const filter = createFilterOptions();

type SelectProps = any; // TODO remove when doing Select.tsx refactor
interface IOpenSelect {
  label?: string;
  value?: string | string[];
  onChange?: (value: string | string[]) => void;
  isMultiple?: boolean;
  optionsPromise?: () => Promise<string[]>;
  options?: string[] | { title: string; value: string }[];
  optionsUseQueryProps?: { options: any[]; isLoading: boolean };
}
export default function OpenSelect({
  label,
  isMultiple = false,
  value = isMultiple ? [] : '',
  onChange = noop,
  options = [],
  optionsPromise,
  optionsUseQueryProps,
  ...props
}: IOpenSelect & SelectProps) {
  const [innerOptions, setOptions] = useState(uniqueOptions(options, value, isMultiple));
  useEffect(() => {
    optionsPromise?.().then(opt => setOptions(prev => uniqueOptions(prev, opt, isMultiple)));
  }, []);

  useEffect(() => {
    setOptions(uniqueOptions(innerOptions, value, isMultiple));
  }, [typeof value === 'string' ? value : JSON.stringify(value)]);

  useEffect(() => {
    if (optionsUseQueryProps && !optionsUseQueryProps.isLoading && optionsUseQueryProps.options) {
      setOptions(() => uniqueOptions(optionsUseQueryProps.options, value, isMultiple));
    }
  }, [optionsUseQueryProps?.isLoading, optionsUseQueryProps?.options]);

  const checkValueDoesntExist = val => !innerOptions.find(({ title }) => title === val);
  const onAdd = (values, ...args) => {
    const newVal = isMultiple ? values.find(checkValueDoesntExist) : checkValueDoesntExist(values) && values;
    if (newVal) {
      setOptions(prev => [...prev, generateOption(newVal)]);
    }
    onChange(values, ...args);
  };

  const onAddBlur = newVal => {
    if (newVal) {
      setOptions(prev => [...prev, generateOption(newVal)]);
      onChange([...(value as string[]), newVal]);
    }
  };

  return (
    <Select
      label={label}
      value={value}
      onChange={onAdd}
      options={innerOptions}
      isMultiple={isMultiple}
      freeSolo
      getValueFunc={val => val?.value || val}
      getLabelFunc={val => val?.title || val}
      muiProps={{
        onBlur: isMultiple ? e => onAddBlur(e.target.value) : undefined,
        clearOnBlur: true,
        filterOptions: isMultiple
          ? undefined
          : (options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== '' && checkValueDoesntExist(params.inputValue)) {
                return [{ value: params.inputValue, title: `Add "${params.inputValue}"` }, ...filtered];
              }
              return filtered;
            },
      }}
      {...props}
    />
  );
}
