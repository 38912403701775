import React from 'react';
import { Box } from '@mui/material';
import { cellContentStyle } from '../utils/Utils';
import AvHighlight from './AvHighlight';
import { flex } from './AvThemeProvider';

const keyValueStyle = {
  lineHeight: '27px',
  fontSize: '13px',
  margin: 0,
  dt: {
    backgroundColor: theme => theme.palette.colors.primary[150],
    color: theme => theme.palette.colors.neutrals[800],
    padding: '2px 6px',
    borderRadius: '8px',
    fontWeight: 500,
    '+ dd': {
      marginLeft: '4px',
      wordBreak: 'break-word',
    },
  },
  'dt, dd': {
    display: 'inline',
  },
};
const spaceForNiceLineBreaks = <>&#8203;</>;

const horizontalStyle = linesLimit => ({
  ...cellContentStyle,
  WebkitLineClamp: linesLimit,
  wordBreak: 'break-word',
  '> span': { mr: 1 },
});
const verticalStyle = { span: { display: 'block', whiteSpace: 'normal' } };

interface AvKeyValueProps {
  value: Record<string, any>;
  orientation?: 'vertical' | 'horizontal';
  highlightText?: string[];
  limit?: number;
  linesLimit?: number;
  inlineActions?: any[];
}

const AvKeyValue: React.FC<AvKeyValueProps> = ({
  value = {},
  orientation = 'horizontal',
  highlightText,
  limit = 20,
  linesLimit = 4,
  inlineActions,
}) => {
  const values = limit === -1 ? Object.keys(value) : Object.keys(value).slice(0, limit);

  return (
    <Box component="dl" sx={{ ...keyValueStyle, ...(orientation === 'vertical' ? verticalStyle : horizontalStyle(linesLimit)) }}>
      {values.map(key => (
        <Box
          component="span"
          key={key}
          sx={
            inlineActions
              ? {
                  position: 'relative',
                  borderRadius: '8px',
                  py: '2px',
                  pl: '2px',
                  transition: theme =>
                    theme.transitions.create(['opacity', 'backgroundColor'], { duration: theme.transitions.duration.short }),
                  ':hover': { backgroundColor: theme => theme.palette.colors.neutrals[200] },
                  ':hover .showInlineAction ': { opacity: 1 },
                }
              : {}
          }>
          {spaceForNiceLineBreaks}
          <dt>{`${key}:`}</dt>
          <dd>
            <AvHighlight text={`${value[key]}` || ''} highlights={highlightText} />
          </dd>
          {inlineActions && (
            <Box
              className="showInlineAction"
              sx={{
                position: 'absolute',
                px: 2,
                right: 0,
                top: 0,
                zIndex: 1,
                height: '100%',
                opacity: 0,
                ...flex.row,
                gap: 2,
                backgroundImage: theme => `linear-gradient(to right, transparent, ${theme.palette.colors.neutrals[200]} 20%)`,
                transition: theme =>
                  theme.transitions.create(['opacity'], {
                    duration: theme.transitions.duration.short,
                  }),
              }}>
              {inlineActions.map(action => (
                <React.Fragment key={action.name}>{action(key, value[key])}</React.Fragment>
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default AvKeyValue;
