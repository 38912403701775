import React from 'react';
import { Box, Typography } from '@mui/material';
import { add, addMinutes, endOfDay, endOfMonth, endOfWeek, format, isValid, startOfDay, startOfMonth, startOfWeek, sub } from 'date-fns';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import { epochDateString, PeriodBehavior, RelativeDatePeriod, RelativeDateTitle, RelativeDateType } from './AvDateRangePicker.constants';
import { DateRangeValueType, DateValue, PredefinedPreset, Preset } from './types';

export const customPreset = 'Custom';
export const dateFormat = 'MMM dd, yyyy';
export const dateTimeFormat = 'MMM dd, yyyy, hh:mm a';
export const shortDateTimeFormat = 'MMM dd, hh:mm aaa';

export enum RelativeUnit {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

export enum RadioButtonValue {
  relative = 'relative',
  empty = 'empty',
}

export const getStartOfDay = (date = new Date()) => startOfDay(date);
export const getEndOfDay = (date = new Date()) => endOfDay(date);
export const getStartOfWeek = (date = new Date()) => startOfWeek(date, { weekStartsOn: 1 });
export const getStartUtc = (date: Date) => new Date(`${date.toISOString().split('T')[0]}T00:00:00.000Z`);
export const getEndUtc = (date: Date) => new Date(`${date.toISOString().split('T')[0]}T23:59:59.999Z`);
export const addLocalTimeChange = (date: Date) => new Date(addMinutes(date, -date.getTimezoneOffset()));
export const changeToLocal = (date: Date) => new Date(date.toISOString().split('Z')[0]);

export const fromDateMapping = {
  [PeriodBehavior.last]: {
    [RelativeUnit.MINUTES]: (minutes: number) => sub(new Date(), { minutes }),
    [RelativeUnit.HOURS]: (hours: number) => sub(new Date(), { hours }),
    [RelativeUnit.DAYS]: (days: number) => sub(getStartOfDay(), { days: days - 1 }),
    [RelativeUnit.WEEKS]: (weeks: number) => sub(getStartOfWeek(), { weeks: weeks - 1 }),
    [RelativeUnit.MONTHS]: (months: number) => startOfMonth(sub(new Date(), { months: months - 1 })),
  },
  [PeriodBehavior.previous]: {
    [RelativeUnit.MINUTES]: (minutes: number) => sub(new Date(), { minutes }),
    [RelativeUnit.HOURS]: (hours: number) => sub(new Date(), { hours }),
    [RelativeUnit.DAYS]: (days: number) => startOfDay(sub(getStartOfDay(), { days })),
    [RelativeUnit.WEEKS]: (weeks: number) => getStartOfWeek(sub(getStartOfDay(), { weeks })),
    [RelativeUnit.MONTHS]: (months: number) => startOfMonth(sub(getStartOfDay(), { months })),
  },
};

export const toDateMapping = {
  [PeriodBehavior.previous]: {
    [RelativeUnit.MINUTES]: (() => sub(new Date(), { minutes: 1 }))(),
    [RelativeUnit.HOURS]: (() => sub(new Date(), { hours: 1 }))(),
    [RelativeUnit.DAYS]: getEndOfDay(sub(getStartOfDay(), { days: 1 })),
    [RelativeUnit.WEEKS]: endOfWeek(getStartOfWeek(sub(getStartOfDay(), { weeks: 1 })), { weekStartsOn: 1 }),
    [RelativeUnit.MONTHS]: endOfMonth(sub(getStartOfDay(), { months: 1 })),
  },
};

export const getUtcRange = (from: Date, to: Date) => {
  const startUtc = getStartUtc(addLocalTimeChange(from));
  const endUtc = getEndUtc(addLocalTimeChange(to));
  return [startUtc, endUtc];
};

export const getLocalRange = (from: Date, to: Date) => [changeToLocal(from), changeToLocal(to)];

export const formatDate = (date, token) => (isValid(date) ? format(date, `${token}`) : '');
export const stringDefinition = definition => definition && `${definition.type}-${definition.count}-${definition.period}`;

export const validateValue = (date: DateRangeValueType, isRange: boolean): DateValue | null =>
  isRange
    ? ({
        from: date ? new Date(date.from) : getStartOfDay(),
        to: date?.to ? new Date(date.to) : getEndOfDay(),
      } as DateValue)
    : date === null
      ? date
      : date || { from: getStartOfDay() };

export const lastDaysPreset = count => ({
  title: `Last ${count} Days`,
  definition: { type: RelativeDateType.last, count, period: RelativeDatePeriod.days },
});
export const nextDaysPreset = count => ({
  title: `Next ${count} Days`,
  definition: { type: RelativeDateType.next, count, period: RelativeDatePeriod.days },
});

export const getPresets = ({ allowFutureDates }): PredefinedPreset[] => {
  const presets = [lastDaysPreset(7), lastDaysPreset(14), lastDaysPreset(30)];

  presets.push(
    {
      title: RelativeDateTitle.LastMonth,
      definition: { type: RelativeDateType.last, count: 1, period: RelativeDatePeriod.months },
    },
    {
      title: RelativeDateTitle.LastThreeMonths,
      definition: { type: RelativeDateType.last, count: 3, period: RelativeDatePeriod.months },
    }
  );

  if (allowFutureDates) {
    presets.push(nextDaysPreset(7), nextDaysPreset(14), nextDaysPreset(30));
  }

  return presets;
};

export const getInputValue = ({
  date,
  isRange,
  timeOptions,
  preset = {},
  customFormat = '',
}: {
  date: DateRangeValueType;
  isRange: boolean;
  timeOptions?: any[];
  preset?: Preset;
  customFormat?: string;
}) => {
  const format = customFormat || (timeOptions ? dateTimeFormat : dateFormat);
  return date
    ? isRange
      ? preset.type === RelativeDateType.before
        ? `Before ${formatDate(date.to, format)}`
        : `${formatDate(date.from, format)} - ${formatDate(date.to, format)}`
      : formatDate(date.from, format)
    : undefined;
};

const getFromDate = definition => {
  if ([RelativeDateType.last, RelativeDateType.previous].includes(definition.type)) {
    return fromDateMapping[definition.type.toUpperCase()][definition.period?.toUpperCase()]?.(definition.count);
  }

  if (definition.type === RelativeDateType.next) {
    return getStartOfDay();
  }

  return new Date(epochDateString);
};

const getToDate = definition => {
  if ([RelativeDatePeriod.hours, RelativeDatePeriod.minutes].includes(definition.period)) {
    return new Date();
  }
  if (definition.type === RelativeDateType.last) {
    return getEndOfDay();
  }

  if (definition.type === RelativeDateType.previous) {
    return toDateMapping[definition.type.toUpperCase()][definition.period?.toUpperCase()];
  }

  return add(getStartOfDay(), { [definition.period]: definition.count });
};

export const getDatesFromDefinition = definition => ({
  from: getFromDate(definition),
  to: getToDate(definition),
});

export const captionComponent = ({ displayMonth }: { displayMonth: Date }) => (
  <Typography variant="h7" sx={{ color: theme => theme.palette.colors.neutrals[600] }}>
    {format(displayMonth, 'MMM yyyy')}
  </Typography>
);

export const DatePickerStartAdornment = ({ label, icon, tooltipText }: { label?: string; icon?: any; tooltipText?: string }) => (
  <AvTooltip title={tooltipText}>
    <Box
      className="ofir"
      sx={{
        ...flex.itemsCenter,
        borderRadius: '8px',
        gap: 1,
        background: theme => theme.palette.colors.primary[150],
        svg: { fill: theme => theme.palette.colors.primary[500] },
        py: '2px',
        pl: 1.5,
        pr: 2,
        pointerEvents: 'all',
        cursor: 'default',
      }}>
      {icon}
      {label}
    </Box>
  </AvTooltip>
);
