import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useAvContext } from '../../context/AvContextProvider';
import { Filter, OperatorType, StringConditionType } from '../../types/filter.types';
import { insert } from '../../utils/Utils';
import { typeLabelMap } from '../../views/Sources/Mapping/mapping.types';
import AvAddOperandButton from '../AvAddOperandButton';
import { flex } from '../AvThemeProvider';
import FieldFilterBox from './FieldFilterBox';
import { boxWidth, ConditionType, filterOperatorOptions, getEmptyExpression, getEmptyFilterExpression } from './Utils';

interface RenderOperatorProps {
  filter: Filter;
  setFilter: any;
  fields: any[];
  isLoadingFields?: boolean;
  isVertical: boolean;
  size: 'xSmall' | 'small';
  defaultField: string | null;
  rootOperator: 'or' | 'and';
  level?: number;
  onToggleLogicalOperators: () => void;
  canAddNewFields?: boolean;
  canSelectFieldType?: boolean;
  supportParser?: boolean;
  autocompleteFilter?: Date[];
  canDeleteLast?: boolean;
  operatorOptions?: typeof filterOperatorOptions;
}

const RenderOperator: React.FC<RenderOperatorProps> = ({
  filter,
  setFilter,
  rootOperator,
  size,
  fields,
  isLoadingFields,
  isVertical,
  level = 0,
  defaultField,
  onToggleLogicalOperators,
  canAddNewFields,
  canSelectFieldType,
  supportParser,
  autocompleteFilter,
  canDeleteLast,
  operatorOptions,
}) => {
  const { palette } = useTheme();
  const { fieldMap } = useAvContext().accountEntities;

  const operatorColors = {
    and: palette.colors.primary[600],
    or: palette.colors.complementary[600],
  };

  const commonProps = {
    size,
    isVertical,
    fields,
    isLoadingFields,
    canAddNewFields,
    canSelectFieldType,
    supportParser,
    operatorOptions,
  };

  return filter[rootOperator]?.operands.map((innerFilter, index) => {
    const oppositeOperator = rootOperator === OperatorType.OR ? OperatorType.AND : OperatorType.OR;

    const setInnerFilter = value => {
      const operands = filter[rootOperator]!.operands.map((v, i) => (i === index ? value : v));
      setFilter({ [rootOperator]: { operands } });
    };

    const onDelete = () => {
      const operands = filter[rootOperator]!.operands.filter((v, i) => i !== index);
      if (operands.length === 0 && level === 0) {
        setFilter(
          canDeleteLast ? undefined : getEmptyFilterExpression(defaultField, supportParser ? StringConditionType.EQUALS : undefined)
        );
      } else if (operands.length === 1 && level !== 0) {
        setFilter(operands[0]);
      } else {
        setFilter({ [rootOperator]: { operands } });
      }
    };

    const addLogicalExpression = () => {
      const getOperands = () => {
        const typeCondition =
          ConditionType[
            typeLabelMap[fieldMap[(innerFilter.expression?.arrayCondition?.underlying || innerFilter.expression)?.fieldName || '']?.type]
          ];
        const newOperands: Filter[] = filter[rootOperator]!.operands;
        if (level === 0) {
          return newOperands.map((v, i) =>
            i === index
              ? {
                  [oppositeOperator]: {
                    operands: [
                      v,
                      getEmptyExpression(
                        defaultField,
                        typeCondition,
                        supportParser ? StringConditionType.EQUALS : undefined,
                        fieldMap[defaultField!]?.repeated
                      ),
                    ],
                  },
                }
              : v
          );
        }
        return insert(
          newOperands,
          index + 1,
          getEmptyExpression(
            defaultField,
            typeCondition,
            supportParser ? StringConditionType.EQUALS : undefined,
            fieldMap[defaultField!]?.repeated
          )
        );
      };
      setFilter({
        [rootOperator]: {
          operands: getOperands(),
        },
      });
    };

    return (
      // eslint-disable-next-line react/no-array-index-key
      <Box key={index} sx={{ borderLeft: `2px solid ${operatorColors[rootOperator]}`, ml: level * 2 }}>
        {index > 0 && (filter[rootOperator]?.operands?.length || 0) > index && (
          <Box
            sx={{
              ...flex.itemsStart,
              my: '-4px',
              ml: isVertical ? `${boxWidth / 2}px` : `${boxWidth / 4}px`,
            }}>
            <AvAddOperandButton style={{ translate: '-50%' }} size={size} operator={rootOperator} onClick={onToggleLogicalOperators} />
          </Box>
        )}
        {Object.keys(innerFilter)[0] === 'expression' ? (
          <FieldFilterBox
            {...commonProps}
            autocompleteFilter={autocompleteFilter}
            index={index}
            rootOperator={rootOperator}
            setFilter={setInnerFilter}
            level={level}
            filter={innerFilter}
            onDelete={onDelete}
            addLogicalExpression={addLogicalExpression}
            canSelectFieldType={canSelectFieldType}
          />
        ) : (
          <RenderOperator
            {...commonProps}
            autocompleteFilter={autocompleteFilter}
            defaultField={defaultField}
            filter={innerFilter}
            setFilter={setInnerFilter}
            rootOperator={oppositeOperator}
            level={level + 1}
            onToggleLogicalOperators={onToggleLogicalOperators}
          />
        )}
      </Box>
    );
  });
};

export default RenderOperator;
