import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useAvQuery } from '../../API/useAvQuery';
import { useAvContext } from '../../context/AvContextProvider';

const GET_LOGS_LIST = gql`
  query ($entity: AuditEntityType!, $entityId: String!, $pageable: Pageable) {
    getAuditEntriesById(entity: $entity, entityId: $entityId, pageable: $pageable) {
      content {
        entryId
        entityId
        accountId
        actorId
        operation
        oldRowData
        newRowData
        transactionId
        dmlTimestamp
      }
      totalPages
      totalElements
    }
  }
`;

export const useAuditLogs = ({ id, entityType, pageVars, onError }) => {
  const { api } = useAvContext();
  return useAvQuery({
    queryKey: ['getAuditLogs', id, pageVars.pageNumber],
    queryFn: () =>
      api(GET_LOGS_LIST, { options: { entity: entityType, entityId: id, pageable: pageVars }, onError }).then(({ data }) => {
        const result = data?.getAuditEntriesById || [];
        return {
          totalElements: result.totalElements,
          rowsFromQuery: result.content || result,
        };
      }),

    enabled: !!id,
    refetchInterval: 30000,
    gcTime: 0,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  });
};
const GET_ALL_LOGS_LIST = gql`
  query ($entity: AuditEntityType!, $pageable: Pageable) {
    getAuditEntriesByAccountId(entity: $entity, pageable: $pageable) {
      content {
        entryId
        entityId
        accountId
        actorId
        operation
        oldRowData
        newRowData
        transactionId
        dmlTimestamp
      }
      totalPages
      totalElements
    }
  }
`;
export const useAuditLogsAll = ({ id, entity, pageVars, onSuccess }) => {
  const { api } = useAvContext();
  return useAvQuery({
    queryKey: ['getAuditLogsAll', id],
    queryFn: () =>
      api(GET_ALL_LOGS_LIST, {
        options: {
          entity,
          pageable: pageVars,
        },
      }).then(({ data }) => onSuccess(data?.getAuditEntriesByAccountId?.content || [])),
    enabled: !!id,
    refetchInterval: 30000,
    gcTime: 0,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  });
};

export const useAuditNavigation = ({ id, path }) => {
  const navigate = useNavigate();
  const {
    getPathName,
    userPermissions: { isInternalRole },
  } = useAvContext();
  return {
    menuOptions: isInternalRole ? [{ title: 'Audit Logs', onClick: () => navigate(getPathName(path, `/audit/${id}`)) }] : undefined,
  };
};

export const buildPagination = ({ page, pageSize }: { page: number; pageSize?: number }) => ({
  pageNumber: +page || 0,
  pageSize: pageSize || 20,
});
