import React, { useEffect, useMemo } from 'react';
import { alpha, Box, Button, Collapse, ListItem, ListItemIcon, ListItemText, listItemTextClasses, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useAvContext } from '../../context/AvContextProvider';
import { PathFeatureFlag } from '../../types';
import { SubMenuItem } from '../../types/sideBar.types';
import { iconSize } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import { ReactComponent as ArrowDown } from '../../assets/Arrow Down Nav.svg';
import { ReactComponent as Bullet } from '../../assets/Bullet.svg';

const ArrowDownIcon = isCollapse => (
  <ArrowDown style={{ ...iconSize(16), transform: `rotate(${isCollapse ? 180 : 0}deg)` }} className="showOnHover" />
);

interface Props extends SubMenuItem {
  isOpen: boolean;
  openedSubMenus?: any[];
  setOpenedSubMenus: React.Dispatch<React.SetStateAction<any[]>>;
  ffForDisplaying?: string;
  ffForHiding?: string;
}

const SideBarItem: React.FC<Props> = ({
  path,
  name,
  icon,
  isOpen,
  subMenu,
  permission,
  openedSubMenus = [],
  setOpenedSubMenus,
  ffForDisplaying = '',
  ffForHiding = '',
}) => {
  const location = useLocation();
  const theme = useTheme();
  const {
    getPathName,
    featureFlags,
    selectedApp,
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const pathname = getPathName(path);

  const isSubMenuExpanded = useMemo(
    () => openedSubMenus.some(v => v.name === name && v.app === selectedApp),
    [openedSubMenus, name, selectedApp]
  );

  const isSomeSubMenuSelected = useMemo(
    () => subMenu?.some(subItem => location.pathname.startsWith(`${pathname}/${subItem.path}`)),
    [subMenu, location.pathname, pathname]
  );
  const handleChangeSubMenuExpanded = () =>
    setOpenedSubMenus(
      openedSubMenus.some(v => v.name === name)
        ? openedSubMenus.filter(v => v.name !== name)
        : [...openedSubMenus, { name, app: selectedApp }]
    );

  useEffect(() => {
    if (location.pathname.includes(`/${path}/`)) {
      setOpenedSubMenus([{ name, app: selectedApp }]);
    }
  }, [location.pathname]);

  const subMenusWithPermissions = subMenu
    ? subMenu.filter(
        ({ path }) =>
          !(
            !hasAllowedPermission({ path, app: selectedApp, permission }) ||
            (PathFeatureFlag[path] && !featureFlags[PathFeatureFlag[path]])
          )
      )
    : [];

if (
  (ffForHiding && !featureFlags[ffForHiding]) ||
  (ffForDisplaying && featureFlags[ffForDisplaying]) ||
  (!ffForDisplaying && !ffForHiding)
) {  
return (
    !(
      (!subMenu && !hasAllowedPermission({ path, app: selectedApp, permission })) ||
      (PathFeatureFlag[path] && !featureFlags[PathFeatureFlag[path]])
    ) &&
      (subMenu ? (
        subMenusWithPermissions.length > 1 ? (
          <Box sx={{ ...subMenuContainer(theme, isSubMenuExpanded, isSomeSubMenuSelected) }}>
            <ListItem
              sx={{ ...subMenuParent(theme, isOpen) }}
              onClick={handleChangeSubMenuExpanded}
              component={Button}
              selected={isSomeSubMenuSelected}>
              <ListItemIcon sx={listItemIconStyle}>{icon}</ListItemIcon>
              <Collapse in={isOpen} orientation="horizontal" timeout={theme.transitions.duration.complex} sx={{ flexGrow: 1 }}>
                <ListItemText>{name}</ListItemText>
              </Collapse>
              {isOpen && ArrowDownIcon(isSubMenuExpanded)}
            </ListItem>
            <Collapse in={isSubMenuExpanded} orientation="vertical" timeout={theme.transitions.duration.standard}>
              <Box sx={{ gap: 1, py: 1, ...(isOpen ? { ...flex.col } : { ...flex.colCenter }) }}>
                {subMenusWithPermissions.map(subItem =>
                  isOpen ? (
                    <ListItem
                      sx={{ ...item(theme), ...flex.itemsCenter, pl: '18px' }}
                      selected={location.pathname.startsWith(`${pathname}/${subItem.path}`)}
                      component={Link}
                      key={subItem.path}
                      to={getPathName(`${path}/${subItem.path}`)}>
                      <Collapse in={isOpen} orientation="horizontal" timeout={theme.transitions.duration.short}>
                        <ListItemText>{subItem.name}</ListItemText>
                      </Collapse>
                    </ListItem>
                  ) : (
                    <ListItem
                      sx={{ ...item(theme), ...flex.itemsCenter }}
                      key={subItem.path}
                      selected={location.pathname.startsWith(`${pathname}/${subItem.path}`)}>
                      <Bullet />
                    </ListItem>
                  )
                )}
              </Box>
            </Collapse>
          </Box>
        ) : subMenusWithPermissions.length === 1 ? (
          <ListItem
            sx={item(theme)}
            selected={location.pathname.startsWith(`${pathname}/${subMenusWithPermissions[0].path}`)}
            component={Link}
            to={getPathName(`${path}/${subMenusWithPermissions[0].path}`)}>
            <ListItemIcon sx={listItemIconStyle}>{icon}</ListItemIcon>
            <Collapse in={isOpen} orientation="horizontal" timeout={theme.transitions.duration.short}>
              <ListItemText>{subMenusWithPermissions[0].name}</ListItemText>
            </Collapse>
          </ListItem>
        ) : null
      ) : (
        <ListItem
          sx={item(theme)}
          selected={location.pathname.startsWith(pathname)}
          component={Link}
          to={pathname}
          onClick={() => {
            setOpenedSubMenus([]);
          }}>
          <ListItemIcon sx={listItemIconStyle}>{icon}</ListItemIcon>
          <Collapse in={isOpen} orientation="horizontal" timeout={theme.transitions.duration.short}>
            <ListItemText>{name}</ListItemText>
          </Collapse>
        </ListItem>
      ))
    );
  }
  return null;
};
export default SideBarItem;

const listItemIconStyle = {
  minWidth: 'inherit',
  height: 20,
  width: 20,
  svg: {
    height: 'inherit',
    width: 'inherit',
  },
};

export const item = theme => ({
  ...flex.row,
  height: 32,
  width: 'auto',
  padding: '7px',
  margin: '0px 18px',
  gap: 1,
  borderRadius: '12px',
  color: theme.palette.colors.neutrals[700],
  svg: {
    color: theme.palette.colors.neutrals[500],
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  transition: theme.transitions.create(['color', 'background-color'], {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover': {
    backgroundColor: alpha(theme.palette.colors.neutrals[600], 0.1),
    color: theme.palette.colors.neutrals[650],
    svg: {
      color: theme.palette.colors.neutrals[800],
    },
  },
  '&.Mui-selected': {
    [`.${listItemTextClasses.root} .MuiTypography-root`]: {
      fontWeight: 600,
    },
    backgroundColor: alpha(theme.palette.colors.primary[500], 0.1),
    color: theme.palette.colors.primary[500],
    svg: {
      color: theme.palette.colors.primary[500],
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.colors.primary[500], 0.15),
    },
  },

  [`.${listItemTextClasses.root}`]: {
    overflow: 'hidden',
  },
});

export const subMenuContainer = (theme, isSubMenuOpened, isSomeSubMenuSelected) => ({
  ...((isSubMenuOpened || isSomeSubMenuSelected) && { borderRadius: '12px' }),
  ml: 2.5,
  mr: 2,
  backgroundColor: isSomeSubMenuSelected
    ? theme.palette.colors.primary[100]
    : isSubMenuOpened
      ? theme.palette.colors.neutrals[200]
      : undefined,
});

export const subMenuParent = (theme, isOpen) => ({
  ...flex.row,
  height: 32,
  borderRadius: '12px',
  margin: '0px 0px',
  padding: '7px',
  width: isOpen ? '100%' : '20px',
  color: theme.palette.colors.neutrals[700],
  svg: {
    color: theme.palette.colors.neutrals[500],
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  '& .showOnHover': {
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  '&:hover .showOnHover': {
    opacity: 1,
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.colors.neutrals[600], 0.1),
    color: theme.palette.colors.neutrals[650],
    svg: {
      color: theme.palette.colors.neutrals[800],
    },
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.colors.primary[100],
    color: theme.palette.colors.primary[500],
    svg: {
      color: theme.palette.colors.primary[500],
    },
  },
});
