import React from 'react';
import { Box, LinearProgress, Skeleton, Typography, useTheme } from '@mui/material';
import * as PropTypes from 'prop-types';
import useElementOnScreen from '../../hooks/useElementOnScreen.tsx';
import useQuerySql from '../../hooks/useQuerySql.ts';
import { dotStyle, isNullOrUndefined } from '../../utils/Utils';
import AvTag from '../AvTag.tsx';
import { flex } from '../AvThemeProvider.tsx';
import AvTooltip from '../AvTooltip.tsx';
import { ErrorBoundary } from '../ErrorBoundary';
import { ErrorLoadingWidget, HistoricDataIndicator, NoDataFoundWidget, WidgetTitle } from './layout.components.tsx';
import { ReactComponent as FilterIcon } from '../../assets/Filter.svg';

const filterIcon = <FilterIcon strokeWidth={0.5} style={{ width: 16, height: 16 }} />;

export const chartStyle = {
  ...flex.col,
  flex: 1,
  position: 'relative',
  overflow: 'auto',
  maxHeight: '100%',
  '.recharts-surface': {
    pointerEvents: 'auto',
    height: 'auto',
    width: 'auto',
    'text:not(.custom-chart-text)': {
      fontSize: '12px',
      fill: theme => theme.palette.colors.neutrals[500],
    },
    // '.recharts-yAxis': {
    //   transform: 'translate(15px,0)',
    // },
    '.recharts-xAxis': {
      transform: 'translate(0,5px)',
    },
  },
};

function SliceIndicator({ data, isSlicer, hideText }) {
  const theme = useTheme();
  if (isNullOrUndefined(isSlicer)) {
    return null;
  }
  const color = isSlicer ? '#fff' : theme.palette.colors.primary[500];
  const tooltipContent = (
    <Box sx={{ ...flex.colItemsStart }}>
      {data.map(item => (
        <Box key={item.value} sx={{ ...flex.itemsCenter, gap: 1 }}>
          <Box sx={dotStyle(item.color)} />
          <Box>{item.value}</Box>
        </Box>
      ))}
    </Box>
  );
  return (
    <AvTooltip title={isSlicer ? tooltipContent : hideText ? 'Sliced' : ''}>
      <div>
        <AvTag
          text={
            <>
              {filterIcon}
              {!hideText && (isSlicer ? 'Slicer' : 'Sliced')}
            </>
          }
          color={color}
          bg={isSlicer ? theme.palette.colors.primary[500] : theme.palette.colors.primary[150]}
          sx={{ ...flex.itemsCenter, gap: '6px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        />
      </div>
    </AvTooltip>
  );
}

SliceIndicator.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  isSlicer: PropTypes.bool,
  hideText: PropTypes.bool,
};

SliceIndicator.defaultProps = {
  data: [],
  isSlicer: undefined,
  hideText: false,
};

function Widget({
  children,
  title,
  sql,
  metricKey,
  metricName,
  valLabel,
  valKey,
  height,
  width,
  hideTitle,
  titleMargin,
  titleVariant,
  background,
  transformData,
  onError,
  sx,
  queryOptions,
  sliceProps,
  historicalDataProps,
  errorProps,
  muteErrors,
  noDataProps,
  dateFormat,
  showTotalRows,
}) {
  const { ref: visibilityRef, isVisible } = useElementOnScreen({});
  const { data, totals, isLoading, isRefetching, errors, isPreviousData } = useQuerySql({
    key: title,
    sql,
    onSuccess: transformData,
    onError,
    muteErrors,
    showTotals: showTotalRows,
    options: { ...queryOptions, ...(!isVisible && { enabled: false }) },
  });
  const loading = isLoading && !isPreviousData;
  const isError = errorProps?.forceShowError || errors.length;
  const shouldRender = !sql || (!loading && Object.keys(data || {}).length > 0 && !isError);
  const isNoData = isVisible && !isLoading && !isError && data?.length === 0;
  const isErrorQuery = !!sql && !!isError;
  const isSkeleton = isNullOrUndefined(data) || isLoading;

  return (
    <Box
      ref={visibilityRef}
      sx={{
        ...chartStyle,
        width,
        minHeight: isLoading ? Number(height) || 'auto' : height,
        p: background === 'transparent' ? 0 : 3,
        pt: background === 'transparent' ? 0 : 2,
        background: theme => background || theme.palette.white.main,
        ...sx,
        ...(isLoading && { pb: 3 }),
      }}>
      <ErrorBoundary fallbackComponent={<ErrorLoadingWidget {...errorProps} />}>
        {title && !hideTitle ? (
          titleVariant ? (
            <Typography sx={{ mb: titleMargin }} variant={titleVariant}>
              {title}
            </Typography>
          ) : (
            <WidgetTitle marginBottom={titleMargin}>{title}</WidgetTitle>
          )
        ) : null}
        {!isErrorQuery && isSkeleton && (
          <Skeleton animation="wave" variant="rectangular" sx={{ background: theme => theme.palette.colors.neutrals[300], flex: 1 }} />
        )}
        {isNoData && <NoDataFoundWidget showImage {...noDataProps} />}
        {isErrorQuery && <ErrorLoadingWidget {...errorProps} />}
        {!isSkeleton && isRefetching && <LinearProgress sx={{ top: 0, left: 0, position: 'absolute', width: '100%', height: '2px' }} />}
        <Box
          sx={{
            ...flex.row,
            position: 'absolute',
            right: !isNullOrUndefined(sliceProps?.isSlicer) ? 0 : '24px',
            top: '20px',
            flexDirection: 'row-reverse',
            gap: 0.5,
          }}>
          {sliceProps && <SliceIndicator {...sliceProps} />}
          {historicalDataProps && <HistoricDataIndicator {...historicalDataProps} />}
        </Box>
        {shouldRender &&
          React.cloneElement(children, {
            ...(sql && { data }),
            ...(showTotalRows && { totals }),
            isLoading: isLoading || isRefetching,
            metricKey,
            metricName,
            valLabel,
            valKey,
            sql,
            dateFormat,
          })}
      </ErrorBoundary>
    </Box>
  );
}

export default Widget;
Widget.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  titleVariant: PropTypes.string,
  sql: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  metricKey: PropTypes.string,
  metricName: PropTypes.string,
  valLabel: PropTypes.string,
  valKey: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideTitle: PropTypes.bool,
  titleMargin: PropTypes.number,
  background: PropTypes.string,
  transformData: PropTypes.func,
  onError: PropTypes.func,
  sx: PropTypes.shape(),
  queryOptions: PropTypes.shape(),
  sliceProps: PropTypes.shape(),
  historicalDataProps: PropTypes.shape(),
  errorProps: PropTypes.shape(),
  muteErrors: PropTypes.bool,
  noDataProps: PropTypes.shape(),
  dateFormat: PropTypes.string,
  showTotalRows: PropTypes.bool,
};

Widget.defaultProps = {
  title: '',
  titleVariant: undefined,
  sql: undefined,
  metricKey: undefined,
  metricName: undefined,
  valLabel: undefined,
  valKey: undefined,
  height: 'auto',
  width: '100%',
  hideTitle: undefined,
  titleMargin: 2,
  background: undefined,
  sx: {},
  transformData: d => d,
  onError: undefined,
  queryOptions: undefined,
  sliceProps: undefined,
  historicalDataProps: undefined,
  errorProps: {},
  muteErrors: false,
  noDataProps: {},
  dateFormat: 'MMM',
  showTotalRows: false,
};
