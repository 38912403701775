import React, { useMemo } from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { noop } from '../../utils/Utils';
import AvLegend from '../AvLegend.tsx';
import { flex } from '../AvThemeProvider.tsx';
import Select from '../Select';
import AvComposedWidget from './AvComposedWidget';
import { ReactComponent as Plus } from '../../assets/Plus.svg';
import { ReactComponent as X } from '../../assets/X.svg';

function KeyMetricsOverTime({
  data,
  dateFormat,
  metrics,
  updateFilters,
  limit,
  labels,
  metricOptions,
  additionalAxis,
  disabledTooltipText,
  allowSelection,
  chartColors: customChartColors,
}) {
  const { palette } = useTheme();
  const chartColors = customChartColors || [
    palette.colors.primary[300],
    palette.colors.primary[400],
    palette.colors.complementary[400],
    palette.colors.blue[500],
    palette.colors.blue[200],
    palette.colors.yellow[200],
    palette.colors.primary[200],
    palette.colors.blue[600],
    palette.colors.blue[700],
    palette.colors.complementary[650],
    palette.colors.orange[600],
    palette.colors.blue[700],
    palette.colors.neutrals[500],
  ];

  const series = useMemo(
    () =>
      metrics.reduce((acc, m, index) => {
        const metricOption = metricOptions.find(o => o.value === m);
        if (metricOption) {
          const { title: name, axisLabel } = metricOption;
          const isAxisExists = acc.find(a => a.axisLabel === axisLabel);
          acc.push({
            name,
            axisLabel,
            component: (
              <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
                {name}
                {allowSelection && metrics.length > 1 && (
                  <IconButton
                    onClick={() => {
                      updateFilters(
                        'keyMetrics',
                        metrics.filter(metric => metric !== m)
                      );
                    }}>
                    <X />
                  </IconButton>
                )}
              </Box>
            ),
            dataKey: m,
            ...(additionalAxis && index > 0 && { isRightAxis: !isAxisExists }),
            color: chartColors[index],
            type: 'line',
          });
        }
        return acc;
      }, []),
    [metrics, metricOptions, additionalAxis, chartColors, updateFilters]
  );
  const memoOptions = useMemo(() => metricOptions.filter(f => !f.value.includes('exception')), [metricOptions]);
  return (
    <Box sx={{ ...flex.col, height: '100%', gap: 2 }}>
      <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
        {allowSelection && (
          <Select
            variant="outlined"
            type="icon"
            isRequired
            disabled={metrics.length >= limit}
            label={<Plus />}
            disabledTooltipText={disabledTooltipText}
            options={memoOptions}
            size="xSmall"
            shouldCloseOnChange={newVal => newVal?.length >= limit}
            isMultiple
            onChange={v => updateFilters('keyMetrics', v.length ? v : [metricOptions[0].value])}
            value={metrics}
            showSelection={false}
          />
        )}
        <AvLegend isHorizontal series={series} isOutlined={allowSelection} />
      </Box>
      <AvComposedWidget
        isDate
        showLegend={false}
        data={data}
        series={series}
        xAxisKey="date"
        labels={labels}
        additionalAxis={additionalAxis}
        dateFormat={dateFormat}
      />
    </Box>
  );
}

export default KeyMetricsOverTime;

KeyMetricsOverTime.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  metrics: PropTypes.arrayOf(PropTypes.string),
  updateFilters: PropTypes.func,
  labels: PropTypes.shape(),
  metricOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  additionalAxis: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  limit: PropTypes.number,
  disabledTooltipText: PropTypes.string,
  dateFormat: PropTypes.string,
  allowSelection: PropTypes.bool,
  chartColors: PropTypes.arrayOf(PropTypes.string),
};

KeyMetricsOverTime.defaultProps = {
  data: [],
  metrics: [],
  updateFilters: noop,
  labels: {},
  additionalAxis: undefined,
  limit: undefined,
  disabledTooltipText: undefined,
  dateFormat: 'MMM',
  allowSelection: true,
  chartColors: undefined,
};
