import React, { ReactElement } from 'react';
import { Box } from '@mui/material';

interface IAvTagProps {
  text: ReactElement | string | number;
  isActive?: boolean;
  bg?: string;
  color?: string;
  oneLine?: boolean;
  sx?: object;
}

const labelStyle = (isActive, bg, color, oneLine, sx) => theme => ({
  whiteSpace: oneLine ? 'unset' : 'pre-wrap',
  padding: '2px 6px',
  borderRadius: '8px',
  lineHeight: '20px',
  fontWeight: 500,
  fontSize: 13,
  color: color || (isActive ? theme.palette.colors.primary[400] : theme.palette.colors.neutrals[600]),
  backgroundColor: bg || (isActive ? theme.palette.colors.primary[150] : theme.palette.colors.neutrals[350]),
  ...sx,
});

const AvTag: React.FC<IAvTagProps> = ({ text, isActive, bg, color, oneLine, sx = {} }) =>
  text && (
    <Box component="span" key={`${text}`} sx={labelStyle(isActive, bg, color, oneLine, sx)}>
      {text}
    </Box>
  );

export default AvTag;
