import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { AppPathsValue, PAGE_PATHS } from '../types';
import avLazy from '../utils/AvLazy';

const Explore = avLazy(() => import('../views/Pivot/Pivot'));

const ExploreRoute = (projectionName, app: AppPathsValue) => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const path = PAGE_PATHS.PIVOT;
  const permissionObject = { app, path };
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission(permissionObject)}>
            <Explore projectionName={projectionName} />
          </ProtectedRoute>
        }
      />
      <Route
        path=":id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission(permissionObject)}>
            <Explore projectionName={projectionName} />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};
export default ExploreRoute;
