// TODO: replace hard-coded strings with this constant
// eslint-disable-next-line import/prefer-default-export
export const FormatterTypes = {
  number: 'number',
  date: 'date',
  historicDate: 'historic-date',
  percentage: 'percentage',
  array: 'array',
  json: 'json',
  bool: 'bool',
  loading: 'loading',
  string: 'string',
};

export const NO_VALUE = '—';
export const EMPTY_STRING = '" "';
export const NullValues = ['null', null, NO_VALUE, EMPTY_STRING];
