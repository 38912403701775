import { SchemaTypes } from '../Sources/Mapping/mapping.types';

export const aggragtedAggs = ['tickets', 'incidents'];

export const initialStateEnhancedProj = {
  clusteringEnabled: true,
  meta: {
    id: {
      name: '',
      builtIn: true,
    },
  },
  clusteringPredicate: {
    anyofList: [],
  },
  aggregates: {
    name: '',
    builtIn: true,
  },
};

export enum AssignmentType {
  VALUE = 'VALUE',
  FIELD = 'FIELD',
  STRING_TEMPLATE = 'STRING_TEMPLATE',
  CEL_EXPRESSION = 'CEL_EXPRESSION',
}

export const assigneeRadioOptions = [
  { value: AssignmentType.VALUE, title: 'By Value' },
  { value: AssignmentType.FIELD, title: 'By Field' },
  { value: AssignmentType.STRING_TEMPLATE, title: 'By Formula' },
  { value: AssignmentType.CEL_EXPRESSION, title: 'By Expression' },
];

export const defaultValues = {
  [SchemaTypes.BOOL]: false,
  [SchemaTypes.DATE]: new Date().toISOString(),
  [SchemaTypes.FIX]: '',
  [SchemaTypes.IP]: '',
  [SchemaTypes.NUMBER]: 0,
  [SchemaTypes.STRING]: '',
};

export const enhancedAggProjsResponseMock = {
  data: {
    aggProjections: [
      {
        clusteringEnabled: false,
        meta: {
          id: {
            name: 'uber_applications',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'Application',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [],
          __typename: 'ClusterPredicate',
        },
        settings: null,
        aggregates: {
          name: 'applications',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: true,
        meta: {
          id: {
            name: 'uber_david',
            builtIn: false,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'david',
            builtIn: false,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [
            {
              ignoreNulls: true,
              name: 'key1',
              predicate: {
                predicateFieldsList: [
                  {
                    fieldName: 'david.key1',
                    __typename: 'PredicateField',
                  },
                ],
                filter: {},
                __typename: 'Predicate',
              },
              fieldsList: [],
              __typename: 'ClusterPredicateFieldSet',
            },
          ],
          __typename: 'ClusterPredicate',
        },
        settings: null,
        aggregates: {
          name: 'david',
          builtIn: false,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: true,
        meta: {
          id: {
            name: 'incidents',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'Incident',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [
            {
              ignoreNulls: true,
              name: '',
              predicate: {
                predicateFieldsList: [
                  {
                    fieldName: 'uber_alerts.grouping_key',
                    __typename: 'PredicateField',
                  },
                ],
                filter: {},
                __typename: 'Predicate',
              },
              fieldsList: ['grouping_key'],
              __typename: 'ClusterPredicateFieldSet',
            },
          ],
          __typename: 'ClusterPredicate',
        },
        settings: null,
        aggregates: {
          name: 'uber_alerts',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: true,
        meta: {
          id: {
            name: 'uber_assets',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'Asset',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [
            {
              ignoreNulls: true,
              name: 'yoo',
              predicate: {
                predicateFieldsList: [
                  {
                    fieldName: 'assets.source_key',
                    __typename: 'PredicateField',
                  },
                ],
                filter: {},
                __typename: 'Predicate',
              },
              fieldsList: ['source_key'],
              __typename: 'ClusterPredicateFieldSet',
            },
            {
              ignoreNulls: true,
              name: 'Asset Tags',
              predicate: {
                predicateFieldsList: [
                  {
                    fieldName: 'assets.tags',
                    __typename: 'PredicateField',
                  },
                ],
                filter: {},
                __typename: 'Predicate',
              },
              fieldsList: ['tags'],
              __typename: 'ClusterPredicateFieldSet',
            },
          ],
          __typename: 'ClusterPredicate',
        },
        settings: null,
        aggregates: {
          name: 'assets',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: true,
        meta: {
          id: {
            name: 'tickets',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'Ticket',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [
            {
              ignoreNulls: true,
              name: '',
              predicate: {
                predicateFieldsList: [
                  {
                    fieldName: 'uber_findings.grouping_key',
                    __typename: 'PredicateField',
                  },
                ],
                filter: {},
                __typename: 'Predicate',
              },
              fieldsList: ['grouping_key'],
              __typename: 'ClusterPredicateFieldSet',
            },
          ],
          __typename: 'ClusterPredicate',
        },
        settings: {
          severities: [
            {
              label: 'NONE',
              upperThreshold: 0.1,
            },
            {
              label: 'LOW',
              upperThreshold: 4,
              slaDays: 120,
            },
            {
              label: 'MEDIUM',
              upperThreshold: 7,
              slaDays: 90,
            },
            {
              label: 'HIGH',
              upperThreshold: 9,
              slaDays: 30,
            },
            {
              label: 'CRITICAL',
              slaDays: 15,
            },
          ],
          trackingStartDate: 'AVALOR_TICKET_CREATION',
        },
        aggregates: {
          name: 'uber_findings',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: false,
        meta: {
          id: {
            name: 'uber_configurations',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'Configuration',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [],
          __typename: 'ClusterPredicate',
        },
        settings: null,
        aggregates: {
          name: 'configurations',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: false,
        meta: {
          id: {
            name: 'uber_locations',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'Location',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [],
          __typename: 'ClusterPredicate',
        },
        settings: null,
        aggregates: {
          name: 'locations',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: false,
        meta: {
          id: {
            name: 'uber_findings',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'Finding',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [],
          __typename: 'ClusterPredicate',
        },
        settings: {
          severities: [
            {
              label: 'NONE',
              upperThreshold: 0.1,
            },
            {
              label: 'LOW',
              upperThreshold: 4,
              slaDays: 120,
            },
            {
              label: 'MEDIUM',
              upperThreshold: 7,
              slaDays: 90,
            },
            {
              label: 'HIGH',
              upperThreshold: 9,
              slaDays: 30,
            },
            {
              label: 'CRITICAL',
              slaDays: 15,
            },
          ],
          ruleSets: [
            {
              name: 'Default Rule-Set',
              rules: [
                {
                  name: 'Key Rule',
                  grouping: {
                    fieldGrouping: {
                      fields: ['_key'],
                    },
                  },
                },
              ],
            },
          ],
          activeRuleSetName: 'Default Rule-Set',
        },
        aggregates: {
          name: 'findings',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: false,
        meta: {
          id: {
            name: 'uber_alert_actors',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'AlertActor',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [],
          __typename: 'ClusterPredicate',
        },
        settings: null,
        aggregates: {
          name: 'alert_actors',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: true,
        meta: {
          id: {
            name: 'uber_global_vulnerabilities',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'GlobalVulnerability',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [
            {
              ignoreNulls: true,
              name: '',
              predicate: {
                predicateFieldsList: [
                  {
                    fieldName: 'global_vulnerabilities.cve',
                    __typename: 'PredicateField',
                  },
                ],
                filter: {},
                __typename: 'Predicate',
              },
              fieldsList: ['cve'],
              __typename: 'ClusterPredicateFieldSet',
            },
          ],
          __typename: 'ClusterPredicate',
        },
        settings: null,
        aggregates: {
          name: 'global_vulnerabilities',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: false,
        meta: {
          id: {
            name: 'uber_alerts',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'Alert',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [],
          __typename: 'ClusterPredicate',
        },
        settings: null,
        aggregates: {
          name: 'alerts',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: true,
        meta: {
          id: {
            name: 'uber_components',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'Component',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [
            {
              ignoreNulls: true,
              name: '',
              predicate: {
                predicateFieldsList: [
                  {
                    fieldName: 'components.source_key',
                    __typename: 'PredicateField',
                  },
                ],
                filter: {},
                __typename: 'Predicate',
              },
              fieldsList: ['source_key'],
              __typename: 'ClusterPredicateFieldSet',
            },
          ],
          __typename: 'ClusterPredicate',
        },
        settings: null,
        aggregates: {
          name: 'components',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: true,
        meta: {
          id: {
            name: 'uber_org_entities',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'OrgEntity',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [
            {
              ignoreNulls: true,
              name: '',
              predicate: {
                predicateFieldsList: [
                  {
                    fieldName: 'org_entities.source_key',
                    __typename: 'PredicateField',
                  },
                ],
                filter: {},
                __typename: 'Predicate',
              },
              fieldsList: ['source_key'],
              __typename: 'ClusterPredicateFieldSet',
            },
          ],
          __typename: 'ClusterPredicate',
        },
        settings: null,
        aggregates: {
          name: 'org_entities',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
      {
        clusteringEnabled: true,
        meta: {
          id: {
            name: 'uber_component_instances',
            builtIn: true,
            __typename: 'ProjectionID',
          },
          typeId: {
            name: 'ComponentInstance',
            builtIn: true,
            __typename: 'EntityTypeID',
          },
          __typename: 'ProjectionMetadata',
        },
        clusteringPredicate: {
          anyofList: [
            {
              ignoreNulls: true,
              name: '',
              predicate: {
                predicateFieldsList: [
                  {
                    fieldName: 'component_instances.source_key',
                    __typename: 'PredicateField',
                  },
                ],
                filter: {},
                __typename: 'Predicate',
              },
              fieldsList: ['source_key'],
              __typename: 'ClusterPredicateFieldSet',
            },
          ],
          __typename: 'ClusterPredicate',
        },
        settings: null,
        aggregates: {
          name: 'component_instances',
          builtIn: true,
          __typename: 'ProjectionID',
        },
        __typename: 'AggProjection',
      },
    ],
  },
};
