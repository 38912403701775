import React, { useContext } from 'react';
import { useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AvCheckbox from '../AvCheckbox';
import { TableContext } from './Context';
import TableHeadCell from './TableHeadCell';
import { GroupedHeadCell, HeadCellType, SortType } from './types';
import { ReactComponent as Filter } from '../../assets/Filter.svg';

const filterIcon = <Filter />;

interface EnhancedTableHeadProps {
  groupedHeadCells?: GroupedHeadCell;
  headCells: HeadCellType[];
  numSelected: number;
  onRequestSort?: (event, id) => void;
  onSelectAllClick: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: SortType;
  orderBy: string;
  rowCount: number;
  multiSelect: boolean;
  onCancelColumn?: (v) => void;
  isSelectedAll: boolean;
}

const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  multiSelect,
  groupedHeadCells,
  onCancelColumn,
  isSelectedAll,
}) => {
  const { palette } = useTheme();
  const { resizingColId } = useContext(TableContext);
  return (
    <TableHead
      sx={[
        { userSelect: 'none' },
        groupedHeadCells ? { position: 'sticky', top: '0' } : {},
        !!resizingColId && { '*': { cursor: 'col-resize !important' } },
      ]}>
      {groupedHeadCells && (
        <TableRow>
          <TableCell colSpan={1} sx={{ borderRight: `1px solid ${palette.colors.neutrals[350]}` }} />
          {groupedHeadCells.cells.map((v, index, arr) => (
            <TableCell
              key={v}
              sx={{
                background: palette.colors.neutrals[150],
                borderRight: index !== arr.length - 1 ? `1px solid ${palette.colors.neutrals[350]}` : '',
              }}
              align="center"
              colSpan={groupedHeadCells.innerCols}>
              {v}
            </TableCell>
          ))}
        </TableRow>
      )}
      <TableRow>
        {multiSelect ? (
          <TableCell padding="checkbox">
            <AvCheckbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              value={isSelectedAll || (rowCount > 0 && numSelected === rowCount)}
              disabled={isSelectedAll}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all rows' }}
            />
          </TableCell>
        ) : null}
        {headCells.map(({ id, label, filter, sx = {}, type, initWidth, disableSortBy, sort }, index) => {
          const titleLabel = (
            <>
              {filter ? filterIcon : null}
              <span>{label}</span>
            </>
          );
          const isActiveSort = orderBy === id || !!sort;
          const sortDirection = sort || (isActiveSort ? order : SortType.DESC);
          return (
            <TableHeadCell
              key={id}
              id={id}
              type={type}
              activeSort={isActiveSort}
              order={order}
              label={label}
              initWidth={initWidth}
              sx={sx}
              disableSortBy={disableSortBy}
              onRequestSort={onRequestSort}
              direction={sortDirection}
              onClick={event => onRequestSort?.(event, id)}
              titleLabel={titleLabel}
              onCancelColumn={onCancelColumn}
              index={index}
            />
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
