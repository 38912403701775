import { BaseWidget, WidgetCategory, WidgetDefinition } from './base.types';

export enum TableSubType {
  Table = 'TABLE',
}

export type TableCustomDefinition = {
  rowSize: TableRowSize;
  showTotals?: boolean;
  crossTableView?: boolean;
};

export type TableWidget = BaseWidget & {
  category: WidgetCategory.Table;
  definition: WidgetDefinition & { custom: TableCustomDefinition };
};

export enum TableRowSize {
  Small = 'xSmall',
  Medium = 'small',
  Large = 'large',
}

export type TableTypeWidget = TableWidget & {
  type: TableSubType.Table;
};

export type TableCategoryWidgetTypes = TableTypeWidget;
