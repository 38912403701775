import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Cell, XAxis } from 'recharts';
import { getSeverityByScore, SEVERITY_LABELS } from '../../utils/severity.utils';
import { uniqBy } from '../../utils/Utils';
import { useSeveritySettings } from '../../views/Settings/hooks';
import AvLegend from '../AvLegend';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import AvComposedWidget from './AvComposedWidget';

const getRange = index => {
  if (index <= 6) {
    return '0';
  }
  if (index > 6 && index <= 12) {
    return '5';
  }
  return '10';
};

const getRangeTitle = index => {
  if (+index === 0) {
    return 'Min Asset Risk';
  }
  if (+index === 5) {
    return 'Median Asset Risk';
  }
  return 'Max Asset Risk';
};

interface ILabelProps {
  index: number;
  x: number;
  y: number;
  width: number;
  height: number;
  payload: {
    value: string;
    offset: number;
  };
}

const Label: React.FC<ILabelProps> = ({ index, x, y, width, height, payload }) => {
  const getWrapperStyle = index => {
    if (index === 0) {
      return flex.colItemsStart;
    }
    if (index === 1) {
      return flex.colItemsCenter;
    }
    return flex.colItemsEnd;
  };
  return (
    <foreignObject x={x - payload.offset} y={y - 5} width={width / 3} height={height / 2}>
      <Box sx={getWrapperStyle(index)}>
        <Box
          sx={{
            fontSize: '13px',
            fontWeight: 600,
            color: theme => theme.palette.colors.neutrals[800],
          }}>
          <AvTooltip title={getRangeTitle(payload.value)}>{payload.value}</AvTooltip>
        </Box>
      </Box>
    </foreignObject>
  );
};

interface IActiveAssetsHistogramProps {
  data?: {
    id: string;
    risk: string;
    pureRisk: number;
    assets: number;
  }[];
}

const ActiveAssetsHistogram: React.FC<IActiveAssetsHistogramProps> = ({ data: d = [] }) => {
  const theme = useTheme();
  const settingsSeverities = useSeveritySettings();
  const data = d.map((item, index) => {
    const risk = getSeverityByScore(theme, settingsSeverities, +item.pureRisk, undefined, 400);
    return {
      ...item,
      name: `${SEVERITY_LABELS[risk?.severity || 'LOW']} Risk`,
      range: getRange(index),
      color: risk?.bgColor,
    };
  });
  const series = [
    {
      name: data[0].name,
      dataKey: 'assets',
      type: 'bar',
      stackId: 'a',
      minPointSize: 1,
      children: data.map(entry => <Cell key={entry.id} fill={entry.assets === 0 ? 'transparent' : entry.color} />),
    },
    {
      name: 'risk',
      color: 'transparent',
      dataKey: 'risk',
      type: 'bar',
      stackId: 'a',
    },
  ];

  const legendSeries = [
    {
      name: 'Low',
      color: theme.palette.colors.blue[400],
    },
    {
      name: 'Medium',
      color: theme.palette.colors.yellow[400],
    },
    {
      name: 'High',
      color: theme.palette.colors.orange[400],
    },
    {
      name: 'Critical',
      color: theme.palette.colors.negative[400],
    },
  ];

  const xAxis = (
    // @ts-ignore
    <XAxis xAxisId={1} dataKey="range" height={40} allowDuplicatedCategory={false} axisLine={false} tickLine={false} tick={<Label />} />
  );

  return (
    <Box sx={{ ...flex.col, height: '100%', width: '100%' }}>
      <Box sx={{ mb: 1.5 }}>
        <AvLegend series={legendSeries} isHorizontal />
      </Box>
      <Box sx={{ height: '100%' }}>
        <AvComposedWidget
          xAxisKey="risk"
          data={data}
          series={uniqBy(series, 'name')}
          labels={{ y: 'Assets' }}
          showLegend={false}
          tooltipProps={{
            showTitleLegend: true,
            showContentLegend: false,
            metricFormatter: value => (value === 'risk' ? `${value} Score` : value),
          }}
          chartProps={{ height: '100%', margin: { left: 5 } }}
          defaultAxisProps={{ x: { hide: true } }}
          additionalAxis={xAxis}
        />
      </Box>
    </Box>
  );
};

export default ActiveAssetsHistogram;
