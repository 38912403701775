import React, { CSSProperties } from 'react';
import ReactJson from 'react-json-view';

interface AvJsonViewerProps {
  data: Record<string, any>[] | Record<string, any>;
  style?: CSSProperties;
}

const AvJsonViewer: React.FC<AvJsonViewerProps> = ({ data, style }) => <ReactJson src={data} displayDataTypes={false} style={style} />;

export default AvJsonViewer;
