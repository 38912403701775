import React from 'react';
import { Box, Button, Theme } from '@mui/material';
import { flex } from './AvThemeProvider';

interface AvSnackBarMessageProps {
  title: string;
  subTitle?: string;
  closeSnackbar: () => void;
  onClickSkipValidation?: (value: boolean) => void;
  isTest?: boolean;
  message?: string;
}
export default function AvSnackBarMessage({
  title,
  subTitle = '',
  closeSnackbar,
  isTest = true,
  onClickSkipValidation,
  message = '',
}: AvSnackBarMessageProps) {
  return (
    <Box sx={{ ...flex.col, gap: '4px', pl: '4px', pb: '4px', wordBreak: 'break-word' }}>
      <Box sx={{ color: (theme: Theme) => theme.palette.colors.neutrals[850] }}>{title}</Box>
      {subTitle && <Box sx={{ fontWeight: 400 }}>{subTitle}</Box>}
      <Box
        sx={{
          fontWeight: 400,
          color: (theme: Theme) => theme.palette.colors.neutrals[600],
          mt: 1,
          fontSize: 13,
          whiteSpace: 'pre-line',
        }}>
        {message}
      </Box>
      <Box sx={{ ...flex.justifyEnd, gap: 1, mt: '10px' }}>
        {!isTest && (
          <Button
            variant="outlined"
            size="xSmall"
            onClick={() => {
              onClickSkipValidation?.(true);
              closeSnackbar();
            }}>
            Skip Validation
          </Button>
        )}
        <Button variant={isTest ? 'outlined' : 'contained'} size="xSmall" onClick={closeSnackbar}>
          OK
        </Button>
      </Box>
    </Box>
  );
}
