import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { flex } from './AvThemeProvider';
import AvTooltip from './AvTooltip';
import { ReactComponent as Error } from '../assets/colorful/Fail_full.svg';

const errorIcon = <Error />;

export function ErrorTooltip({ error, children }: { error: string; children: React.JSX.Element }) {
  return (
    <AvTooltip
      variant="message"
      color="white"
      hasWidthLimit={false}
      title={
        <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
          {errorIcon}
          {error}
        </Box>
      }>
      {children}
    </AvTooltip>
  );
}

export function InputTooltipWrapper({
  tooltipContent,
  error,
  inputNode,
  value,
  children,
}: {
  tooltipContent?: string | React.ReactNode;
  error?: any;
  inputNode?: HTMLInputElement | null;
  value?: string | number | null;
  children: React.JSX.Element;
}) {
  return tooltipContent ? (
    <AvTooltip variant="message" color="white" title={tooltipContent}>
      {children}
    </AvTooltip>
  ) : error ? (
    <ErrorTooltip error={error}>{children}</ErrorTooltip>
  ) : (
    <Tooltip title={inputNode && inputNode.scrollWidth > inputNode.clientWidth ? value : ''} placement="bottom">
      {children}
    </Tooltip>
  );
}
