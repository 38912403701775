import React from 'react';
import { FormControl, InputLabel, Theme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/system';
import { SeverityIcons, severityOptions } from '../utils/severity.utils';
import { noop } from '../utils/Utils';

interface ItemButtonProps {
  theme?: Theme;
  color?: string;
  error?: boolean;
  size?: 'small' | 'large';
}

const ItemButton = styled(ToggleButton)<ItemButtonProps>(({ theme, color, error, size }) => ({
  borderColor: theme.palette.colors.neutrals[400],
  ...(errorColor => ({
    borderTopColor: errorColor,
    borderBottomColor: errorColor,
    '&.MuiToggleButtonGroup-grouped:first-of-type': {
      borderLeftColor: errorColor,
    },
    '&.MuiToggleButtonGroup-grouped:last-of-type': {
      borderRightColor: errorColor,
    },
  }))(error ? theme.palette.colors.negative[500] : undefined),
  padding: size === 'small' ? '6px 15px' : '8px 15px',
  color: theme.palette.colors.neutrals[500],
  '&:hover': {
    svg: {
      filter: 'unset',
    },
  },
  '&.Mui-selected': {
    svg: {
      filter: 'unset',
    },
  },
  svg: {
    filter: 'grayscale(1)',
    transition: theme.transitions.create(['filter']),
  },
  '& > span': {
    color: theme.palette.colors.neutrals[600],
    fontWeight: 600,
    lineHeight: size === 'small' ? '18px' : '22px',
  },
  '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    marginLeft: 'inherit',
    borderLeft: 'none',
  },
  '&:hover, &.Mui-selected:hover, &.Mui-focusVisible': {
    backgroundColor: size !== 'small' && theme.palette.colors.neutrals[200],
    color: color && color !== 'standard' && theme.palette[color].main,
  },
  '&:hover > span, &.Mui-selected > span': {
    color: theme.palette.colors.neutrals[800],
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.colors.neutrals[100],
    color: theme.palette.colors.neutrals[500],
    borderColor: theme.palette.colors.neutrals[400],
    '&.Mui-selected': {
      color: color && color !== 'standard' && theme.palette[color].main,
    },
  },
}));

interface Option {
  title: string;
  value: string;
}

interface AvToggleProps {
  label?: string;
  value?: string | number | string[];
  options?: Option[];
  onChange?: (value: any) => void;
  error?: string;
  labelColor?: string;
  multiSelect?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  size?: 'medium' | 'small' | 'smallOutline';
  getItemColor?: (v) => 'standard' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
  getItemIcon?: (v) => string | undefined;
}

const AvToggle: React.FC<AvToggleProps> = ({
  label = '',
  value = '',
  onChange = noop,
  error = '',
  multiSelect = false,
  isRequired = false,
  disabled = false,
  options = [],
  size = 'medium',
  getItemColor,
  getItemIcon,
  labelColor,
}) => {
  const handleChange = (e, val) => {
    if (!isRequired || multiSelect || val !== null) {
      onChange(val);
    }
  };
  return (
    <FormControl required={isRequired} error={Boolean(error)} disabled={disabled}>
      <InputLabel sx={{ '&.MuiFormLabel-root': { color: labelColor } }}>{label}</InputLabel>
      <ToggleButtonGroup value={value} exclusive={!multiSelect} onChange={handleChange} aria-label={label} disabled={disabled} size={size}>
        {options.map(({ title, value: val }) => (
          <ItemButton
            key={val}
            value={val}
            color={getItemColor?.(val)}
            error={Boolean(error)}
            disabled={isRequired && multiSelect && Array.isArray(value) && value.length === 1 && value.includes(val)}>
            {getItemIcon?.(val)}
            <span>{title}</span>
          </ItemButton>
        ))}
      </ToggleButtonGroup>
    </FormControl>
  );
};

export default AvToggle;

interface SeverityToggleProps {
  onChange: (value: string) => void;
}
export function SeverityToggle({ onChange, ...toggleProps }: SeverityToggleProps & any) {
  return (
    <AvToggle
      options={severityOptions}
      getItemColor={val => SeverityIcons[val].color}
      getItemIcon={val => SeverityIcons[val].icon}
      onChange={newValue =>
        onChange(toggleProps.multiSelect ? newValue.sort((a, b) => SeverityIcons[a].order - SeverityIcons[b].order) : newValue)
      }
      {...toggleProps}
    />
  );
}
