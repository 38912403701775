import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  InputAdornment,
  Popper,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { abbreviateNumber, ellipsis, generateOption, noop, uniqBy } from '../utils/Utils';
import AvMessagePopover from '../views/Tickets/AvMessagePopover.tsx';
import AvCheckbox from './AvCheckbox.tsx';
import { flex } from './AvThemeProvider.tsx';
import AvTooltip from './AvTooltip.tsx';
import { InputTooltipWrapper } from './InputTooltipWrapper.tsx';
import { ReactComponent as ArrowDown } from '../assets/Arrow Down.svg';
import { ReactComponent as Attention } from '../assets/Attention.svg';
import { ReactComponent as Plus } from '../assets/Plus.svg';
import { ReactComponent as Delete } from '../assets/Remove Filters Flat.svg';
import { ReactComponent as Search } from '../assets/Search.svg';
import { ReactComponent as X } from '../assets/X.svg';

const popupIcon = <ArrowDown />;
const clearIcon = <X />;
const searchIcon = <Search />;
const plusIcon = <Plus />;

export const FilterStyledPopper = styled(Popper)(({ theme }) => ({
  ...theme.components.MuiPaper.styleOverrides.rounded,
  ...theme.components.MuiAutocomplete.styleOverrides.paper,
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.modal,
  [`& .${autocompleteClasses.popupIndicator}`]: {
    transform: 'none',
  },
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    padding: 0,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    maxWidth: 400,
    flex: 1,
  },
}));
const showOnlyButtonStyle = {
  '.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-sizeSmall': {
    paddingRight: '16px',
    input: {
      minWidth: 0,
      '+ .MuiAutocomplete-endAdornment': {
        right: '6px',
      },
    },
  },
};

// eslint-disable-next-line react/prop-types
function PopperComponent({ style, ...params }) {
  return <Popper {...params} style={{ ...style, minWidth: 'fit-content' }} placement="bottom-start" />;
}

// eslint-disable-next-line react/prop-types
function SimplePopperComponent({ disablePortal, anchorEl, style, open, ...others }) {
  return <div {...others} />;
}

const getDefaultValue = isMultiple => (isMultiple ? [] : null);
const renderWithCheckbox = (renderOption, renderOptionStyle) =>
  function renderOpt(props, opt, { selected }) {
    if (opt.value === AvalorSelectLoadingValue) {
      return getLoadingOption();
    }
    return (
      <Box component="li" sx={renderOptionStyle(opt)} {...props} className={`${props.className} has-checkbox`}>
        <AvCheckbox value={selected} />
        <AvTooltip>{renderOption(opt)}</AvTooltip>
      </Box>
    );
  };

const getRenderOptionFunc = ({ renderOption, isMultiple, getLabelFunc, renderOptionStyle, skeletonLoading }) => {
  if (isMultiple) {
    return renderWithCheckbox(renderOption || getLabelFunc, renderOptionStyle);
  }
  if (skeletonLoading) {
    return function (props, opt) {
      if (opt.value === AvalorSelectLoadingValue) {
        return getLoadingOption();
      }
      return <li {...props}>{(renderOption || getLabelFunc)(opt)}</li>;
    };
  }
  return renderOption && ((props, opt) => <li {...props}>{renderOption(opt)}</li>);
};

export const AvalorSelectLoadingValue = 'avalorSelectLoadingValue';
export const getLoadingOption = () => (
  <Box sx={{ 'li:has(> &)': { pointerEvents: 'none' }, ...flex.col, gap: 0.5, px: '12px' }}>
    <Skeleton width={120} height={16} />
    <Skeleton width={220} height={16} />
    <Skeleton width={100} height={16} />
    <Skeleton width={180} height={16} />
    <Skeleton width={120} height={16} />
    <Skeleton width={220} height={16} />
    <Skeleton width={100} height={16} />
    <Skeleton width={180} height={16} />
  </Box>
);
export function ClearSelection({ onClick, disabled }) {
  return (
    <Button sx={{ gap: '4px' }} disabled={disabled} onClick={onClick}>
      <Delete style={{ height: 18, width: 18 }} />
      Clear Selection
    </Button>
  );
}

ClearSelection.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ClearSelection.defaultProps = {
  disabled: false,
};

export default function Select({
  label,
  value: originalValue,
  onChange,
  error,
  options,
  size,
  acceptNullValue,
  disabled,
  disabledTooltipText,
  startAdornment,
  freeSolo,
  style,
  isMultiple,
  muiProps,
  getValueFunc,
  getLabelFunc,
  groupByFunc,
  filterFunc,
  isRequired,
  renderOption,
  placeholder,
  transparent,
  showSelection,
  loading,
  disableCloseOnSelect,
  showInput,
  selectIcon,
  hidePlaceHolder,
  missingValueErrorText,
  onClose,
  showOnlyAutoComplete,
  showClearSelection,
  onCreateNew,
  hasApplyButton,
  variant,
  type,
  renderTagSeparator,
  hideValue,
  shouldCloseOnChange,
  customActionButton,
  totalOptionsCount,
  renderOptionStyle,
  skeletonLoading,
  valueAsObject,
}) {
  const buttonEl = useRef(null);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [orderedOptions, setOrderedOptions] = useState(options);
  useEffect(() => {
    setOpen(isOpen);
  }, [options]);
  const [draftValue, setDraftValue] = useState(isMultiple ? [] : undefined);
  const value = hasApplyButton ? draftValue : originalValue;

  const extractSingleValue = val => options.find(opt => getValueFunc(opt) === val) || null;
  const processedValue =
    freeSolo && !isMultiple && valueAsObject
      ? value
      : value !== undefined
        ? isMultiple
          ? value.map(extractSingleValue)
          : extractSingleValue(value)
        : getDefaultValue(isMultiple);

  const errorText = (
    isMultiple ? processedValue.filter(val => !val).length : !processedValue && value !== undefined && (acceptNullValue || value !== null)
  )
    ? missingValueErrorText
    : undefined;

  const doChange = (e, val) =>
    (hasApplyButton ? setDraftValue : onChange)(isMultiple ? (val || []).map(getValueFunc) : val ? getValueFunc(val) : null, val);

  const autocompleteProps = {
    className: isMultiple ? 'autocomplete-multiple' : undefined,
    popupIcon: variant !== 'input' ? searchIcon : selectIcon,
    clearIcon,
    size,
    autoComplete: !freeSolo,
    loading,
    autoHighlight: !freeSolo,
    PopperComponent: variant !== 'input' ? SimplePopperComponent : PopperComponent,
    multiple: isMultiple,
    disableCloseOnSelect: isMultiple || disableCloseOnSelect,
    options: [...orderedOptions, ...(skeletonLoading && loading ? [generateOption(AvalorSelectLoadingValue)] : [])],
    getOptionLabel: getLabelFunc,
    getOptionKey: getValueFunc,
    groupBy: groupByFunc,
    filterOptions: filterFunc,
    isOptionEqualToValue: (option, val) => val === '' || getValueFunc(option) === getValueFunc(val),
    disableClearable: isRequired,
    renderOption: getRenderOptionFunc({ renderOption, isMultiple, getLabelFunc, renderOptionStyle, skeletonLoading }),
    freeSolo,
    sx: variant === 'input' ? style : undefined,
    forcePopupIcon: freeSolo,
    blurOnSelect: !isMultiple,
    ...muiProps,
    componentsProps: { clearIndicator: { tabIndex: -1 }, ...muiProps?.componentsProps },
    onKeyDown: e => {
      e.stopPropagation();
      muiProps?.onKeyDown?.(e);
    },
  };

  const getDisplayValue = val => (isMultiple ? val.map(getLabelFunc).join(', ') : getLabelFunc(val));

  const setOpen = open => {
    setIsOpen(open);
    if (open && (isMultiple || showClearSelection) && variant !== 'input' && !groupByFunc) {
      setOrderedOptions(uniqBy([...(isMultiple ? processedValue : [processedValue]), ...options], getValueFunc));
    } else {
      setOrderedOptions(options);
    }
  };

  const handleClick = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setSearch('');
    onClose?.();
    if (hasApplyButton) {
      setDraftValue([]);
    }
  };

  const onChangeAutocomplete = (event, newValue, reason) => {
    if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
      return;
    }
    doChange(event, newValue);
    if (!autocompleteProps.disableCloseOnSelect || shouldCloseOnChange?.(newValue)) {
      setOpen(false);
    }
  };

  const hasValidValue = !!value?.length || (acceptNullValue && value === null);
  const id = isOpen ? 'filter-mode' : undefined;
  const showPlaceholder = Boolean(placeholder && !processedValue);
  const filterAutoComplete = () => (
    <Box sx={{ zIndex: theme => theme.zIndex.modal }}>
      <Autocomplete
        onClose={(e, reason) => reason === 'escape' && handleClose()}
        open
        value={errorText ? getDefaultValue(isMultiple) : processedValue}
        onChange={onChangeAutocomplete}
        inputValue={search}
        onInputChange={(event, value, reason) => {
          if (reason !== 'reset') {
            setSearch(value);
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            sx={{ display: showInput ? 'unset' : options.length > 5 || search ? 'unset' : 'none' }}
            autoFocus
            placeholder="Search"
            InputProps={{
              ...params.InputProps,
              ...(muiProps?.InputProps || {}),
              startAdornment: <Box sx={{ ...flex.row, color: ({ palette }) => palette.colors.neutrals[600] }}>{searchIcon}</Box>,
            }}
          />
        )}
        renderTags={() => null}
        {...autocompleteProps}
        popupIcon={false}
        sx={{ ...autocompleteProps.sx, mb: showInput ? '6px' : 0 }}
        size="small"
      />
      {((options.length > 2 && isMultiple) || showClearSelection || onCreateNew || hasApplyButton || customActionButton) && (
        <>
          <Divider sx={{ mt: '6px' }} />
          <Box sx={{ pt: '8px', pl: '4px', pr: 1, gap: 2, ...flex.justifyBetweenCenter }}>
            {customActionButton}
            {onCreateNew && (
              <Button
                onClick={() => {
                  onCreateNew();
                  handleClose();
                }}
                sx={{ color: ({ palette }) => palette.primary.main }}>
                {plusIcon} Create New
              </Button>
            )}
            {!hasApplyButton && !customActionButton && ((isMultiple && options.length > 2) || showClearSelection) ? (
              <ClearSelection disabled={!value?.length} onClick={() => onChange(isMultiple ? [] : '')} />
            ) : null}
            {!hasApplyButton && isMultiple ? (
              <Typography sx={{ ...flex.itemsCenter, gap: 1, fontStyle: 'italic', color: ({ palette }) => palette.colors.neutrals[500] }}>
                {value?.length || 0} / {abbreviateNumber(totalOptionsCount ?? options.length)} values
                {totalOptionsCount > options.length && (
                  <AvMessagePopover
                    asTooltip
                    icon={<Attention />}
                    includeIconInMessage={false}
                    message={`Showing first ${options.length} values, search to see more`}
                  />
                )}
              </Typography>
            ) : null}
            {hasApplyButton && (
              <>
                {isMultiple ? (
                  <Typography sx={{ width: 100, fontStyle: 'italic', fontSize: 12, color: ({ palette }) => palette.colors.neutrals[500] }}>
                    {draftValue.length}/{options.length} Selected
                  </Typography>
                ) : null}
                <Box>
                  <Button size="small" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    disabled={draftValue.length === 0}
                    variant="contained"
                    onClick={() => {
                      onChange(draftValue);
                      handleClose();
                    }}>
                    Apply
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
  const displayValue =
    hasValidValue && !errorText && showSelection && !hideValue
      ? `${getDisplayValue(Array.isArray(processedValue) ? [processedValue[0]] : processedValue)}`
      : '';
  const tooltipValue = hasValidValue && !errorText && showSelection ? `${getDisplayValue(processedValue)}` : '';
  return showOnlyAutoComplete ? (
    filterAutoComplete()
  ) : variant !== 'input' ? (
    <>
      <SelectTrigger
        ariaDescribedby={id}
        onClick={handleClick}
        size={size}
        disabled={disabled}
        disabledTooltipText={disabledTooltipText}
        open={isOpen}
        hasValidValue={hasValidValue}
        showPlaceholder={showPlaceholder}
        transparent={transparent}
        ref={buttonEl}
        placeholder={placeholder}
        label={label}
        processedValue={isMultiple ? processedValue?.slice?.(1) : processedValue}
        chipCount={isMultiple && processedValue?.length ? processedValue.length - 1 : undefined}
        displayValue={displayValue}
        showSelection={showSelection}
        tooltipValue={tooltipValue}
        showChipCount={!(variant !== 'input' && transparent && isMultiple) && typeof label === 'string'}
        selectIcon={selectIcon}
        type={type}
        sx={style}
        variant={variant}
      />
      <FilterStyledPopper
        id={id}
        open={isOpen}
        anchorEl={buttonEl.current}
        placement="bottom-start"
        style={{ minWidth: 'fit-content', width: buttonEl.current?.clientWidth }}>
        <ClickAwayListener onClickAway={handleClose}>{filterAutoComplete()}</ClickAwayListener>
      </FilterStyledPopper>
    </>
  ) : (
    <Autocomplete
      value={errorText ? getDefaultValue(isMultiple) : processedValue}
      onChange={doChange}
      disabled={disabled}
      renderInput={params => (
        <InputTooltipWrapper error={error} inputNode={params.inputProps.ref.current} value={params.inputProps.value}>
          <TextField
            required={isRequired}
            {...params}
            label={label}
            error={Boolean((error || errorText) && !loading)}
            helperText={errorText && !loading ? errorText : ''}
            placeholder={disabled || hidePlaceHolder ? undefined : placeholder}
            InputProps={{
              ...params.InputProps,
              ...(muiProps?.InputProps || {}),
              startAdornment: (
                <>
                  {params.InputProps.startAdornment}
                  {startAdornment && <InputAdornment position="start">{startAdornment(processedValue)}</InputAdornment>}
                </>
              ),
            }}
          />
        </InputTooltipWrapper>
      )}
      renderTags={
        isMultiple
          ? (tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <>
                  {renderTagSeparator && index > 0 ? renderTagSeparator : null}
                  <Chip
                    label={(renderOption || getLabelFunc)(option)}
                    {...getTagProps({ index })}
                    sx={
                      isRequired && tagValue.length === 1
                        ? {
                            '&.MuiAutocomplete-tag.Mui-disabled': {
                              color: 'unset',
                              backgroundColor: ({ palette }) => palette.colors.primary[150],
                            },
                          }
                        : undefined
                    }
                    deleteIcon={clearIcon}
                    disabled={(isRequired && tagValue.length === 1) || option.disabled}
                  />
                </>
              ))
          : undefined
      }
      {...autocompleteProps}
      componentsProps={{ paper: { style: { maxWidth: muiProps?.paperSize || 700 } } }}
      {...(showInput ? {} : { value: {}, disableClearable: true, sx: { ...autocompleteProps.sx, ...showOnlyButtonStyle } })}
    />
  );
}

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(), PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.array]),
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])),
  size: PropTypes.oneOf(['xSmall', 'small', 'medium' /* , 'large' */]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  getValueFunc: PropTypes.func,
  getLabelFunc: PropTypes.func,
  groupByFunc: PropTypes.func,
  filterFunc: PropTypes.func,
  renderOption: PropTypes.func,
  disabled: PropTypes.bool,
  disabledTooltipText: PropTypes.string,
  isMultiple: PropTypes.bool,
  isRequired: PropTypes.bool,
  acceptNullValue: PropTypes.bool,
  muiProps: PropTypes.shape(),
  placeholder: PropTypes.string,
  startAdornment: PropTypes.func,
  freeSolo: PropTypes.bool,
  style: PropTypes.shape(),
  renderOptionStyle: PropTypes.func,
  transparent: PropTypes.bool,
  showSelection: PropTypes.bool,
  loading: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  showInput: PropTypes.bool,
  selectIcon: PropTypes.node,
  hidePlaceHolder: PropTypes.bool,
  missingValueErrorText: PropTypes.string,
  onClose: PropTypes.func,
  showOnlyAutoComplete: PropTypes.bool,
  showClearSelection: PropTypes.bool,
  onCreateNew: PropTypes.func,
  hasApplyButton: PropTypes.bool,
  variant: PropTypes.string,
  type: PropTypes.string,
  renderTagSeparator: PropTypes.element,
  hideValue: PropTypes.bool,
  shouldCloseOnChange: PropTypes.func,
  customActionButton: PropTypes.element,
  totalOptionsCount: PropTypes.number,
  skeletonLoading: PropTypes.bool,
  valueAsObject: PropTypes.bool,
};

Select.defaultProps = {
  label: undefined,
  value: undefined,
  options: [],
  size: 'medium',
  onChange: () => {},
  error: '',
  getValueFunc: ({ value }) => value,
  getLabelFunc: ({ title }) => title || '',
  groupByFunc: undefined,
  filterFunc: undefined,
  renderOption: undefined,
  disabled: false,
  disabledTooltipText: undefined,
  isMultiple: false,
  isRequired: false,
  acceptNullValue: false,
  muiProps: undefined,
  placeholder: '',
  startAdornment: undefined,
  freeSolo: undefined,
  style: {},
  transparent: false,
  showSelection: true,
  loading: false,
  disableCloseOnSelect: false,
  showInput: true,
  hidePlaceHolder: false,
  selectIcon: popupIcon,
  missingValueErrorText: 'value missing from options',
  onClose: undefined,
  showOnlyAutoComplete: false,
  showClearSelection: false,
  onCreateNew: undefined,
  hasApplyButton: false,
  variant: 'input',
  type: '',
  renderTagSeparator: undefined,
  hideValue: false,
  shouldCloseOnChange: undefined,
  customActionButton: undefined,
  totalOptionsCount: undefined,
  renderOptionStyle: () => ({}),
  skeletonLoading: false,
  valueAsObject: false,
};

export const SelectTrigger = forwardRef(
  (
    {
      ariaDescribedby,
      selectIcon,
      placeholder,
      transparent,
      showSelection,
      showPlaceholder,
      disabled,
      disabledTooltipText,
      label,
      size,
      open,
      onClick,
      processedValue,
      tooltipValue,
      displayValue,
      hasValidValue,
      chipCount,
      showChipCount,
      variant,
      type,
      sx,
    },
    ref
  ) => {
    const width = useMemo(() => (open ? ref.current.getBoundingClientRect().width : undefined), [open]);
    const content = (
      <Button
        active={open}
        ref={ref}
        variant={variant}
        type={type}
        aria-describedby={ariaDescribedby}
        sx={{ maxWidth: 300, width, justifyContent: 'space-between', ...sx }}
        className={[
          open ? 'Mui-focusVisible' : '',
          hasValidValue ? 'active-filter' : '',
          showPlaceholder ? 'placeholder' : '',
          transparent ? 'transparent' : '',
        ]
          .filter(d => d)
          .join(' ')}
        onClick={onClick}
        size={size}
        disabled={disabled}>
        {showPlaceholder ? (
          <span style={ellipsis}>{placeholder}</span>
        ) : (
          (label || processedValue) && (
            <>
              <span style={{ ...ellipsis, ...(open ? { textOverflow: 'clip' } : {}), ...flex.row, gap: '6px' }}>
                {label}
                {label && displayValue ? ': ' : ''}
                {displayValue && <span style={ellipsis}>{displayValue}</span>}
                {showChipCount && chipCount > 0 && (
                  <AvTooltip variant="message" title={tooltipValue}>
                    <Chip label={`+${chipCount}`} size="small" />
                  </AvTooltip>
                )}
              </span>
              {showSelection ? selectIcon : ''}
            </>
          )
        )}
      </Button>
    );
    return disabled && disabledTooltipText ? (
      <AvTooltip title={disabledTooltipText}>
        <div>{content}</div>
      </AvTooltip>
    ) : (
      content
    );
  }
);

SelectTrigger.propTypes = {
  ariaDescribedby: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  disabledTooltipText: PropTypes.string,
  open: PropTypes.bool,
  hasValidValue: PropTypes.bool,
  transparent: PropTypes.bool,
  placeholder: PropTypes.string,
  showPlaceholder: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  processedValue: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string, PropTypes.number, PropTypes.array]),
  displayValue: PropTypes.string,
  showSelection: PropTypes.bool,
  selectIcon: PropTypes.element,
  chipCount: PropTypes.number,
  showChipCount: PropTypes.bool,
  tooltipValue: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  sx: PropTypes.shape(),
};

SelectTrigger.defaultProps = {
  ariaDescribedby: '',
  onClick: noop,
  size: 'medium',
  disabled: undefined,
  disabledTooltipText: undefined,
  open: undefined,
  hasValidValue: undefined,
  showPlaceholder: undefined,
  transparent: undefined,
  placeholder: undefined,
  label: undefined,
  processedValue: undefined,
  displayValue: undefined,
  showSelection: true,
  selectIcon: popupIcon,
  chipCount: undefined,
  showChipCount: true,
  tooltipValue: undefined,
  variant: 'filter',
  type: undefined,
  sx: {},
};
