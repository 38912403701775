import React from 'react';
import { Box, useTheme } from '@mui/material';
import { flex } from '../AvThemeProvider';
import { dateOptionStyle } from '../SelectDate';

const ShortVal: React.FC<{ value?: number; unit?: string }> = ({ value, unit }) => {
  const theme = useTheme();
  return (
    value &&
    unit && (
      <Box
        sx={{
          ...dateOptionStyle(theme),
          ...flex.center,
          gap: 1,
        }}>
        <span className="interval-shortval" style={{ backgroundColor: theme.palette.colors.primary[150], minWidth: '56px' }}>
          {value}
          {unit[0]}
        </span>
      </Box>
    )
  );
};

export default ShortVal;
