import React, { useState } from 'react';
import { Box, Paper, Popper } from '@mui/material';
import { ellipsis } from '../utils/Utils';
import { flex } from './AvThemeProvider';
import { ItemWithLogo, Logo } from './ItemWithLogo';

interface TooltipContentProps {
  variant: 'destinationsMap' | 'sourcesMap' | 'sourcesMapByName';
  items: string[];
  limit: number;
  size: 'medium' | 'small' | 'collapsed' | 'collapsedMedium';
  isAvatar?: boolean;
}

const TooltipContent: React.FC<TooltipContentProps> = ({ items, limit, variant, size, isAvatar = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = ({ currentTarget }) => setAnchorEl(currentTarget);
  const handleMouseLeave = () => setAnchorEl(null);

  return (
    <Box
      onMouseLeave={handleMouseLeave}
      onClick={e => e.stopPropagation()}
      sx={{ backgroundColor: theme => theme.palette.white.main, border: theme => `2px solid ${theme.palette.white.main}` }}>
      <Box key="source-overflow" onMouseOver={handleClick}>
        <Logo width={size === 'small' || size === 'collapsed' ? 20 : 32} transparent style={{ minWidth: 'max-content' }}>
          <Box
            sx={theme => ({
              fontSize: size === 'small' ? 12 : 13,
              fontWeight: '600',
              color: theme.palette.colors.neutrals[600],
            })}>
            +{items.length - limit}
          </Box>
        </Logo>
      </Box>
      <Popper
        key="source-popover"
        placement="bottom-start"
        anchorEl={anchorEl}
        open={!!anchorEl}
        sx={{
          maxHeight: '600px',
          overflowY: 'scroll',
          boxShadow: (theme: any) => theme.components?.MuiPaper?.styleOverrides?.root?.boxShadow,
          zIndex: theme => theme.zIndex.modal,
        }}>
        <Paper sx={{ p: 2, ...flex.col, gap: 2 }}>
          {items.slice(limit, items.length).map(item => (
            <ItemWithLogo key={item} type={item} variant={variant} isAvatar={isAvatar} />
          ))}
        </Paper>
      </Popper>
    </Box>
  );
};

export const itemWithLogoTableCellStyle = { '&.MuiTableCell-root.MuiTableCell-body': { py: '10px' } };

interface AvItemWithLogoGroupProps {
  variant: 'destinationsMap' | 'sourcesMap' | 'sourcesMapByName';
  items?: string[];
  limit?: number;
  size?: 'medium' | 'small' | 'collapsed' | 'collapsedMedium';
  isAvatar?: boolean;
  sx?: Record<string, any>;
}

const AvItemWithLogoGroup: React.FC<AvItemWithLogoGroupProps> = ({
  items = [],
  variant,
  limit = 2,
  size = 'small',
  isAvatar = false,
  sx = {},
}) => {
  const hideText = ['collapsedMedium', 'collapsed'].includes(size);
  const hasOverflow = items.length > limit + 1;
  const shownItems = [...(hasOverflow ? items.slice(0, limit) : items)];
  return (
    <Box
      sx={{
        ...flex.row,
        ...(hideText
          ? {
              pl: '4px',
              '> div': {
                alignSelf: 'center',
                position: 'relative',
                border: theme => `2px solid ${theme.palette.white.main}`,
                borderRadius: '50%',
                ml: size === 'collapsedMedium' ? '-10px' : '-4px',
              },
            }
          : { gap: 2 }),
        ...sx,
      }}>
      {shownItems.map(value => (
        <ItemWithLogo
          key={value}
          variant={variant}
          hideText={hideText}
          textStyle={ellipsis}
          logoWidth={size === 'collapsedMedium' ? 32 : 24}
          type={value}
          isAvatar={isAvatar}
        />
      ))}
      {hasOverflow && <TooltipContent limit={limit} items={items} variant={variant} size={size} isAvatar={isAvatar} />}
    </Box>
  );
};

export default AvItemWithLogoGroup;
