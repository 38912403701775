import React from 'react';
import { useTheme } from '@mui/material';
import { YAxis } from 'recharts';
import KeyMetricsOverTime from '../../components/Widgets/KeyMetricsOverTime';
import { abbreviateNumber } from '../../utils/Utils';
import { ITrendWidgetProps } from './types';
import { SCORE_TREND_OPTIONS } from './Utils';

const TrendWidget: React.FC<ITrendWidgetProps> = ({ data, metrics, updateFilters, options = [], allowSelection, chartColors }) => {
  const theme = useTheme();
  const getMetricLabel = val => {
    const option = (options.length ? options : SCORE_TREND_OPTIONS).find(({ value }) => value === val);
    return option?.axisLabel;
  };
  const metricsLabels = [...new Set([...metrics].slice(1).map(getMetricLabel))].filter(label => label !== getMetricLabel(metrics[0]));
  const rightYaxis = (
    <YAxis
      key="right"
      label={{
        value: metricsLabels[0],
        style: { textAnchor: 'middle', fill: theme.palette.colors.neutrals[600] },
        angle: -90,
        position: 'left',
        dx: 60,
        offset: 0,
      }}
      yAxisId="right"
      tickCount={5}
      tickMargin={5}
      orientation="right"
      axisLine={false}
      tickLine={false}
      tickFormatter={abbreviateNumber}
      allowDecimals={false}
    />
  );

  return (
    <KeyMetricsOverTime
      data={data}
      limit={2}
      disabledTooltipText="You can select up to 2 metrics, try to delete metric  in order to add other"
      metricOptions={options.length ? options : SCORE_TREND_OPTIONS}
      metrics={metrics}
      additionalAxis={rightYaxis}
      updateFilters={updateFilters}
      labels={{ y: getMetricLabel(metrics[0]) }}
      allowSelection={allowSelection}
      chartColors={chartColors}
    />
  );
};

export default TrendWidget;
