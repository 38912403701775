import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../../components/Permissions';
import { useAvContext } from '../../context/AvContextProvider';
import { APP_PATHS, PAGE_PATHS } from '../../types';
import { entityViewConfig } from '../../utils/entityViewConfig';
import DashboardAssetCoveragePage from '../../views/AssetsCoverage/DashboardAssetCoveragePage';
import AssetsSettings from '../AssetsSettings';
import CustomDashboardsRoute from '../CustomDashboardsRoute';
import GeneralEntityRoute from '../GeneralEntityRoute';
import PoliciesRoute from '../PoliciesRoute';
import ReportsRoute from '../ReportsRoute';

const Assets = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const appPath = APP_PATHS.CAASM;
  return (
    <Route path={appPath}>
      {GeneralEntityRoute(entityViewConfig.Asset.projectionName)}
      {GeneralEntityRoute(entityViewConfig.PolicyViolation.projectionName)}
      {PoliciesRoute()}
      {AssetsSettings()}
      <Route
        path={PAGE_PATHS.ASSET_COVERAGE}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ app: appPath, path: PAGE_PATHS.ASSET_COVERAGE })}>
            <DashboardAssetCoveragePage />
          </ProtectedRoute>
        }
      />
      {ReportsRoute()}
      {CustomDashboardsRoute()}
    </Route>
  );
};
export default Assets;
