import React from 'react';
import { Box } from '@mui/material';
import { flex } from '../../components/AvThemeProvider';
import { GroupHeaderRowType } from '../../components/Table/types';
import { ellipsis } from '../../utils/Utils';
import { ReactComponent as ListSon } from '../../assets/ListSon.svg';

// eslint-disable-next-line import/prefer-default-export
export const getHeadCellWithExpended = ({ headCells }: { headCells: any[] }) =>
  headCells.map((hc, i) =>
    i === 0
      ? {
          ...hc,
          style: {
            '> div': { ...flex.itemsCenter },
          },
          formatter: (v, row, setRows, index) =>
            row.groupHeaderType === GroupHeaderRowType.SUB_GROUP ? (
              <Box
                sx={{
                  ...flex.center,
                  gap: 1,
                  pl: 3,
                  svg: { color: theme => theme.palette.colors.neutrals[600] },
                  ...ellipsis,
                }}>
                <ListSon />
                <Box>{hc.formatter ? hc.formatter(v, row, setRows, index) : v}</Box>
              </Box>
            ) : hc.formatter ? (
              hc.formatter(v, row, setRows, index)
            ) : (
              v
            ),
        }
      : hc
  );
