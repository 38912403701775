import React from 'react';
import * as PropTypes from 'prop-types';
import AvComposedWidget from './AvComposedWidget';

export default function LineWidget({ data, series, dateFormat, sx, className, isDate }) {
  const dataKeyMap = series.reduce((map, { name, dataKey }) => ({ ...map, [dataKey]: name }), {});
  const topDataPlusOthers = data.map(({ date, ...values }) => ({
    date,
    ...Object.keys(values).reduce(
      (obj, key) => ({ ...obj, ...(dataKeyMap[key] ? { [key]: values[key] } : { OTHERS: (obj.OTHERS || 0) + values[key] }) }),
      {}
    ),
  }));

  return (
    <AvComposedWidget
      xAxisKey="date"
      isDate={isDate}
      series={series}
      data={topDataPlusOthers}
      dateFormat={dateFormat}
      sx={sx}
      className={className}
    />
  );
}

LineWidget.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  series: PropTypes.arrayOf(PropTypes.shape()),
  dateFormat: PropTypes.string,
  sx: PropTypes.shape(),
  className: PropTypes.string,
  isDate: PropTypes.bool.isRequired,
};
LineWidget.defaultProps = {
  data: [],
  series: [],
  dateFormat: 'MMM',
  sx: {},
  className: undefined,
};
