import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { useAvContext } from '../../context/AvContextProvider';
import { sortByStringField } from '../../utils/Utils';
import { DeliveryMethodOptions } from './types';
import { emailIntegrationType } from './Utils';

export const useMeasurementsList = ({
  entityName,
  filterFunc = v => v.expression && v.context.includes(entityName),
  onSuccess = d => d,
}: {
  entityName?: string;
  filterFunc?: (v: any) => any;
  onSuccess?: (d) => any;
}) => {
  const { measurements: measurementsRaw = { visible: [] } } = useAvContext();
  return useMemo(
    () => onSuccess({ data: sortByStringField(measurementsRaw.visible.filter(filterFunc), 'displayName') }),
    [entityName, filterFunc]
  );
};

export const useBuildQueryParams = ({ headCellsIds, measurements }) =>
  headCellsIds.map(headCell => {
    const measurement = measurements.find(({ systemName }) => systemName === headCell);
    if (!measurement) {
      return headCell;
    }
    return { systemName: measurement.systemName, alias: headCell };
  });

export const useBuildQueryParamsObject = ({ headCellsMeasurements, measurements }) =>
  headCellsMeasurements.reduce((acc, headCell) => {
    const measurement = measurements.find(({ systemName }) => systemName === headCell);
    if (!measurement) {
      acc[headCell] = headCell;
    } else {
      acc[headCell] = measurement.systemName;
    }
    return acc;
  }, {});

export const useAvailableIntegrationInstance = (typeOptions = Object.values(DeliveryMethodOptions), enabled = true) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['availableIntegrationInstance'],
    queryFn: () =>
      api(GET_INTEGRATION, {
        onSuccess: ({ data }) =>
          [emailIntegrationType, ...data.findAvailableIntegrationInstanceForReports].filter(({ integrationType }) =>
            typeOptions.includes(integrationType)
          ),
      }),
    enabled,
    gcTime: 0,
  });
};

export const GET_INTEGRATION = gql`
  query findAvailableIntegrationInstanceForReports {
    findAvailableIntegrationInstanceForReports {
      id
      name
      integrationType
      active
      redactedConfig
    }
  }
`;
