import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { flex } from '../AvThemeProvider.tsx';
import { ReactComponent as CaretDown } from '../../assets/CaretDown.svg';
import { ExploreLink } from './layout.components.tsx';
import { formatPercent } from '../../utils/dashboardDataUtils';

const caretDown = <CaretDown width={18} height={18} transform="rotate(180)" />;

function StatusWithChartOverview({ data, title, icon, format, actionHref, formatFunc }) {
  const { palette } = useTheme();

  const percentValue = formatFunc?.(data.count) || formatPercent(data.count, data.total);
  const separator = <div style={{ backgroundColor: palette.colors.neutrals[350], borderRadius: 5, width: '1px' }} />;
  const entireData = [
    { a1: 'last month', value: 0 },
    { a1: 'now', value: data.count },
  ];
  return (
    <Box sx={{ ...flex.row, gap: 2, height: '100%', position: 'relative' }}>
      <Box sx={{ svg: { width: 56, height: 56 } }}>{icon}</Box>
      <div style={{ ...flex.col, gap: 8, flexShrink: 0 }}>
        <Typography sx={{ color: palette.colors.neutrals[500], fontWeight: 500 }}>{title}</Typography>
        <span style={{ ...flex.row, gap: '12px' }}>
          <Typography variant="h3" sx={{ mb: 0 }}>
            {format === 'percent' ? percentValue : data.count}
          </Typography>
          {format === 'both' ? (
            <>
              {separator}
              <Typography variant="h4" style={{ color: palette.colors.neutrals[500] }}>
                {percentValue}
              </Typography>
            </>
          ) : (
            ''
          )}
        </span>
        {entireData[0].value !== 0 && (
          <span style={{ color: palette.success.main, fontWeight: 600, ...flex.itemsCenter, gap: '3px' }}>
            {caretDown}
            {formatPercent(entireData[1].value, entireData[0].value)}
            <span style={{ color: palette.colors.neutrals[500], fontWeight: 500 }}>Last Month</span>
          </span>
        )}
      </div>
      {actionHref ? <ExploreLink actionHref={actionHref} title="Tickets" sx={{ position: 'absolute', right: 0, bottom: 0 }} /> : null}
    </Box>
  );
}

export default StatusWithChartOverview;
StatusWithChartOverview.propTypes = {
  data: PropTypes.shape(),
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  format: PropTypes.oneOf(['amount', 'percent', 'both']),
  actionHref: PropTypes.string,
  formatFunc: PropTypes.func,
};
StatusWithChartOverview.defaultProps = {
  data: {},
  format: 'percent',
  actionHref: undefined,
  formatFunc: undefined,
};
