import React, { forwardRef, useMemo, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  IconButton,
  LinearProgress,
  Paper,
  Popper,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AvJsonViewer from '../../../components/AvJsonViewer';
import AvTag from '../../../components/AvTag';
import { flex } from '../../../components/AvThemeProvider';
import AvTable from '../../../components/Table/AvTable';
import { styleNoUpperCase } from '../../../context/utils';
import { uniqBy } from '../../../utils/Utils';
import { ReactComponent as X } from '../../../assets/X.svg';

const clearIcon = <X />;

const PreviewTitle = ({ totals, extra }: { totals: string | number; extra?: React.ReactNode }) => (
  <Box sx={{ fontWeight: 400, fontSize: 14, color: theme => theme.palette.colors.neutrals[500] }}>
    Preview{' '}
    <Box component="span" sx={{ color: theme => theme.palette.colors.neutrals[800] }}>
      top{' '}
      <Box component="span" sx={{ fontWeight: 600 }}>
        {totals}
      </Box>{' '}
      values
    </Box>{' '}
    {extra}
  </Box>
);

const getHeadCells = (headers: string[], nameMap?: Record<string, string>) => [
  { id: 'avalor_unique_key', isKey: true, hidden: true },
  ...headers.map(id => ({
    id,
    label: nameMap?.[id] || id,
    formatter: val => (typeof val === 'object' ? JSON.stringify(val) : val),
    sx: styleNoUpperCase,
  })),
];

type TPreviewDrawer = {
  hasPreviewId: boolean;
  previewRows?: any[];
  headers: string[];
  onClose: () => void;
  isLoading?: boolean;
  label?: string;
  nameMap?: Record<string, string>;
};
export const PreviewDrawer = forwardRef(
  (
    { hasPreviewId, previewRows = [], headers, onClose, isLoading = false, label, nameMap }: TPreviewDrawer,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => (
    <Box
      ref={ref}
      className="prevent-scroll-back-forward"
      sx={{
        position: 'fixed',
        bottom: 0 + (hasPreviewId ? 64 : 0),
        left: 'var(--side-bar-width)',
        width: 'calc(100% - var(--side-bar-width))',
        zIndex: theme => theme.zIndex.drawer,
        '> :first-of-type': { borderTop: theme => `5px solid ${theme.palette.colors.neutrals[150]}` },
      }}>
      <Box sx={{ ...flex.col, gap: 2, backgroundColor: ({ palette }) => palette.white.main, p: 3 }}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Box sx={flex.justifyBetweenCenter}>
            <PreviewTitle
              totals={previewRows.length}
              extra={
                <>
                  for <AvTag text={label || 'Ingested Data'} />
                </>
              }
            />
            <IconButton onClick={onClose}>{clearIcon}</IconButton>
          </Box>
        )}
        <AvTable
          rows={previewRows}
          headCells={getHeadCells(headers, nameMap)}
          hasHeaderFilters={false}
          size="xSmall"
          sx={{ maxHeight: useMediaQuery(useTheme().breakpoints.down('laptop')) ? 280 : 400 }}
          allowEdit={false}
          enableSelectionColor={false}
          resizable
          pagination
          loading={isLoading}
        />
      </Box>
    </Box>
  )
);

const options = ['Table', 'JSON'];
export function PreviewPopper({
  onClose,
  previewPopperData: { anchor },
  previewRows,
  keys,
  isLoading = false,
  flatNested = false,
}: {
  previewPopperData: { anchor };
  onClose: () => void;
  previewRows: any[];
  keys: string[];
  isLoading?: boolean;
  flatNested?: boolean;
}) {
  const [mode, setMode] = useState(options[0]);
  const isSingleHeadCell = keys.length === 1;
  const headCells = isLoading
    ? [{ id: 'loading', isKey: true }]
    : keys.map(key => ({
        id: key,
        isKey: key === 'id' || isSingleHeadCell,
        hidden: key === 'id' && !isSingleHeadCell,
        label: key,
        getValue: v => v,
        formatter: (_, val) => (['object', 'boolean'].includes(typeof val[key]) ? JSON.stringify(val[key]) : val[key]),
        sx: styleNoUpperCase,
        style: { '> div': { maxWidth: '1000px' } },
      }));

  const uniqueRows = useMemo(() => {
    if (flatNested) {
      return uniqBy(
        previewRows.map(v =>
          keys.reduce((acc, cur) => {
            const value = cur.split('.').reduce((nestedObj, key) => nestedObj?.[key], v);
            return { ...acc, [cur]: value };
          }, {})
        ),
        JSON.stringify
      );
    }
    return uniqBy(
      previewRows.map(v => keys.reduce((acc, cur) => ({ ...acc, [cur]: v[cur] }), {})),
      JSON.stringify
    );
  }, [keys, previewRows]);
  return (
    <Popper
      open={!!anchor}
      disablePortal
      anchorEl={anchor}
      placement="right-start"
      sx={{ ...flex.row, zIndex: theme => theme.zIndex.modal + 5, width: 600, maxHeight: 626, height: 626 }}>
      <ClickAwayListener onClickAway={onClose}>
        <Paper sx={{ px: 3, py: 2, ...flex.col, gap: 2, overflow: 'auto', width: '100%' }}>
          <Box sx={flex.justifyBetweenCenter}>
            <PreviewTitle totals="unique" />
            <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
              <ToggleButtonGroup
                color="white"
                value={mode}
                onChange={(e: any) => setMode(e.target.value)}
                size="xSmall"
                exclusive
                sx={{ ...flex.justifyEndCenter }}>
                {options.map(opt => (
                  <ToggleButton key={opt} value={opt}>
                    {opt}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              <IconButton onClick={onClose}>{clearIcon}</IconButton>
            </Box>
          </Box>
          {mode === options[0] ? (
            headCells.length ? (
              <AvTable
                size="xSmall"
                loading={isLoading}
                resizable
                headCells={headCells}
                rows={uniqueRows}
                allowEdit={false}
                enableSelectionColor={false}
                hasHeaderFilters={false}
                pagination
                rowLimit={15}
              />
            ) : null
          ) : (
            <AvJsonViewer data={uniqueRows} style={{ overflow: 'auto' }} />
          )}
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}
