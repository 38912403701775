import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { FeatureFlags, PAGE_PATHS, Permission } from '../types';
import avLazy from '../utils/AvLazy';
import NotificationTargetAuditLogs from '../views/NotificationTargets/NotificationTargetAuditLogs';

const NotificationTargetLogs = avLazy(() => import('../views/NotificationTargets/NotificationTargetLogs'));
const NotificationTargets = avLazy(() => import('../views/NotificationTargets/NotificationTargets'));
const EditNotificationTargets = avLazy(() => import('../views/NotificationTargets/EditNotificationTargets'));
const PickNotificationTargets = avLazy(() => import('../views/NotificationTargets/PickNotificationTargets'));
const NotificationTargetMapping = avLazy(() => import('../views/NotificationTargets/NotificationTargetMapping'));
const NotificationTargetMappingNew = avLazy(() => import('../views/NotificationTargets/NotificationTargetMappingNew'));

const NotificationTargetsRoute = () => {
  const {
    userPermissions: { hasAllowedPermission },
    featureFlags,
  } = useAvContext();
  const newOutegrationScreen = featureFlags[FeatureFlags.NewOutegrationScreen];
  const path = PAGE_PATHS.TARGETS;
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <NotificationTargets />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.CREATE })}>
            <PickNotificationTargets />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/:type/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.CREATE })}>
            <EditNotificationTargets />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE })}>
            <EditNotificationTargets />
          </ProtectedRoute>
        }
      />
      <Route
        path="activityLog/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <NotificationTargetLogs />
          </ProtectedRoute>
        }
      />
      <Route
        path="audit/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <NotificationTargetAuditLogs />
          </ProtectedRoute>
        }
      />
      <Route
        path="audit/:id/mapping/:idMapping"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <NotificationTargetAuditLogs isMapping />
          </ProtectedRoute>
        }
      />
      <Route
        path="mapping/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            {newOutegrationScreen ? <NotificationTargetMappingNew /> : <NotificationTargetMapping />}
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default NotificationTargetsRoute;
