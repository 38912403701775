import React from 'react';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { abbreviateNumber, getPercent } from '../../utils/Utils';
import { flex } from '../AvThemeProvider.tsx';
import AvTooltip from '../AvTooltip.tsx';
import { ExploreLink } from './layout.components.tsx';
import { ReactComponent as DownArrow } from '../../assets/Down.svg';
import { ReactComponent as QuestionCircle } from '../../assets/question_circle.svg';
import { ReactComponent as UpArrow } from '../../assets/Up.svg';

function StatusDescription({ isUpPositive, value, description, type, isComingSoon, days }) {
  const theme = useTheme();
  let colors = [theme.palette.colors.positive[500], theme.palette.colors.negative[500]];
  colors = isUpPositive ? colors : colors.reverse();
  const iconStyle = { width: '100%', height: '100%' };
  const icon =
    type === 'timeComparison' ? (
      value > 0 ? (
        <UpArrow style={{ ...iconStyle, color: colors[0] }} />
      ) : (
        <DownArrow style={{ ...iconStyle, color: colors[1] }} />
      )
    ) : null;
  const absValue = value ? Math.abs(value) : value;
  if (isComingSoon) {
    return (
      <AvTooltip title={`Collecting data.. once ${days} days completed we will show here a comparison trend`}>
        <Box sx={{ ...flex.justifyStartCenter, gap: '5px', whiteSpace: 'nowrap' }}>
          <Box sx={{ fontSize: 13, color: theme.palette.colors.neutrals[500] }}>Coming Soon...</Box>
          <QuestionCircle style={{ height: 16, width: 16, color: theme.palette.colors.neutrals[600] }} />
        </Box>
      </AvTooltip>
    );
  }
  return (
    <Box sx={{ ...flex.justifyStartCenter, gap: '5px', whiteSpace: 'nowrap' }}>
      {absValue !== 0 && icon && <Box sx={{ width: '15px', height: '15px' }}>{icon}</Box>}
      {value && <Box sx={{ fontWeight: 600, color: value > 0 ? colors[0] : colors[1] }}>{absValue}%</Box>}
      <Box sx={{ fontSize: 13, color: theme.palette.colors.neutrals[500] }}>{description}</Box>
    </Box>
  );
}

StatusDescription.propTypes = {
  isUpPositive: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  isComingSoon: PropTypes.bool,
  days: PropTypes.number.isRequired,
};

StatusDescription.defaultProps = {
  isUpPositive: false,
  value: 0,
  description: '',
  type: '',
  isComingSoon: false,
};

function PerenctageViewer({ value, total }) {
  return (
    <>
      <Divider sx={{ height: 19 }} orientation="vertical" />
      <Box sx={{ ...flex.itemsBaseline, gap: '6px' }}>
        <AvTooltip title={abbreviateNumber(value)}>
          <Typography variant="h4" sx={{ mb: 0 }}>
            {abbreviateNumber(value)}{' '}
          </Typography>
        </AvTooltip>
        <Box sx={{ fontSize: 13, color: theme => theme.palette.colors.neutrals[500], whiteSpace: 'nowrap' }}>
          {' '}
          out of{' '}
          <AvTooltip title={total.toLocaleString()}>
            <Box component="span" sx={{ textTransform: 'uppercase' }}>
              {abbreviateNumber(total)}
            </Box>
          </AvTooltip>
        </Box>
      </Box>
    </>
  );
}

PerenctageViewer.propTypes = {
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

function Status({ title, icon, data, series, actionHref, sx, stickDescription }) {
  const isPercentage = data.some(s => !!s.percentage);
  return (
    <Box sx={{ ...flex.alignEnd, gap: 2, position: 'relative', height: '100%', py: 2, px: 3, ...sx }}>
      {icon}
      <Box sx={{ ...(stickDescription ? flex.colItemsStart : { ...flex.colJustifyCenter, gap: 1.5 }), height: '100%' }}>
        {title && <Typography sx={{ color: theme => theme.palette.colors.neutrals[500], fontWeight: 600 }}>{title}</Typography>}
        <Box sx={{ ...flex.justifyBetweenCenter, gap: isPercentage ? 2 : 4 }}>
          {data.map((item, i) => (
            <React.Fragment key={item.id || item.name}>
              {i > 0 && <Divider sx={{ height: 51 }} orientation="vertical" />}
              <Box key={item.value} sx={{ ...flex.colItemsStart, gap: 1 }}>
                <Box sx={{ ...flex.itemsBaseline, gap: 2 }}>
                  <AvTooltip {...(!item.percentage && { title: item.value?.toLocaleString() })}>
                    <Typography variant="h3" sx={{ mb: 0, ...(series[i].valueSize && { fontSize: series[i].valueSize, fontWeight: 400 }) }}>
                      {item.percentage ? getPercent(item.percentage) : abbreviateNumber(item.value)}
                    </Typography>
                  </AvTooltip>
                  {item.percentage && <PerenctageViewer value={item.value} total={item.total} />}
                </Box>
                <StatusDescription
                  isUpPositive={series[i].isUpPositive}
                  isComingSoon={item.isComingSoon}
                  value={item.timeComparison || null}
                  days={series[i].timeComparisonDays}
                  type={series[i].type}
                  description={item.timeComparison ? `Last Month` : <Box sx={{ color: series[i].color, fontWeight: 600 }}>{item.name}</Box>}
                />
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
      {actionHref && <ExploreLink actionHref={actionHref} title="Tickets" sx={{ position: 'absolute', right: 0, bottom: 0 }} />}
    </Box>
  );
}

Status.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  data: PropTypes.arrayOf(PropTypes.shape()),
  series: PropTypes.arrayOf(PropTypes.shape()),
  actionHref: PropTypes.string,
  stickDescription: PropTypes.bool,
  sx: PropTypes.shape(),
};

Status.defaultProps = {
  title: undefined,
  icon: undefined,
  data: [],
  series: [],
  actionHref: undefined,
  stickDescription: false,
  sx: {},
};

export default Status;
