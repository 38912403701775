import { BaseWidget, WidgetCategory, WidgetDefinition } from './base.types';

export enum TextSubType {
  Text = 'TEXT',
}

export type TextCustomDefinition = {
  text: string;
};

export type TextWidget = BaseWidget & {
  category: WidgetCategory.Text;
  definition: WidgetDefinition & { custom: TextCustomDefinition };
};

export type TextTypeWidget = TextWidget & {
  type: TextSubType.Text;
};

export type TextCategoryWidgetTypes = TextTypeWidget;
