import React from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}
const OAuthContext: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  return location.pathname === '/oauth' ? <div /> : children;
};

export default OAuthContext;
