import { generateOption } from '../../utils/Utils';

export enum FrequencyTypes {
  NONE = 'None',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  CUSTOM = 'Custom',
}

export enum CustomFrequencyTypesNames {
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
}

export enum CustomFrequencyTypes {
  DAYS = 'Days',
  HOURS = 'Hours',
  MINUTES = 'Minutes',
}
export enum Months {
  MONTHS = 'Months',
}
export const defaultFrequencyTypes = {
  [Months.MONTHS]: 43800,
  [CustomFrequencyTypes.DAYS]: 1440,
  [CustomFrequencyTypes.HOURS]: 60,
  [CustomFrequencyTypes.MINUTES]: 1,
};

export enum CronEnum {
  SECOND,
  MINUTE,
  HOUR,
  DAY_OF_MONTH,
  MONTH,
  WEEKDAYS,
}
export const defaultCron = '0 0 * * * *';

export const weekOptions = ['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((v, index) => ({ title: v, value: `${index}` }));

export const getOptions = (from: number, to: number, incremental = 1) =>
  Array.from({ length: Math.floor((to - from) / incremental) + 1 }, (_, index) => {
    const value = from + index * incremental;
    return { title: `${value}`, value };
  });

export const minutesToTimeObject = (timeInMinutes: number) => {
  if (timeInMinutes < defaultFrequencyTypes[CustomFrequencyTypes.HOURS]) {
    return { value: timeInMinutes, type: CustomFrequencyTypes.MINUTES };
  }
  if (timeInMinutes < defaultFrequencyTypes[CustomFrequencyTypes.DAYS]) {
    return { value: timeInMinutes / defaultFrequencyTypes[CustomFrequencyTypes.HOURS], type: CustomFrequencyTypes.HOURS };
  }
  if (timeInMinutes < defaultFrequencyTypes[Months.MONTHS]) {
    return { value: timeInMinutes / defaultFrequencyTypes[CustomFrequencyTypes.DAYS], type: CustomFrequencyTypes.DAYS };
  }
  return { value: timeInMinutes / defaultFrequencyTypes[Months.MONTHS], type: Months.MONTHS };
};

export const customFrequencyOptions = {
  [CustomFrequencyTypes.DAYS]: [...getOptions(1, 7), ...[14, 21].map(generateOption)],
  [CustomFrequencyTypes.HOURS]: getOptions(1, 23),
  [CustomFrequencyTypes.MINUTES]: getOptions(10, 50, 10),
};

export const dataLakeCustomFrequencyOptions = (showDataLakeMonths?: boolean) => ({
  ...customFrequencyOptions,
  [CustomFrequencyTypes.DAYS]: getOptions(1, 30),
  ...(showDataLakeMonths ? { [Months.MONTHS]: getOptions(1, 6) } : {}),
});

export const customFrequencyOptionsLimitedLookback = {
  [CustomFrequencyTypes.HOURS]: getOptions(1, 11),
  [CustomFrequencyTypes.MINUTES]: getOptions(10, 50, 10),
};

type CronType = {
  frequency: FrequencyTypes;
  customFrequencyTypes?: CustomFrequencyTypes;
  value?: number;
};

export const getCronType: (cronArray: string[]) => CronType = cronArray => {
  if (cronArray[CronEnum.MINUTE].includes('*/')) {
    return {
      frequency: FrequencyTypes.CUSTOM,
      customFrequencyTypes: CustomFrequencyTypes.MINUTES,
      value: parseInt(cronArray[CronEnum.MINUTE].split('*/')[1], 10),
    };
  }
  if (cronArray[CronEnum.HOUR].includes('*/')) {
    return {
      frequency: FrequencyTypes.CUSTOM,
      customFrequencyTypes: CustomFrequencyTypes.HOURS,
      value: parseInt(cronArray[CronEnum.HOUR].split('*/')[1], 10),
    };
  }
  if (cronArray[CronEnum.DAY_OF_MONTH].includes('*/')) {
    return {
      frequency: FrequencyTypes.CUSTOM,
      customFrequencyTypes: CustomFrequencyTypes.DAYS,
      value: parseInt(cronArray[CronEnum.DAY_OF_MONTH].split('*/')[1], 10),
    };
  }
  if (!['*', '?'].includes(cronArray[CronEnum.WEEKDAYS])) {
    return {
      frequency: FrequencyTypes.WEEKLY,
    };
  }
  return {
    frequency: FrequencyTypes.DAILY,
  };
};

const getTimeArray = (time: string[]) =>
  [time.reduce((hours, t) => [...hours, t.split(':')[0]], [] as string[]).join(','), time[0]?.split(':')[1]].map(t =>
    t.includes('/') ? '*' : t
  );

export const getCronFromObject = (
  cron: string | null,
  time: string[],
  frequency: FrequencyTypes | undefined,
  week: string[],
  customTime: {
    type: CustomFrequencyTypes;
    value: number;
  }
) => {
  if (frequency === FrequencyTypes.NONE) {
    return null;
  }
  const cronArray = (cron || defaultCron).split(' ');
  if (frequency === FrequencyTypes.DAILY) {
    cronArray[CronEnum.SECOND] = '0';
    [cronArray[CronEnum.HOUR], cronArray[CronEnum.MINUTE]] = getTimeArray(time);
    cronArray[CronEnum.DAY_OF_MONTH] = '*';
    cronArray[CronEnum.MONTH] = '*';
    cronArray[CronEnum.WEEKDAYS] = '?';
  } else if (frequency === FrequencyTypes.WEEKLY) {
    cronArray[CronEnum.SECOND] = '0';
    [cronArray[CronEnum.HOUR], cronArray[CronEnum.MINUTE]] = getTimeArray(time);
    cronArray[CronEnum.DAY_OF_MONTH] = '?';
    cronArray[CronEnum.MONTH] = '*';
    cronArray[CronEnum.WEEKDAYS] = week.join(',');
  } else if (frequency === FrequencyTypes.CUSTOM) {
    if (customTime.type === CustomFrequencyTypes.DAYS) {
      cronArray[CronEnum.SECOND] = '0';
      cronArray[CronEnum.MINUTE] = '0';
      cronArray[CronEnum.HOUR] = '0';
      cronArray[CronEnum.DAY_OF_MONTH] = `*/${customTime.value}`;
      cronArray[CronEnum.MONTH] = '*';
      cronArray[CronEnum.WEEKDAYS] = '?';
    } else if (customTime.type === CustomFrequencyTypes.HOURS) {
      cronArray[CronEnum.SECOND] = '0';
      cronArray[CronEnum.MINUTE] = '0';
      cronArray[CronEnum.HOUR] = `*/${customTime.value}`;
      cronArray[CronEnum.DAY_OF_MONTH] = '*';
      cronArray[CronEnum.MONTH] = '*';
      cronArray[CronEnum.WEEKDAYS] = '?';
    } else if (customTime.type === CustomFrequencyTypes.MINUTES) {
      cronArray[CronEnum.SECOND] = '0';
      cronArray[CronEnum.MINUTE] = `*/${customTime.value}`;
      cronArray[CronEnum.HOUR] = '*';
      cronArray[CronEnum.DAY_OF_MONTH] = '*';
      cronArray[CronEnum.MONTH] = '*';
      cronArray[CronEnum.WEEKDAYS] = '?';
    }
  }
  return cronArray.join(' ');
};

export const dayHours = 24;
export const halfDayHours = dayHours / 2;
export const hourMinutes = 60;
