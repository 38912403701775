import React, { useMemo, useState } from 'react';
import { alpha, Box, useTheme } from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Cell, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { isPercentageMetric } from '../../utils/dashboardDataUtils';
import { isExportMode } from '../../utils/exportDashboards.utils.ts';
import { abbreviateNumber, isNullOrUndefined, normalize100PercentData } from '../../utils/Utils';
import AvLegend from '../AvLegend.tsx';
import { flex } from '../AvThemeProvider.tsx';
import AvTooltip from '../AvTooltip.tsx';
import CustomTooltip from './CustomTooltip.tsx';
import { getHorizontalWidth } from './layout.components.tsx';

function CustomTick({ x, y, payload, xOffset, shouldRotateTicks, ...rest }) {
  const maxWidth = shouldRotateTicks ? 85 : 100;
  const theme = useTheme();
  return (
    <foreignObject
      x={x - xOffset}
      y={y - 8}
      width={maxWidth}
      height={rest.height}
      transform={`rotate(${rest.angle}, ${x + 35}, ${y + maxWidth - 28})`}>
      <AvTooltip
        sx={{
          color: theme.palette.colors.neutrals[500],
          fontSize: '12px',
          pr: 1,
          textAlign: rest.textAnchor,
        }}>
        {rest.tickFormatter(payload.value)}
      </AvTooltip>
    </foreignObject>
  );
}

CustomTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape().isRequired,
  xOffset: PropTypes.number,
  shouldRotateTicks: PropTypes.bool,
};

CustomTick.defaultProps = {
  x: 0,
  y: 0,
  xOffset: 0,
  shouldRotateTicks: false,
};

const componentType = {
  bar: ({ props, onSelect, selected = [], data, hoveredCell = {}, setHoveredCell = () => {} }) => (
    <Bar
      yAxisId={props.yAxisId || isPercentageMetric(props.dataKey) || props.isRightAxis ? 'right' : 'left'}
      key={props.dataKey}
      name={props.dataKey || props.name}
      dataKey={props.dataKey}
      barSize={20}
      fill={props.color}
      onClick={d => onSelect?.({ d, dataKey: props.dataKey })}
      hide={props.hide}
      minPointSize={props.minPointSize || 0}
      isAnimationActive={!isExportMode() && props.animation}
      {...(props.stackId && { stackId: props.stackId })}>
      {props?.children ||
        (props.color !== 'transparent' &&
          data.map(entry => (
            <Cell
              onMouseEnter={() => onSelect && setHoveredCell({ name: entry.name, dataKey: props.dataKey })}
              onMouseLeave={() => setHoveredCell(undefined)}
              onBlur={() => setHoveredCell(undefined)}
              cursor={onSelect ? 'pointer' : 'default'}
              filter={
                hoveredCell.name === entry.name && hoveredCell.dataKey === props.dataKey
                  ? `drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.60)`
                  : undefined
              }
              fill={alpha(
                props.color,
                selected.some(v => v.name === entry.name && (!v.dataKey || v.dataKey === props.dataKey)) || selected.length === 0 ? 1 : 0.15
              )}
            />
          )))}
    </Bar>
  ),
  line: ({ props, theme }) => (
    <Line
      yAxisId={props.yAxisId || isPercentageMetric(props.dataKey) || props.isRightAxis ? 'right' : 'left'}
      type={props.lineType || 'monotone'}
      key={props.dataKey}
      name={props.name || props.dataKey}
      dataKey={props.dataKey}
      strokeWidth={3}
      dot={!isNullOrUndefined(props.dot) ? props.dot : { r: 0 }}
      isAnimationActive={!isExportMode() && props.animation}
      hide={props.hide}
      activeDot={
        !isNullOrUndefined(props.activeDot)
          ? props.activeDot
          : { fill: theme.palette.white.main, stroke: props.color, strokeWidth: 3, r: 4 }
      }
      stroke={props.color}
    />
  ),
};

function AvComposedWidget({
  data: d,
  series,
  isDate,
  xAxisKey,
  showLegend,
  labels,
  additionalAxis,
  chartProps,
  defaultAxisProps,
  tooltipProps,
  dateFormat,
  getTooltipContent,
  sx,
  className,
  selected,
  onSelect,
  tooltipComponentProps,
  clickInteractions,
  responsiveContainerProps,
}) {
  const [temporaryClickedCell, setTemporaryClickedCell] = useState();
  const series100Percent = series.filter(({ is100Percent }) => is100Percent);
  const is100Percent = series100Percent.length;
  const data = useMemo(() => (is100Percent ? normalize100PercentData(d, series) : d), [d, series]);
  const theme = useTheme();
  const [hoveredCell, setHoveredCell] = useState();
  const rightAxisMetrics = useMemo(() => series.filter(s => isPercentageMetric(s.dataKey)), [series]);
  const isBar = series.every(({ type }) => type === 'bar');
  const isHorizontal = chartProps?.layout !== 'vertical';
  const shouldRotateTicks = data.length > 6;

  const tickRotationProps = {
    textAnchor: 'end',
    angle: -45,
    height: 80,
    tick: <CustomTick shouldRotateTicks={shouldRotateTicks} xOffset={20} />,
  };

  const onCellSelection = cell => {
    if (clickInteractions && !selected.length) {
      const availableCellClickInteractions = clickInteractions.filter(v => v.isEnabledForCell(cell));
      if (availableCellClickInteractions.length === 1) {
        availableCellClickInteractions[0].onClick(cell);
      } else if (availableCellClickInteractions.length) {
        setTemporaryClickedCell(cell);
      }
      return undefined;
    }
    onSelect?.(cell);
    return undefined;
  };

  const tickFormatterX = isDate ? date => (date ? format(new Date(date), dateFormat || 'MMM') : '') : v => v;
  const tickFormatterY = tick => `${abbreviateNumber(tick)}${is100Percent ? '%' : ''}`;
  const content = (
    <>
      <CartesianGrid stroke={theme.palette.colors.neutrals[300]} vertical={!isHorizontal} horizontal={isHorizontal} />
      <XAxis
        label={{
          ...(labels.x && {
            value: labels.x,
            style: { textAnchor: 'bottom', fill: theme.palette.colors.neutrals[600] },
            dy: 10,
            position: 'center',
            offset: 0,
            ...defaultAxisProps.xLabel,
          }),
        }}
        dy={10}
        dataKey={isHorizontal ? xAxisKey : undefined}
        type={isHorizontal ? 'category' : 'number'}
        axisLine={false}
        tickLine={false}
        tickCount={5}
        {...(shouldRotateTicks && tickRotationProps)}
        tickFormatter={isHorizontal ? tickFormatterX : tickFormatterY}
        {...defaultAxisProps.x}
      />
      <YAxis
        label={{
          ...(labels.y && {
            value: labels.y,
            style: { textAnchor: 'middle', fill: theme.palette.colors.neutrals[600] },
            angle: -90,
            position: 'left',
            offset: isHorizontal ? 0 : getHorizontalWidth(data),
          }),
        }}
        tickMargin={15}
        yAxisId="left"
        tickCount={5}
        dataKey={isHorizontal ? undefined : xAxisKey}
        type={isHorizontal ? 'number' : 'category'}
        orientation="left"
        axisLine={false}
        tickLine={false}
        allowDecimals={false}
        tickFormatter={isHorizontal ? tickFormatterY : tickFormatterX}
        {...(isHorizontal ? {} : { tick: <CustomTick xOffset={90} /> })}
        {...defaultAxisProps.y}
      />
      {additionalAxis}
      {!!rightAxisMetrics?.length && (
        <YAxis
          label
          padding={{ right: 20 }}
          yAxisId="right"
          tickCount={5}
          tickMargin={15}
          orientation="right"
          axisLine={false}
          tickLine={false}
          tickFormatter={tick => `${tick}%`}
          domain={[0, 1]}
          allowDecimals={false}
        />
      )}
      <Tooltip
        cursor={{ fill: alpha(theme.palette.colors.neutrals[300], 0.5) }}
        {...(clickInteractions?.some(v => v.isEnabledForCell(temporaryClickedCell))
          ? { active: !!hoveredCell || !!temporaryClickedCell, trigger: temporaryClickedCell ? 'click' : 'hover' }
          : {})}
        content={
          getTooltipContent ||
          /* eslint-disable-next-line react/no-unstable-nested-components */
          (({ payload } = {}) => (
            <CustomTooltip
              payload={payload}
              isDate={isDate}
              {...tooltipProps}
              dateFormat={dateFormat}
              series100Percent={series100Percent}
              clickInteractions={clickInteractions}
              areClickOptionsVisible={!!temporaryClickedCell}
              setAreClickOptionsVisible={setTemporaryClickedCell}
            />
          ))
        }
        wrapperStyle={{ zIndex: theme.zIndex.modal, pointerEvents: 'auto', overflow: 'auto', maxHeight: '300px' }}
        {...tooltipComponentProps}
      />
      {series.map(s =>
        componentType[s.type]({
          props: s,
          theme,
          selected,
          onSelect: onCellSelection,
          data,
          hoveredCell: temporaryClickedCell ? { ...temporaryClickedCell.d, dataKey: temporaryClickedCell.dataKey } : hoveredCell,
          setHoveredCell: v => setHoveredCell(v),
        })
      )}
    </>
  );

  return (
    <Box sx={{ ...flex.col, height: '100%', gap: 2, ...sx }} onMouseLeave={() => setTemporaryClickedCell(undefined)}>
      {showLegend && <AvLegend series={series} isHorizontal />}
      <Box sx={{ flex: 1, overflow: 'hidden', ...responsiveContainerProps.sx }}>
        <ResponsiveContainer
          className={className}
          width={responsiveContainerProps.width ?? '100%'}
          height={responsiveContainerProps.height ?? '100%'}>
          {isBar ? (
            <BarChart {...chartProps} data={data}>
              {content}
            </BarChart>
          ) : (
            <ComposedChart {...chartProps} data={data}>
              {content}
            </ComposedChart>
          )}
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}

export default AvComposedWidget;

AvComposedWidget.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  series: PropTypes.arrayOf(PropTypes.shape()),
  isDate: PropTypes.bool,
  xAxisKey: PropTypes.string,
  showLegend: PropTypes.bool,
  showTooltipLegend: PropTypes.bool,
  labels: PropTypes.shape(),
  additionalAxis: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  chartProps: PropTypes.shape(),
  defaultAxisProps: PropTypes.shape(),
  dateFormat: PropTypes.string,
  tooltipProps: PropTypes.shape({
    showContentLegend: PropTypes.bool,
    metricsToHide: PropTypes.arrayOf(PropTypes.string),
    showTitle: PropTypes.bool,
    showTitleLegend: PropTypes.bool,
    metricFormatter: PropTypes.func,
  }),
  getTooltipContent: PropTypes.func,
  sx: PropTypes.shape(),
  className: PropTypes.string,
  onSelect: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.shape()),
  tooltipComponentProps: PropTypes.shape(),
  responsiveContainerProps: PropTypes.shape(),
  clickInteractions: PropTypes.arrayOf(PropTypes.shape()),
};

AvComposedWidget.defaultProps = {
  data: [],
  series: [],
  isDate: undefined,
  dateFormat: undefined,
  xAxisKey: undefined,
  showLegend: true,
  showTooltipLegend: true,
  labels: {},
  additionalAxis: undefined,
  chartProps: undefined,
  defaultAxisProps: { x: {}, y: {}, xLabel: {} },
  tooltipProps: { showContentLegend: true, metricsToHide: [], showTitleLegend: false },
  getTooltipContent: undefined,
  sx: {},
  className: undefined,
  selected: [],
  onSelect: undefined,
  tooltipComponentProps: {},
  clickInteractions: undefined,
  responsiveContainerProps: {},
};
