import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import { ReactComponent as CheckboxChecked } from '../assets/CheckboxChecked.svg';
import { ReactComponent as CheckboxUnchecked } from '../assets/CheckboxUnchecked.svg';
import { ReactComponent as CheckboxIntermediate } from '../assets/CheckboxIntermediate.svg';

interface AvCheckboxProps {
  label?: React.ReactNode;
  value?: boolean;
  onChange?: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
  indeterminate?: boolean;
  disabled?: boolean;
  inputProps?: SwitchBaseProps['inputProps'];
  labelColor?: string;
  size?: 'medium' | 'small';
}

const AvCheckbox: React.FC<AvCheckboxProps> = ({
  label,
  value = false,
  onChange = () => {},
  indeterminate,
  disabled,
  inputProps,
  labelColor,
  size = 'medium',
}) => {
  const [focused, setFocused] = useState(false);
  const sx = {
    width: 'fit-content',
    gap: '8px',
    margin: 0,
    '.MuiFormControlLabel-label': { color: labelColor, fontSize: size === 'small' ? 12 : undefined },
  };
  return (
    <FormControlLabel
      sx={sx}
      control={
        <Checkbox
          checked={value}
          indeterminate={indeterminate}
          icon={<CheckboxUnchecked />}
          checkedIcon={<CheckboxChecked />}
          indeterminateIcon={<CheckboxIntermediate />}
          className={focused ? 'Mui-focused' : ''}
          onChange={event => onChange(event.target.checked, event)}
          inputProps={{ onFocus: () => setFocused(true), onBlur: () => setFocused(false), ...inputProps }}
        />
      }
      label={label}
      disabled={disabled}
    />
  );
};
export default AvCheckbox;
