import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';
import avLazy from '../utils/AvLazy';

const UserManagement = avLazy(() => import('../views/UserManagement/UserManagement'));
const EditUserManagement = avLazy(() => import('../views/UserManagement/EditUserManagement'));
const AuditLogsUserManagement = avLazy(() => import('../views/UserManagement/AuditLogsUserManagement'));

const UserManagementRoute = path => {
  const {
    userPermissions: { hasAllowedPermission, isInternalRole },
  } = useAvContext();

  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <UserManagement key={path} />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.CREATE })}>
            <EditUserManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE })}>
            <EditUserManagement showContentPermissions={path === PAGE_PATHS.USER_MANAGEMENT} />
          </ProtectedRoute>
        }
      />
      <Route
        path="audit/:id"
        element={
          <ProtectedRoute isAllowed={isInternalRole}>
            <AuditLogsUserManagement />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default UserManagementRoute;
