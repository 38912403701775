export enum QueryType {
  Advanced = 'ADVANCED',
  Basic = 'BASIC',
}

export type LogQuery = string | undefined;
export type LogsFilter = Tab & { date: Date[] };
export type Tab = {
  tabId: string;
  queryType: QueryType;
  query?: LogQuery;
  title: string;
  page?: number;
  lastSearch?: Date;
  categoryName?: string;
  categoryId?: string | null;
  description?: string;
  headCells?: string[];
};

type LogDataProps = {
  logsData: any[];
  isLoadingLogs: boolean;
  totals: number;
};

export interface NewLogsDashboardProps {
  filters: LogsFilter;
  setFilterAndDraft: (value: Tab, replaceById?: string) => void;
  logsDataProps: LogDataProps;
  activeLog?: string;
  setActiveLog: (value: any) => void;
  lastSearch?: Date;
  onColumnPickerApply?: any;
  currentTab?: Tab;
}

export interface LogsDashboardProps {
  filters: LogsFilter;
  setFilters: (value: LogsFilter) => void;
  logsDataProps: LogDataProps;
  activeLog?: string;
  setActiveLog: (value: any) => void;
  lastSearch?: Date;
  onColumnPickerApply?: any;
  currentTab?: Tab;
}

export type LogsQuery = {
  id?: string;
  name: string;
  description?: string;
  query: string;
  queryType: QueryType;
  logsQueryCategory?: LogsQueryCategoryType;
};

export type LogsQueryCategoryType = {
  id?: string;
  name: string;
};

export type SetFilterAndDraftType = (tab: Tab, replaceById?: string) => void;
