import React from 'react';
import { useParams } from 'react-router-dom';
import RunsPage from '../../components/RunsPage';
import { useAvContext } from '../../context/AvContextProvider';
import { PAGE_PATHS } from '../../types';
import { SearchAttributeType } from '../../types/executionRules.types';
import { GET_WORKFLOWS_RUNS_BY_TYPE } from './hooks';

const PolicyRuns = () => {
  const { id } = useParams();
  const { getPathName } = useAvContext();

  const noDataProps = {
    goBackTitle: 'Policies',
    runsNameTitle: 'Policy',
    goBackPath: getPathName(PAGE_PATHS.POLICIES),
  };

  return (
    <RunsPage
      getWorkflowsQuery={GET_WORKFLOWS_RUNS_BY_TYPE}
      title="Policy Runs"
      noDataProps={noDataProps}
      id={id}
      queryOptions={{ searchAttributeType: SearchAttributeType.ASSET_POLICY }}
    />
  );
};
export default PolicyRuns;
