import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { CartesianGrid, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import { ColorGroup } from '../../types/theme';
import { abbreviateNumber, dotStyle, ellipsis } from '../../utils/Utils';
import { IScatterChartWidgetProps } from '../../views/Risk/types';
import { flex } from '../AvThemeProvider';
import CustomTooltip from './CustomTooltip';

const getCustomShape = ({ chartProps, selectedId }) => <RenderCustomizedLabel chartProps={chartProps} selectedId={selectedId} />;

const ScatterChartWidget: React.FC<IScatterChartWidgetProps> = ({ data: d, selectedId }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1850));
  const data = useMemo(
    () =>
      d?.toSorted((a, b) => {
        if (a.id === selectedId) {
          return 1;
        }
        if (b.id === selectedId) {
          return -1;
        }
        return 0;
      }) || [],
    [selectedId, d]
  );
  const getCustomizedAxisTick = props => <CustomizedAxisTick {...props} />;

  const getTooltipContent = (payload: object[] = []) => (
    <CustomTooltip
      showContentLegend={false}
      // @ts-ignore
      payload={payload.map(item => ({ ...item, payload: { ...item.payload, name: item.payload.title } }))}
    />
  );

  return (
    <Box sx={{ ...flex.row, width: '100%', height: '100%' }}>
      <Box sx={{ ...flex.col, overflow: 'hidden', gap: 2, width: '100%', height: '100%' }}>
        <Box sx={{ ml: 5.3, fontSize: '16px', fontWeight: 600, color: theme => theme.palette.colors.neutrals[500] }}>Overview</Box>
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart margin={{ top: 20, right: 25, bottom: 30, left: 20 }}>
            <CartesianGrid stroke={theme.palette.colors.neutrals[300]} />
            <XAxis
              type="number"
              dataKey="x"
              tick={props => getCustomizedAxisTick({ ...props, isXAxis: true })}
              tickCount={isSmallScreen ? 6 : 9}
              name="Assets"
              axisLine={false}
              tickLine={{ stroke: theme.palette.colors.neutrals[300], transform: 'translate(0, -5)' }}
              allowDecimals={false}
              label={{
                value: 'Number of Assets',
                style: { textAnchor: 'bottom', fill: theme.palette.colors.neutrals[600] },
                dy: 30,
                position: 'center',
                offset: 0,
              }}
            />
            <YAxis
              type="number"
              dataKey="y"
              tickCount={isSmallScreen ? 8 : 11}
              axisLine={false}
              tick={props => getCustomizedAxisTick({ ...props, isXAxis: false })}
              tickLine={{ stroke: theme.palette.colors.neutrals[300] }}
              allowDecimals={false}
              name="Risk"
              label={{
                value: 'Risk Score',
                style: { textAnchor: 'middle', fill: theme.palette.colors.neutrals[600] },
                angle: -90,
                position: 'left',
                offset: 0,
              }}
            />
            <Tooltip
              isAnimationActive={false}
              cursor={false}
              content={({ payload }) => getTooltipContent(payload)}
              wrapperStyle={{ zIndex: theme.zIndex.modal, pointerEvents: 'auto', overflow: 'auto', maxHeight: '300px', top: '10px' }}
            />
            <Scatter
              name="all points"
              data={data}
              fill={theme.palette.colors.neutrals[300]}
              stroke={theme.palette.colors.neutrals[300]}
              isAnimationActive={false}
              shape={props => getCustomShape({ chartProps: props, selectedId })}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default ScatterChartWidget;

type TChartProps = {
  cx: number;
  cy: number;
  payload: { id: string; title: string; color: ColorGroup; isActive: boolean };
  xAxis: { width: number };
};

interface IRenderCustomizedLabelProps {
  chartProps: TChartProps;
  selectedId: IScatterChartWidgetProps['selectedId'];
}

const RenderCustomizedLabel: React.FC<IRenderCustomizedLabelProps> = ({ chartProps, selectedId }) => {
  const containerRef = useRef<HTMLElement>();
  const [dims, setDims] = useState({ width: 0, height: 0 });
  const { cx, cy, payload, xAxis } = chartProps;
  const { id, title, color, isActive } = payload;
  const isSelected = id === selectedId;
  const isOverflowed = useMemo(() => cx > xAxis.width, [cx, xAxis.width]);

  const R = 12;
  const PADDING = isSelected ? 4.5 : 0;
  const BOX_MAX_WIDTH = 240;
  const BOX_MAX_HEIGHT = 50;

  const selectedBoxStyle = {
    border: theme => `1px solid ${theme.palette.colors.neutrals[400]}`,
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, 0.6)',
    backdropFilter: 'blur(0.6px)',
    height: '32px',
    fontWeight: 600,
    '&:hover > :first-child': {
      backgroundColor: color,
    },
    ...(isOverflowed ? { pl: 1.5, pr: 0.5 } : { pl: 0.5, pr: 1.5 }),
  };

  useEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setDims({ width: rect.width, height: rect.height });
    }
  }, []);

  const { width, height } = useMemo(() => dims, [dims]);

  return (
    <foreignObject
      width={Math.min(BOX_MAX_WIDTH, width)}
      height={Math.min(BOX_MAX_HEIGHT, height)}
      x={isOverflowed ? cx - width + PADDING + R : cx - (R + PADDING)}
      y={cy - height / 2}>
      <Box
        ref={containerRef}
        sx={{
          ...flex.itemsCenter,
          flexDirection: isOverflowed ? 'row-reverse' : 'row',
          gap: '5px',
          width: 'fit-content',
          maxWidth: BOX_MAX_WIDTH,
          ...(isSelected && selectedBoxStyle),
        }}>
        <Box
          sx={{
            ...dotStyle(isActive ? color : 'transparent', 24),
            flexShrink: 0,
            border: `3px solid ${color}`,
            '&:hover': {
              backgroundColor: color,
            },
          }}
        />
        {isSelected && <Box sx={{ ...ellipsis, color: theme => theme.palette.colors.neutrals[800] }}>{title}</Box>}
      </Box>
    </foreignObject>
  );
};

interface ICustomizedAxisTickProps {
  x: number;
  y: number;
  payload: { value: number };
  isXAxis?: boolean;
}

const CustomizedAxisTick: React.FC<ICustomizedAxisTickProps> = ({ x, y, payload, isXAxis = false }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={isXAxis ? 0 : -20} y={isXAxis ? 15 : 4} textAnchor="middle">
      {abbreviateNumber(payload.value)}
    </text>
  </g>
);
