import React from 'react';
import { severityOptions } from '../utils/severity.utils';
import { SeverityItem } from './ItemWithLogo';
import Select from './Select';

interface ISeveritySelectProps {
  filterValue: string | string[];
  onChange: (value: string | string[]) => void;
  options?: object[] | string[];
  isMultiple?: boolean;
  size?: string;
  showLabel?: boolean;
  isTransparent?: boolean;
  label?: string;
}

const SeveritySelect: React.FC<ISeveritySelectProps> = ({
  filterValue,
  onChange,
  options = severityOptions,
  isMultiple = true,
  size = 'small',
  showLabel = true,
  isTransparent = false,
  label = 'Severity',
}) => (
  <Select
    {...(showLabel && { label })}
    size={size}
    value={filterValue}
    onChange={val => onChange(val)}
    options={options}
    renderOption={({ value }) => <SeverityItem value={value} />}
    isMultiple={isMultiple}
    variant="filter"
    showInput={false}
    transparent={isTransparent}
  />
);

export default SeveritySelect;
