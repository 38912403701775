import React, { useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { iconSize } from '../utils/Utils';
import AvTooltip from './AvTooltip';
import { ReactComponent as Copy } from '../assets/Copy.svg';
import { ReactComponent as LinkSVG } from '../assets/Link.svg';

const copyIcon = <Copy style={{ ...iconSize(20) }} />;
const linkIcon = <LinkSVG style={{ ...iconSize(17) }} />;

export enum CopyType {
  LINK = 'Link',
  DATA = '',
}

interface CopyLinkProps {
  title?: string;
  copyData: string;
  copyType?: CopyType;
}

const CopyToClipboard: React.FC<CopyLinkProps> = ({ title = false, copyData, copyType }) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const copyLink = () => {
    navigator.clipboard.writeText(copyData);
    setIsLinkCopied(true);
  };

  return (
    <AvTooltip
      title={isLinkCopied ? `${copyType} Copied!` : `Copy ${copyType}`}
      muiProps={{ leaveDelay: isLinkCopied ? 1000 : undefined, TransitionProps: { onExited: () => setIsLinkCopied(false) } }}>
      {title ? (
        <Button size="small" variant="link" onClick={copyLink}>
          {copyType === CopyType.LINK ? <LinkSVG /> : <Copy />}
          {title}
        </Button>
      ) : (
        <IconButton onClick={copyLink}>{copyType === CopyType.LINK ? linkIcon : copyIcon}</IconButton>
      )}
    </AvTooltip>
  );
};

export default CopyToClipboard;
