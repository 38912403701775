import { useAvContext } from '../../context/AvContextProvider';
import { APP_PATHS, PAGE_PATHS } from '../../types';
import { appsOptions } from './appbar.constants';
import { appsSideBar } from './sidebar.constants';

// eslint-disable-next-line import/prefer-default-export
export const useEnabledAppsOptions = () => {
  const {
    accountData,
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  return appsOptions.filter(
    app =>
      accountData.apps?.some(({ name }) => APP_PATHS[name] === app.id) &&
      appsSideBar[app.id]
        .flatMap(r => r.subMenu.flatMap(s => (s.subMenu ? s.subMenu.map(subMenu => ({ ...subMenu, parentPath: s.path })) : s)))
        .filter(({ path }) => ![PAGE_PATHS.CUSTOM_DASHBOARDS, PAGE_PATHS.REPORTS].includes(path))
        .some(({ path }) => hasAllowedPermission({ path, app: app.id }))
  );
};
