import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as Logo } from '../assets/logo.svg';

const animateStyle = {
  '@keyframes escalate': {
    '0%': { clipPath: 'inset(0 0 100% 0)' },
    '19%': { clipPath: 'inset(0 0 100% 0)' },
    '20%': { clipPath: 'inset(0 0 75% 0)' },
    '34%': { clipPath: 'inset(0 0 75% 0)' },
    '35%': { clipPath: 'inset(0 0 55% 0)' },
    '59%': { clipPath: 'inset(0 0 55% 0)' },
    '60%': { clipPath: 'inset(0 0 35% 0)' },
    '79%': { clipPath: 'inset(0 0 35% 0)' },
    '80%': { clipPath: 'inset(0 0 0 0)' },
    '100%': { clipPath: 'inset(0 0 0 0)' },
  },
  color: theme => theme.palette.colors.complementary[400],
};
interface LoadingProps {
  height?: number;
  duration?: number;
  color?: string;
}

const Loading: React.FC<LoadingProps> = ({ height, duration = 1.3, color }) => (
  <Box sx={animateStyle}>
    <Logo style={{ animation: `escalate ${duration}s infinite`, height, width: height, color }} />
  </Box>
);
export default Loading;
