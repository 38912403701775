import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { useAvContext } from '../context/AvContextProvider';
import { AssignmentType } from '../views/DataUnification/constants';
import { Assignment } from '../views/DataUnification/EditRuleSet';
import EditorPopper from '../views/Sources/Mapping/EditorPopper';
import { MappingType, typeLabelMap } from '../views/Sources/Mapping/mapping.types';
import Select from './Select';
import TextInput from './TextInput';
import { ReactComponent as Edit } from '../assets/Edit.svg';

interface AvAssignmentInputProps {
  fieldName: string;
  fieldDisplayName: string;
  assignment?: Assignment;
  onSaveEditorValue: (value: string, currentEditedField: string, assignmentType: AssignmentType) => void;
  projName: string;
  fieldType: string;
}
const typeMapper = {
  [MappingType.String_Template]: AssignmentType.STRING_TEMPLATE,
  [MappingType.Literal]: AssignmentType.VALUE,
  [MappingType.Path]: AssignmentType.FIELD,
};

const reverseTypeMapper = {
  [AssignmentType.STRING_TEMPLATE]: MappingType.String_Template,
  [AssignmentType.FIELD]: MappingType.Path,
  [AssignmentType.VALUE]: MappingType.Literal,
};

const EditIcon = <Edit />;
const AvAssignmentInput = ({ fieldName, fieldDisplayName, assignment, onSaveEditorValue, projName, fieldType }: AvAssignmentInputProps) => {
  const {
    accountEntities: { aggProjs },
  } = useAvContext();

  const { fields } = aggProjs[projName];
  const filterOptions = useMemo(
    () => fields.filter((field: any) => fieldType === typeLabelMap[field.originalType.kind] || false),
    [fields]
  );

  const [showEditor, setShowEditor] = useState(false);
  const fieldRef = useRef<any>(null);

  const [draftAssignment, setDraftAssignment] = useState<Assignment>(assignment || { assignmentType: AssignmentType.VALUE, value: '' });

  useEffect(() => {
    if (assignment) {
      setDraftAssignment(assignment);
    }
  }, [assignment]);

  const buttons = [
    { onActionClick: () => setShowEditor(false), children: 'Cancel' },
    {
      onActionClick: () => {
        onSaveEditorValue(draftAssignment.value as string, fieldName, draftAssignment.assignmentType);
        setShowEditor(false);
      },
      variant: 'contained' as any,
      children: 'Save',
    },
  ];

  const fieldSelector = () => (
    <Select
      value={draftAssignment.assignmentType === AssignmentType.FIELD ? draftAssignment.value : ''}
      onChange={newField => setDraftAssignment({ ...draftAssignment, assignmentType: AssignmentType.FIELD, value: newField })}
      options={filterOptions}
      style={{
        '.MuiOutlinedInput-notchedOutline, .MuiInputBase-root': {
          width: '554px',
        },
      }}
      missingValueErrorText={null}
    />
  );

  return (
    <>
      <TextInput
        label={fieldDisplayName}
        value={assignment?.value.toString()} // TODO - check with liron
        size="small"
        ref={fieldRef}
        disableClearButton
        onClick={() => setShowEditor(true)}
        actionIcons={[<Button onClick={() => setShowEditor(true)}>{EditIcon}</Button>]}
      />
      {showEditor && (
        <EditorPopper
          open={showEditor}
          buttons={buttons}
          anchorEl={fieldRef.current}
          value={draftAssignment.value as string} // TODO - check with liron
          type={reverseTypeMapper[draftAssignment.assignmentType]}
          fieldType={fieldName}
          setType={type => setDraftAssignment({ ...draftAssignment, assignmentType: typeMapper[type], value: '' })}
          handleClose={() => setShowEditor(false)}
          pythonScript={false}
          projectionName={projName}
          onChange={val => setDraftAssignment({ ...draftAssignment, value: val })}
          FieldSelector={fieldSelector}
        />
      )}
    </>
  );
};

export default AvAssignmentInput;
