import React from 'react';
import { Box } from '@mui/material';
import { iconSize, noop } from '../utils/Utils';
import { flex } from './AvThemeProvider';
import AvTooltip from './AvTooltip';
import Select from './Select';
import { ReactComponent as More } from '../assets/More.svg';

type SelectProps = any; // TODO remove when doing Select.tsx refactor

const defulatLabel = (
  <AvTooltip title="More">
    <Box sx={flex.itemsCenter}>
      <More style={iconSize(18)} />
    </Box>
  </AvTooltip>
);
interface AvMoreMenuProps {
  options: {
    title?: string;
    onClick?(...args: unknown[]): unknown;
  }[];
  label?: React.ReactElement;
  customActionButton?: React.ReactElement;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
}
const AvMoreMenu: React.FC<AvMoreMenuProps & SelectProps> = ({
  options,
  label = defulatLabel,
  customActionButton,
  onClick = noop,
  ...selectProps
}) => (
  <Box sx={{ button: { px: '7px' }, display: 'contents' }} onClick={onClick}>
    <Select
      variant="filter"
      showInput={false}
      transparent
      onChange={(v, option) => option.onClick()}
      selectIcon={null}
      label={label}
      options={options.map(o => ({ ...o, value: o.title }))}
      acceptNullValue
      customActionButton={customActionButton}
      {...selectProps}
    />
  </Box>
);

export const renderOptionOfMenu = ({ title, icon }) => (
  <>
    {icon} {title}
  </>
);

export default AvMoreMenu;
