export type EntityTypeID = {
  name: string;
  builtIn: boolean;
};

type ProjectionMetadata = {
  id: EntityTypeID;
  typeId: EntityTypeID;
};

type AggProjection = {
  name: string;
  builtIn: boolean;
};

export type Projection = {
  meta: ProjectionMetadata;
  aggregates: AggProjection;
  settings: any;
  clusteringPredicate: {
    anyofList: {
      fieldsList;
      ignoreNulls;
    }[];
  };
};

export enum DescriptorType {
  TYPE_DOUBLE = 1,
  TYPE_FLOAT = 2,
  TYPE_INT64 = 3,
  TYPE_UINT64 = 4,
  TYPE_INT32 = 5,
  TYPE_FIXED64 = 6,
  TYPE_FIXED32 = 7,
  TYPE_BOOL = 8,
  TYPE_STRING = 9,
  TYPE_GROUP = 10,
  TYPE_MESSAGE = 11,
  TYPE_BYTES = 12,
  TYPE_UINT32 = 13,
  TYPE_ENUM = 14,
  TYPE_SFIXED32 = 15,
  TYPE_SFIXED64 = 16,
  TYPE_SINT32 = 17,
  TYPE_SINT64 = 18,
}

export type AccountEntities = Record<
  string,
  {
    name: string;
    builtIn: boolean;
    projection: Projection;
    entityTypeId: EntityTypeID;
    projId: EntityTypeID;
    aggregates: EntityTypeID;
    projDisplayName: string;
    pathAlias: string;
    typeMap: Record<string, string>;
    nameMap: Record<string, string>;
    fieldList: Record<string, FieldListObject[]>;
    fields: FieldListObject[];
  }
>;

export type FieldListObject = {
  id: string;
  value: string;
  displayName: string;
  builtIn: boolean;
  pathAlias: string;
  systemName: string;
  name: string;
  mainProjId: EntityTypeID;
  title: string;
  group: string;
  entityTypeId: EntityTypeID;
  type: number | string;
  repeated: boolean;
  visibilityConfig: VisibilityConfig;
  originalType: {
    name?: string;
    kind?: string;
  };
  tags: any[];
  isMetadata?: boolean;
  additionalInfo?: Record<string, boolean>;
  otherAliases?: string[];
};

export type FieldVisibilityConfig = {
  hidden: boolean;
};

export type VisibilityConfig = {
  id?: string;
  projectionName: string;
  builtInProjection: boolean;
  fieldName: string;
  config: FieldVisibilityConfig;
};
