import React from 'react';
import { Box, Button } from '@mui/material';
import AvDialog from '../../../components/AvDialog';
import { flex } from '../../../components/AvThemeProvider';

interface EditDialogProps {
  onClose(isApplied): void;
}

const EditPolicyDialog: React.FC<EditDialogProps> = ({ onClose }) => (
  <AvDialog
    title="Edit Policy"
    onClose={() => onClose(false)}
    open={!!onClose}
    noPadding
    footer={
      <Box sx={{ ...flex.justifyEndCenter, gap: 2 }}>
        <Button onClick={() => onClose(false)} size="small">
          Cancel
        </Button>
        <Button onClick={() => onClose(true)} variant="contained" size="small">
          Edit Policy
        </Button>
      </Box>
    }>
    <Box sx={{ px: 3, ...flex.col }}>
      By editing this policy, all existing and future policy violations associated with it will be updated accordingly.
    </Box>
    <Box sx={{ px: 3, ...flex.col, marginTop: 5 }}>Are you sure you want to edit this policy?</Box>
  </AvDialog>
);

export default EditPolicyDialog;
