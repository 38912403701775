export enum RelativeDateType {
  next = 'next',
  last = 'last',
  previous = 'previous',
  before = 'before',
}
export enum RelativeDatePeriod {
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
  weeks = 'weeks',
  OldMonths = 'monthsRelative',
  months = 'months',
}

export enum RelativeDateTitle {
  LastMonth = 'Last Month',
  LastThreeMonths = 'Last 3 Months',
}

export enum PeriodBehavior {
  last = 'LAST',
  previous = 'PREVIOUS',
  next = 'NEXT',
}

export const epochDateString = '1970-01-01T00:00:00';
