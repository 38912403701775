import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { LayoutContext } from '../../context/LayoutContext';
import { AvalorTableIds, isAuto } from './Utils';

const minWidth = 100;

const useResize = (storageKey, resizable, freeSpaceRef) => {
  const {
    layoutWidths: { [storageKey]: LocalColumnsWidth },
    setLayoutWidths,
  } = useContext(LayoutContext);
  const [columnWidths, setColumnWidths] = useState(storageKey && LocalColumnsWidth ? LocalColumnsWidth : {});
  const columnRefs = useRef({});
  const [resizingColId, setResizingColId] = useState<string>();
  const [columnsToAddFreeSpace, setColumnsToAddFreeSpace] = useState<string[]>([]);
  const columnsToAddFreeSpaceTemp = [
    ...Object.keys(columnRefs).filter(colId => columnRefs?.[colId]?.parentElement?.firstChild?.clientWidth >= 300),
  ];
  const freeSpace = useMemo(
    () => freeSpaceRef[AvalorTableIds.FreeSpaceId]?.clientWidth,
    [freeSpaceRef[AvalorTableIds.FreeSpaceId]?.clientWidth === undefined || columnsToAddFreeSpaceTemp.length]
  );
  useEffect(() => {
    if (Object.keys(columnWidths).filter(id => isAuto(columnWidths, id)).length) {
      setColumnsToAddFreeSpace(columnsToAddFreeSpaceTemp);
    }
  }, [columnsToAddFreeSpaceTemp.length]);

  useEffect(() => {
    if (resizable) {
      if (!LocalColumnsWidth) {
        setColumnsToAddFreeSpace([
          ...Object.keys(columnRefs).filter(colId => columnRefs?.[colId]?.parentElement?.firstChild?.clientWidth === 300),
        ]);
        const initColumnWidth = Object.keys(columnRefs)
          .filter(ref => columnRefs[ref])
          .reduce((obj, columnRef) => ({ ...obj, [columnRef]: 'auto' }), {});
        setColumnWidths(initColumnWidth);
      } else {
        setColumnWidths(LocalColumnsWidth);
      }
    }
  }, [storageKey]);

  const generateNewColumnWidths = columnWidths =>
    Object.keys(columnWidths).reduce(
      (acc, col) =>
        columnRefs[col]?.parentElement
          ? {
              ...acc,
              [col]: columnRefs[col].parentElement.getBoundingClientRect().width,
            }
          : acc,
      {}
    );

  const getNewWidth = (e, colId, columnWidths) => {
    const width = e.clientX - columnRefs[colId].parentElement.getBoundingClientRect().left;
    return { ...columnWidths, [colId]: Math.max(width, minWidth) };
  };

  const onResizeColumn = (e, colId) => {
    setResizingColId(colId);
    const initNewColumnWidths = generateNewColumnWidths(getNewWidth(e, colId, columnWidths));
    setColumnWidths(initNewColumnWidths);
    setLayoutWidths(storageKey, initNewColumnWidths);
    const handleOnMouseMove = e => setColumnWidths(getNewWidth(e, colId, initNewColumnWidths));

    const handleOnMouseUp = e => {
      document.removeEventListener('mouseup', handleOnMouseUp, true);
      document.removeEventListener('touchend', handleOnMouseUp, true);
      document.removeEventListener('mousemove', handleOnMouseMove, true);
      // @ts-ignore
      document.removeEventListener('touchmove', handleOnMouseMove, { passive: false });
      setResizingColId(undefined);
      setLayoutWidths(storageKey, generateNewColumnWidths(getNewWidth(e, colId, initNewColumnWidths)));
    };
    document.addEventListener('mouseup', handleOnMouseUp, true);
    document.addEventListener('touchend', handleOnMouseUp, true);
    document.addEventListener('mousemove', handleOnMouseMove, true);
    document.addEventListener('touchmove', handleOnMouseMove, { passive: false });
  };
  const autoFitCol = colId => {
    setColumnWidths(prev => ({ ...prev, [colId]: 'auto_fit' }));
    setLayoutWidths(storageKey, { ...columnWidths, [colId]: 'auto_fit' });
    setTimeout(() => setResizingColId(undefined));
  };
  return {
    columnWidths,
    columnRefs,
    resizingColId,
    onResizeColumn,
    autoFitCol,
    LocalColumnsWidth,
    columnsToAddFreeSpace,
    freeSpace,
  };
};

export default useResize;
