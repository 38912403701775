import React, { useEffect, useState } from 'react';
import { Button, ClickAwayListener, Divider, Paper, Popper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAvContext } from '../context/AvContextProvider';
import { Permission, PermissionEntitiesNames } from '../types';
import { ScreenType, ViewType } from '../types/savedViews.types';
import AvCheckbox from './AvCheckbox';
import { flex } from './AvThemeProvider';
import TextInput from './TextInput';
import { ReactComponent as InfoFullPurple } from '../assets/colorful/Info_full_purple.svg';
import { ReactComponent as X } from '../assets/X.svg';

const clearIcon = <X />;
const infoFullPurpleIcon = <InfoFullPurple />;

export const useSaveView = () => {
  const {
    api,
    userPermissions: { hasAllowedPermissionToResource },
  } = useAvContext();
  return ({ global, favorite, createdAt, createdByUserId, ...body }: any, onSuccess, isEditMode) => {
    const { viewId, ...config } = body.config;
    api(`view${!isEditMode ? `/?accountDefaultView=${body?.accountDefaultView || false}` : ''}`, {
      options: {
        method: isEditMode ? 'PUT' : 'POST',
        enabled: hasAllowedPermissionToResource({ resource: PermissionEntitiesNames.VIEWS, permission: Permission.CREATE }),
      },
      body: { ...body, config },
    }).then(onSuccess);
  };
};

interface SavedViewPopperProps {
  view: Partial<ViewType>;
  popperProps: {
    open: boolean;
    isEditMode: boolean;
  };
  refetchViews: (d: any) => void;
  handleClose: () => void;
  anchorEl?: Element;
  screenType: ScreenType;
}

const SavedViewPopper: React.FC<SavedViewPopperProps> = ({
  view = {},
  anchorEl = null,
  popperProps = { open: false, isEditMode: true },
  handleClose,
  refetchViews,
  screenType,
}) => {
  const [viewInfo, setViewInfo] = useState<Partial<ViewType>>({});
  const {
    userPermissions: { hasAllowedPermissionToResource },
  } = useAvContext();
  useEffect(() => {
    setViewInfo({ ...view, name: view?.name || '', publicView: view?.publicView || false });
  }, [view]);
  const onChangeInfo = (key, value) => setViewInfo(prev => ({ ...prev, [key]: value }));
  const saveView = useSaveView();
  const isAllowToEditAccountDefaultView = hasAllowedPermissionToResource({
    resource: PermissionEntitiesNames.ACCOUNT,
    permission: Permission.UPDATE,
  });

  const onSaveAsView = () => {
    saveView({ ...view, ...viewInfo, screenType }, refetchViews, popperProps.isEditMode);
    handleClose();
  };

  const onCancel = () => {
    handleClose();
    setViewInfo(view);
  };

  const saveViewInfo = (
    <Box className="info">
      {infoFullPurpleIcon}
      <Box sx={{ ...flex.col }}>
        <span>By Saving the view you are about to save current selection of:</span>
        <span>-time and query</span>
        <span>-filters</span>
        <span>-grouping</span>
      </Box>
    </Box>
  );

  const buttons = (
    <>
      <Button size="small" onClick={onCancel}>
        Cancel
      </Button>
      <Button disabled={!viewInfo?.name?.trim().length} onClick={onSaveAsView} size="small" variant="contained">
        Save
      </Button>
    </>
  );

  return (
    <Popper
      open={popperProps.open}
      anchorEl={anchorEl}
      placement="bottom-end"
      sx={{ width: 390, zIndex: theme => theme.zIndex.modal, pr: 0, pl: 0 }}>
      <ClickAwayListener onClickAway={handleClose}>
        <Paper sx={{ ...flex.col, gap: 1 }}>
          <Box sx={popperBoxStyle}>
            <Button className="clearIcon" onClick={handleClose}>
              {clearIcon}
            </Button>
            <Typography className="title">{popperProps.isEditMode ? 'Edit Current View' : 'Save Current state as a new view? '}</Typography>
            <TextInput
              isRequired
              size="small"
              sx={{ width: 340, maxWidth: '100%' }}
              label="View name"
              value={viewInfo?.name || ''}
              helperText="Name this view"
              onChange={e => onChangeInfo('name', e)}
            />
            <Box className="permissions">
              <Typography>View permissions</Typography>
              <ToggleButtonGroup
                color="white"
                value={viewInfo?.publicView ? 'public' : 'private'}
                onChange={(e: any) => onChangeInfo('publicView', e.target.value === 'public')}
                size="small"
                exclusive
                sx={{ ...flex.justifyEndCenter }}>
                <ToggleButton value="public">Public</ToggleButton>
                <ToggleButton value="private">Private</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {!popperProps.isEditMode ? (
              <AvCheckbox
                label={<Box sx={{ color: theme => theme.palette.colors.neutrals[600], fontSize: 13 }}>Set as Account Default View</Box>}
                onChange={checked => onChangeInfo('accountDefaultView', checked)}
                value={viewInfo?.accountDefaultView || false}
                disabled={!isAllowToEditAccountDefaultView}
              />
            ) : null}
            {!popperProps.isEditMode ? saveViewInfo : null}
          </Box>
          <Divider />
          <Box sx={{ alignSelf: 'end', ...flex.row, gap: 1, p: 2, pt: 1, pr: 3 }}>{buttons}</Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};
export default SavedViewPopper;

const popperBoxStyle = {
  m: '16px 24px 16px 24px',
  gap: 2,
  ...flex.colItemsStart,
  '.clearIcon': {
    position: 'absolute',
    right: 16,
    top: 16,
  },
  '.title': {
    fontWeight: 600,
    fontSize: 16,
  },
  '.permissions': {
    ...flex.justifyBetweenCenter,
    width: '340px',
    '.MuiTypography-root': {
      color: theme => theme.palette.colors.neutrals[500],
      fontSize: 13,
    },
  },
  '.info': {
    ...flex.justifyBetween,
    backgroundColor: theme => theme.palette.colors.primary[100],
    height: '135px',
    gap: 2,
    padding: 2,
    borderRadius: 1,
    span: {
      fontSize: 14,
    },
  },
};
