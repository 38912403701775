import React from 'react';
import { flex } from '../../components/AvThemeProvider';
import { entityViewConfig } from '../../utils/entityViewConfig';
import { severityOptions } from '../../utils/severity.utils';
import { useMeasurementsList } from '../Reports/hooks';
import { ReactComponent as Settings } from '../../assets/Settings.svg';

export const NUM_OF_PIE_ITEMS = 4;
export const timeComparisonXMonth = 2;
export const DAYS_AGO_PERIOD = 7;
export const MONTH_AGO_PERIOD = 6;

export const methodOptionsWithRiskMass = [
  { title: 'AVG Risk', value: 'risk_avg' },
  { title: 'Max Risk', value: 'risk_max' },
  { title: 'Risk Mass', value: 'risk_mass' },
];

export const defaultFilters = pathAlias => ({
  [`${pathAlias}.severity`]: severityOptions.map(({ value }) => value).filter(v => v !== 'INFO'),
  [`${pathAlias}.source_names`]: [],
  [`${pathAlias}.type`]: [],
  [`${pathAlias}.asset_type`]: [],
  method: methodOptionsWithRiskMass[0].value,
  scatterBy: 'asset.owner_id',
});

export const SCORE_TREND_OPTIONS = [
  { title: 'Active Findings', value: 'active_findings', axisLabel: 'Findings' },
  { title: 'Remediated Findings', value: 'remediated_findings', axisLabel: 'Findings' },
  { title: 'New Findings', value: 'new_findings', axisLabel: 'Findings' },
  { title: 'Unique CVEs', value: 'unique_cves', axisLabel: 'CVEs' },
  // { title: 'Infected Assets %', value: 'infected_assets_percent', axisLabel: 'Assets %' },
  // { title: 'Infected Assets', value: 'infected_assets', axisLabel: 'Assets' },
  { title: 'Risk Score', value: 'risk', axisLabel: 'Risk' },
  // { title: 'Average Asset Risk', value: 'average_asset_risk', axisLabel: 'Risk' },
];

export const GRID_LAYOUT = {
  isXsmallScreen: [
    { i: 'a', x: 0, y: 0, w: 3, h: 6, static: true },
    { i: 'b', x: 3, y: 0, w: 9, h: 6, static: true },
    { i: 'g', x: 0, y: 6, w: 6, h: 7, static: true },
    { i: 'h', x: 6, y: 6, w: 6, h: 7, static: true },
    { i: 'c', x: 0, y: 13, w: 6, h: 3, static: true },
    { i: 'd', x: 0, y: 13, w: 6, h: 3, static: true },
    { i: 'e', x: 6, y: 13, w: 6, h: 3, static: true },
    { i: 'f', x: 6, y: 13, w: 6, h: 3, static: true },
    { i: 'i', x: 0, y: 19, w: 12, h: 8, static: true },
    { i: 'j', x: 0, y: 27, w: 12, h: 20, static: true },
  ],
  isSmallScreen: [
    { i: 'a', x: 0, y: 0, w: 2.5, h: 6, static: true },
    { i: 'b', x: 2.5, y: 0, w: 9.5, h: 6, static: true },
    { i: 'g', x: 0, y: 6, w: 6, h: 7, static: true },
    { i: 'h', x: 6, y: 6, w: 6, h: 7, static: true },
    { i: 'c', x: 0, y: 13, w: 3, h: 3, static: true },
    { i: 'd', x: 0, y: 13, w: 3, h: 3, static: true },
    { i: 'e', x: 3, y: 13, w: 3, h: 3, static: true },
    { i: 'f', x: 3, y: 13, w: 3, h: 3, static: true },
    { i: 'i', x: 6, y: 13, w: 6, h: 6, static: true },
    { i: 'j', x: 0, y: 19, w: 12, h: 12, static: true },
  ],
  defaultScreen: [
    { i: 'a', x: 0, y: 0, w: 1.5, h: 6, static: true },
    { i: 'b', x: 1.5, y: 0, w: 6.5, h: 6, static: true },
    { i: 'c', x: 8, y: 0, w: 2, h: 3, static: true },
    { i: 'd', x: 8, y: 0, w: 2, h: 3, static: true },
    { i: 'e', x: 10, y: 0, w: 2, h: 3, static: true },
    { i: 'f', x: 10, y: 0, w: 2, h: 3, static: true },
    { i: 'g', x: 0, y: 6, w: 4, h: 7, static: true },
    { i: 'h', x: 4, y: 6, w: 4, h: 7, static: true },
    { i: 'i', x: 8, y: 6, w: 4, h: 7, static: true },
    { i: 'j', x: 0, y: 13, w: 12, h: 12, static: true },
  ],
};

export const Types = {
  timeComparisonDays: 'timeComparison',
  label: 'label',
};

export const statusesData = {
  riskScore: {
    series: [
      {
        valueSize: '40px',
        type: Types.timeComparisonDays,
        timeComparisonDays: timeComparisonXMonth,
        isUpPositive: false,
      },
    ],
  },
  activeFindingsSeries: [
    {
      type: Types.timeComparisonDays,
      timeComparisonDays: timeComparisonXMonth,
      isUpPositive: false,
    },
  ],
  infectedAssetsSeries: [
    {
      type: Types.timeComparisonDays,
      timeComparisonDays: timeComparisonXMonth,
      isUpPositive: false,
    },
  ],
  last7days: {
    series: [
      {
        name: 'Discovered',
        type: Types.label,
        color: theme => theme.palette.colors.negative[500],
      },
      {
        name: 'Undetected',
        type: Types.label,
        color: theme => theme.palette.colors.positive[500],
      },
    ],
    data: [
      { name: 'Discovered', value: 2678 },
      { name: 'Undetected', value: 226_000 },
    ],
  },
  uniqueCveSeries: [
    {
      type: Types.timeComparisonDays,
      timeComparisonDays: timeComparisonXMonth,
      isUpPositive: false,
    },
  ],
};

export const startAdornment = text => (
  <div style={{ ...flex.justifyBetweenCenter, gap: '5px' }}>
    <Settings /> {text}
  </div>
);

export const useRiskMeasurementOptions = ({ method }) => {
  const runTimeArgsPopulated = {
    method,
  };

  const { data: measurements } = useMeasurementsList({
    entityName: entityViewConfig.Ticket.entityTypeId,
    filterFunc: v => v.context.includes('Finding'),
  });

  return (measurements || []).reduce(
    (acc, { displayName, systemName, runtimeArgs, ...others }) => {
      const args = runtimeArgs.length ? `(${runtimeArgs.map(arg => runTimeArgsPopulated[arg])})` : '';
      acc.measurementOptions = [
        ...acc.measurementOptions,
        {
          value: systemName,
          selectValue: `${systemName}${args} as ${systemName}`,
          title: displayName,
          axisLabel: displayName.toLowerCase().includes('findings')
            ? 'Findings'
            : systemName.toLowerCase().includes('risk')
              ? 'Risk'
              : 'CVEs',
        },
      ];
      acc.metricsRaw = [...acc.metricsRaw, { displayName, systemName, runtimeArgs, ...others }];
      acc.selectValueMap[systemName] = `${systemName}${args} as ${systemName}`;

      return acc;
    },
    {
      measurementOptions: [],
      selectValueMap: {},
      metricsRaw: [],
    }
  );
};
