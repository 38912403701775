import React, { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { noop } from '../utils/Utils';
import AvTooltip from './AvTooltip';
import { ReactComponent as Export } from '../assets/Export.svg';

interface ExportButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  width?: number;
  exportLimit?: number;
  totalRowsCount?: number;
  customTooltipText?: string;
  showToolbarOnDisabled?: boolean;
}

const ExportButton: FC<ExportButtonProps> = ({
  disabled,
  loading,
  onClick = noop,
  width = 20,
  exportLimit = Infinity,
  totalRowsCount = 100,
  customTooltipText = `Export ${totalRowsCount > exportLimit ? `first ${exportLimit.toLocaleString()} values ` : ''}as CSV`,
  showToolbarOnDisabled = false,
}) => (
  <AvTooltip title={customTooltipText} disabled={!showToolbarOnDisabled && disabled}>
    <div>
      <LoadingButton disabled={disabled} variant="filter" className="transparent" sx={{ px: 1 }} loading={loading} onClick={onClick}>
        <Export style={{ width, height: width }} />
      </LoadingButton>
    </div>
  </AvTooltip>
);
export default ExportButton;
