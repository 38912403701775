export enum DateGap {
  Day = '1 DAYS',
  Week = 'WEEK',
  Month = '1 MONTHS',
}

export enum DateFormats {
  '1 DAYS' = 'P',
  'WEEK' = 'dd MMM yyyy',
  '1 MONTHS' = 'MMMM yyyy',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type EnsureGapHasFormats = (typeof DateFormats)[DateGap]; // this will make validation for us that any member of enum has value

export const getUTCDate = (dateString: string) => new Date(`${dateString.replace(' ', 'T')}Z`);
