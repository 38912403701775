import React, { useMemo } from 'react';
import { alpha, Box } from '@mui/material';
import { format, isSameYear, isValid, parse } from 'date-fns';
import PropTypes from 'prop-types';
import { flex } from './AvThemeProvider.tsx';
import AvTooltip from './AvTooltip.tsx';
import Select from './Select';
import { ReactComponent as Calendar } from '../assets/Calendar.svg';
import { ReactComponent as Error } from '../assets/colorful/Fail_full.svg';

const calendarIcon = <Calendar />;
const errorIcon = <Error />;

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 30;
const YEAR = MONTH * 12;

function timeSince(timeSpan) {
  let interval = timeSpan / YEAR;
  if (interval >= 1) {
    return `${Math.floor(interval)}y`;
  }
  interval = timeSpan / MONTH;
  if (interval >= 1) {
    return `${Math.floor(interval)}mo`;
  }
  interval = timeSpan / DAY;
  if (interval >= 1) {
    return `${Math.floor(interval)}d`;
  }
  interval = timeSpan / HOUR;
  if (interval >= 1) {
    return `${Math.floor(interval)}h`;
  }
  interval = timeSpan / MINUTE;
  if (interval >= 1) {
    return `${Math.floor(interval)}m`;
  }
  return `${Math.floor(timeSpan / 1000)}s`;
}

export const dateFilterOptions = [
  {
    title: 'Past 15 Minutes',
    value: MINUTE * 15,
    shortVal: '15m',
  },
  {
    title: 'Past 1 Hour',
    value: HOUR,
    shortVal: '1h',
  },
  {
    title: 'Past 4 Hours',
    value: HOUR * 4,
    shortVal: '4h',
  },
  {
    title: 'Past 1 Day',
    value: DAY,
    shortVal: '1d',
  },
  {
    title: 'Past 2 Days',
    value: DAY * 2,
    shortVal: '2d',
  },
  {
    title: 'Past 3 Days',
    value: DAY * 3,
    shortVal: '3d',
  },
  {
    title: 'Past 7 Days',
    value: DAY * 7,
    shortVal: '7d',
  },
  {
    title: 'Past 15 Days',
    value: DAY * 15,
    shortVal: '15d',
  },
  {
    title: 'Past 30 Days',
    value: DAY * 30,
    shortVal: '1mo',
  },
];
export const allTimesOption = { title: 'All Time', value: DAY * 365 * 15, shortVal: 'ALL' };

const getOptions = showAllOption => (showAllOption ? [allTimesOption, ...dateFilterOptions] : dateFilterOptions);

export const dateOptionStyle = theme => ({
  display: 'flex',
  gap: '16px',
  '.interval-shortval': {
    width: 56,
    borderRadius: '8px',
    backgroundColor: alpha(theme.palette.colors.neutrals[350], 0.88),
    color: theme.palette.colors.neutrals[600],
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    '[aria-selected="true"] &': {
      backgroundColor: alpha(theme.palette.colors.primary[200], 0.88),
    },
  },
});

const inputPrefixStyle = {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  '> div > .interval-shortval': {
    backgroundColor: theme => theme.palette.colors.primary[150],
    '+ span': {
      display: 'none',
    },
  },
};
const formatDate = (date, token) => (isValid(date) ? format(date, `${token}, p`) : 'Invalid');
const getValueFunc = val =>
  Number.isNaN(val.value)
    ? val.value
    : val.value
      ? [new Date(Date.now() - val.value), new Date()]
      : val === allTimesOption.title || val.value === 0
        ? []
        : val.split('-').map(date => {
            const parsedDate = parse(date.trim(), 'MMM dd, yyyy, p', new Date());
            return isValid(parsedDate) ? parsedDate : parse(date.trim(), 'MMM dd, p', new Date());
          });

// TODO: MAKE IT READABLE + Add Specs
export function SelectDate({ value, onChange, error: e, showAllTimeOption, sx }) {
  const now = useMemo(() => new Date(), []);
  const renderOption = ({ shortVal, title }) => (
    <Box sx={dateOptionStyle}>
      <span className="interval-shortval">{shortVal}</span>
      <span>{title}</span>
    </Box>
  );
  const startAdornment = val => (
    <Box sx={inputPrefixStyle}>
      {calendarIcon}
      {renderOption(val)}
    </Box>
  );
  const formatToken = value.length && isSameYear(now, value[0]) && isSameYear(value[0], value[1]) ? 'MMM dd' : 'MMM dd, yyyy';
  const timeSpan = value[1] - value[0];
  const error = e || (value.length && Number.isNaN(timeSpan) ? 'Invalid Date' : undefined);

  const select = (
    <Select
      value={{
        dateLabel: value.length ? `${formatDate(value[0], formatToken)} - ${formatDate(value[1], formatToken)}` : allTimesOption.title,
        shortVal: error ? 'X' : value.length ? timeSince(timeSpan) : allTimesOption.shortVal,
        value: timeSpan,
      }}
      valueAsObject
      onChange={(val, opt) => onChange(opt.title === allTimesOption.title ? [] : val)}
      options={getOptions(showAllTimeOption).map(opt => {
        const optDate = new Date(Date.now() - opt.value);
        const formatToken = isSameYear(optDate, now) ? 'MMM dd' : 'MMM dd, yyyy';
        return {
          ...opt,
          dateLabel:
            opt.title === allTimesOption.title
              ? allTimesOption.title
              : `${formatDate(optDate, formatToken)} - ${formatDate(now, formatToken)}`,
        };
      })}
      renderOption={renderOption}
      getValueFunc={getValueFunc}
      getLabelFunc={val => val.dateLabel || val}
      isRequired
      size="small"
      startAdornment={startAdornment}
      freeSolo
      error={error}
      style={{
        '&, & > .MuiTextField-root': { maxWidth: '400px' },
        '.MuiOutlinedInput-input::selection': { backgroundColor: theme => theme.palette.colors.primary[150] },
      }}
      sx={sx}
      muiProps={{ autoSelect: true }}
    />
  );
  return error ? (
    <AvTooltip
      variant="message"
      color="white"
      title={
        <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
          {errorIcon}
          {error}
        </Box>
      }>
      <Box sx={{ width: 400 }}>{select}</Box>
    </AvTooltip>
  ) : (
    select
  );
}

SelectDate.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  showAllTimeOption: PropTypes.bool,
  sx: PropTypes.shape(),
};
SelectDate.defaultProps = {
  value: [],
  error: undefined,
  showAllTimeOption: false,
  sx: {},
};
