import React, { ReactNode, useState } from 'react';
import { Box, Card, Collapse, SxProps, Typography } from '@mui/material';
import { ExpandCard } from '../utils/Utils';
import { flex } from './AvThemeProvider';

const StyledCard = (expanded, isStatic, variant) => ({
  cursor: expanded ? undefined : 'pointer',
  mt: 1,
  ...(expanded
    ? {}
    : {
        '&:hover': {
          ...(variant === 'list' && { boxShadow: '0px 2px 20px 0px rgba(18, 18, 27, 0.15)' }),
        },
        '&:not(:hover)': {
          boxShadow: 'none',
        },
      }),
  ...(variant === 'list' && {
    flexShrink: 0,
    borderRadius: 3,
    border: '1px solid',
    borderColor: theme => theme.palette.colors.neutrals[350],
    mt: 0,
  }),
  '.card-header': {
    ...flex.justifyBetweenCenter,
    cursor: isStatic ? undefined : 'pointer',
  },
  '> .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner': {
    ...flex.col,
    pt: 3,
    gap: '10px',
  },
});

interface AvFormCardProps {
  title: string | ReactNode;
  description?: string | ReactNode;
  open?: boolean;
  isStatic?: boolean;
  children: ReactNode;
  fullScreen?: boolean;
  toggleFullScreen?: () => void;
  customGlobalActions?: ReactNode[];
  sx?: SxProps;
  variant?: 'normal' | 'list';
  showCustomGlobalOptionsWhenCollapsed?: boolean;
  onCloseProps?: ReactNode;
}

const AvFormCard: React.FC<AvFormCardProps> = ({
  title,
  description,
  open = true,
  isStatic,
  children,
  fullScreen,
  toggleFullScreen,
  customGlobalActions,
  sx,
  variant = 'normal',
  showCustomGlobalOptionsWhenCollapsed = false,
  onCloseProps = null,
}) => {
  const [expanded, setExpanded] = useState(open || isStatic);

  const handleExpandClick = () => setExpanded(!expanded);
  return (
    <Card
      raised
      sx={{ ...StyledCard(expanded, isStatic, variant), ...(variant === 'list' && { p: 0 }), ...sx }}
      onClick={expanded ? undefined : handleExpandClick}>
      <Box
        className="card-header"
        onClick={isStatic ? undefined : handleExpandClick}
        sx={{
          ...(variant === 'list' && {
            boxSizing: 'content-box',
            height: 24,
            py: 2.5,
            px: 3,
            ...(expanded && { borderBottom: '1px solid', borderBottomColor: theme => theme.palette.colors.neutrals[350] }),
          }),
        }}>
        <Box sx={{ ...flex[onCloseProps ? 'itemsCenter' : 'justifyBetweenCenter'], width: '100%' }}>
          <Typography variant="h5">{title}</Typography> {!expanded && onCloseProps}
          {(expanded || showCustomGlobalOptionsWhenCollapsed) && (
            <Box sx={{ ...flex.itemsCenter, mr: 2, gap: 1 }} onClick={e => e.stopPropagation()}>
              {/* eslint-disable-next-line react/no-array-index-key */}
              {customGlobalActions?.map((action, index) => <React.Fragment key={`action-${index}`}>{action}</React.Fragment>)}
            </Box>
          )}
        </Box>
        {!isStatic && <ExpandCard expand={expanded} sx={{ height: 18 }} fullScreen={fullScreen} toggleFullScreen={toggleFullScreen} />}
      </Box>
      {description && <Box sx={{ color: ({ palette }) => palette.colors.neutrals[700], mt: '6px' }}>{description}</Box>}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Card>
  );
};

export default AvFormCard;
