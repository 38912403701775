import React from 'react';
import { Box } from '@mui/material';
import { flex } from '../components/AvThemeProvider.tsx';
import Widget from '../components/Widgets/Widget';
import { useWidgetData } from '../utils/dashboardDataUtils';
import OverlappingDataBySource from '../components/Widgets/OverlappingDataBySource';

export default function DashboardROIPage() {
  const widgetData = useWidgetData();
  // const { getPathName } = useAvContext();

  return (
    <Box sx={theme => ({ ...flex.row, gap: 2, alignItems: 'start', [theme.breakpoints.down('laptop')]: { flexDirection: 'column' } })}>
      <Widget height={414} {...widgetData.overlappingDataBySource} titleMargin={0}>
        <OverlappingDataBySource />
      </Widget>
    </Box>
  );
}
