import React from 'react';
import { useParams } from 'react-router-dom';
import AuditLogs from '../AuditLogs/AuditLogs';
import { AuditEntityType } from '../AuditLogs/types';
import { useDashboard } from '../CustomDashboards/hooks';

export default function AuditLogsDashboardReport() {
  const { id } = useParams();
  const { data: dashboardData, isLoading } = useDashboard({ id: id! });
  return (
    <AuditLogs
      id={id}
      entityType={AuditEntityType.USER_DASHBOARD_REPORT}
      title={`Audit Logs ${dashboardData?.name}`}
      isQueryLoading={isLoading}
    />
  );
}
