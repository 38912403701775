import React, { ReactNode } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

// IF you want to enable/disable dev tools in different environments
// const devTools = localStorage.getItem('apolloDevTools') || false;
// https://nextsteps.dev/apollo-client-graphQL-and-auth

interface AuthorizedApolloProviderProps {
  children: ReactNode;
}

const AuthorizedApolloProvider: React.FC<AuthorizedApolloProviderProps> = ({ children }) => {
  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.warn(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
          );
        }
        if (networkError) {
          if (['signal is aborted without reason', 'The operation was aborted.'].includes(networkError?.message?.toString()?.trim())) {
            return;
          }
          // localStorage.removeItem('accessToken');
          console.warn('[Network error]:', networkError);
        }
      }),
      new RetryLink({
        delay: {
          initial: 300,
          max: Infinity,
          jitter: true,
        },
        attempts: {
          max: 2,
          retryIf: error => !!error && error.statusCode !== 401,
        },
      }),
      createHttpLink({ uri: `${import.meta.env.VITE_API_URL}/graphql` }),
    ]),
    cache: new InMemoryCache(),
    // connectToDevTools: devTools,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
