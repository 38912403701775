import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Divider, Skeleton, Typography, useTheme } from '@mui/material';
import { editor } from 'monaco-editor';
import AvSidePanel from '../../../components/AvSidePanel';
import { flex } from '../../../components/AvThemeProvider';
import CodeEditor from '../../../components/CodeEditor';
import NewExpressionBuilder from '../../../components/filters/NewExpressionBuilder';
import { getEmptyFilterExpression } from '../../../components/filters/Utils';
import Select from '../../../components/Select';
import TextInput from '../../../components/TextInput';
import { useAvContext } from '../../../context/AvContextProvider';
import { LayoutContext } from '../../../context/LayoutContext';
import { extractKeyValues, formDisabledStyle, noop, setNestedValue } from '../../../utils/Utils';
import { drawerWidth } from '../../Tickets/ticket.types';
import { getRuleFilterOptions } from '../hooks';
import { useGetProjectionFieldCompletions } from './hooks';
import RulePreview from './RulePreview';
import { DEFAULT_RULE_NAME, GroupingRule, GroupingRuleSetTypes } from './types';
import { emptyRule, isValidUUID } from './utils';
import { ReactComponent as Preview } from '../../../assets/Table.svg';

interface Props {
  rule?: GroupingRule;
  onRuleChange: (value) => void;
  projectionName: string;
  isOpen: boolean;
  onClose: () => void;
  disabled: boolean;
}

export const RuleTitleFormatter = ({
  projectionName,
  onChange,
  value,
  height = 40,
}: {
  projectionName: string;
  onChange: (value) => void;
  value: string | undefined;
  height?: number;
}) => {
  const { aggProjs } = useAvContext().accountEntities;
  const onEditorMount = useGetProjectionFieldCompletions({ projectionName });
  const monacoProps: Partial<editor.IStandaloneEditorConstructionOptions> = {
    lineNumbers: 'off',
    lineDecorationsWidth: 'off',
    overviewRulerLanes: 0,
    hideCursorInOverviewRuler: true,
    scrollbar: {
      vertical: 'hidden',
    },
    overviewRulerBorder: false,
    renderLineHighlight: 'none',
    lineNumbersMinChars: 0,
    glyphMargin: false,
    folding: false,
  };
  return (
    <CodeEditor
      label={`${aggProjs[projectionName].projDisplayName} Title Format - {{Field}} Free Text {{Field}}`}
      height={height}
      language="python"
      style={{ p: 1 }}
      onChange={onChange}
      value={value || ''}
      onMount={onEditorMount}
      options={monacoProps}
    />
  );
};

function EditRule({ rule, onRuleChange, projectionName = GroupingRuleSetTypes.Ticket, isOpen, onClose, disabled = false }: Props) {
  const theme = useTheme();
  const {
    layoutWidths: { sideBar: sidebarWidth = drawerWidth },
  } = useContext(LayoutContext);
  const [newRule, setNewRule] = useState<GroupingRule>(emptyRule());
  const [maximized, setMaximized] = useState(false);
  const onChange = field => value => setNewRule({ ...newRule, [field]: value });

  const [condition, setCondition] = useState(newRule?.condition || getEmptyFilterExpression());
  const { aggProjs } = useAvContext().accountEntities;
  const filterOptions = getRuleFilterOptions(aggProjs, projectionName, useAvContext().featureFlags);

  useEffect(() => onChange('condition')(condition), [condition]);

  useEffect(() => {
    if (rule) {
      setNewRule(rule);
    }
  }, [rule]);

  const hasOptions = filterOptions.length > 0;
  const isRuleValid =
    newRule?.name && !!newRule.grouping?.fieldGrouping?.fields?.length && extractKeyValues(newRule.condition, 'fieldName').every(v => !!v);

  return !isOpen ? (
    <AvSidePanel storageKey="groupingRuleInfo" />
  ) : (
    <AvSidePanel
      storageKey="groupingRuleInfo"
      isOpen={isOpen}
      onClose={onClose}
      maxWidthOffset={sidebarWidth}
      maximizedControlled={maximized}
      headComponent={
        <Box sx={{ ...flex.col, gap: 3 }}>
          <Typography variant="h5">{disabled ? 'View' : rule?.isNew && isValidUUID(rule?.id) ? 'Create' : 'Edit'} Rule</Typography>
          <Box sx={{ ...flex.itemsCenter, gap: 5, mb: 1 }}>
            <Typography
              variant="h7"
              sx={{
                mr: 1,
                ':after': {
                  content: '" *"',
                  color: theme.palette.error.main,
                },
              }}>
              Name
            </Typography>
            <TextInput
              size="small"
              disabled={newRule.name === DEFAULT_RULE_NAME || disabled}
              isRequired
              label=""
              helperText="New Rule Name"
              value={newRule.name}
              onChange={onChange('name')}
              autoFocus
            />
          </Box>
        </Box>
      }
      footerComponent={
        <Box sx={{ ...flex.justifyEnd, gap: 1 }}>
          <Button size="small" onClick={onClose}>
            Cancel
          </Button>
          {!rule?.defaultFallbackRule && (
            /* @ts-ignore */
            <Button size="small" variant="outlined" onClick={() => setMaximized(prev => !prev)} active={!!maximized}>
              <Preview />
              Preview
            </Button>
          )}
          <Button
            size="small"
            variant="contained"
            disabled={!isRuleValid || disabled}
            onClick={() => {
              onRuleChange(newRule);
              onClose();
            }}>
            Save
          </Button>
        </Box>
      }>
      <Box sx={disabled ? formDisabledStyle : {}} aria-disabled={disabled}>
        <Typography variant="h5" sx={{ mt: '20px', mb: 1 }}>
          {newRule.defaultFallbackRule ? 'Fallback Grouping Logic' : 'If'}
        </Typography>
        {newRule.defaultFallbackRule ? (
          <Box sx={{ mb: 2 }}>
            {`This grouping rule will apply to all findings that don't answer the filter conditions of any of the rules in the grouping rule
            set.`}
          </Box>
        ) : hasOptions ? (
          <NewExpressionBuilder fields={filterOptions} isVertical={false} filter={newRule.condition} setFilter={setCondition} />
        ) : (
          <Skeleton height={134} animation="wave" />
        )}
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Typography variant="h5" sx={{ mb: 1 }}>
          Then
        </Typography>
        <Select
          size="small"
          label="Group By"
          isRequired
          options={filterOptions}
          groupByFunc={({ group }) => group}
          isMultiple
          onChange={val => onChange('grouping')(setNestedValue('fieldGrouping.fields', newRule.grouping, val))}
          value={hasOptions ? newRule?.grouping?.fieldGrouping.fields : []}
        />
        <Box sx={{ width: 650, mt: 1, pointerEvents: 'auto' }}>
          {hasOptions ? (
            <RuleTitleFormatter projectionName={projectionName} onChange={onChange('entityTitleFormat')} value={rule?.entityTitleFormat} />
          ) : (
            <TextInput
              value=""
              style={{ maxWidth: 'unset', width: '100%' }}
              disabled
              onChange={noop}
              label={`${aggProjs[projectionName].projDisplayName} Title Format - {{Field}} Free Text {{Field}}`}
            />
          )}
        </Box>
      </Box>
      {!rule?.defaultFallbackRule && newRule.condition && maximized && (
        <RulePreview
          filter={newRule.condition}
          projectionName={aggProjs[projectionName].aggregates.name}
          onClose={() => setMaximized(false)}
        />
      )}
    </AvSidePanel>
  );
}

export default EditRule;
