import React from 'react';
import { Route } from 'react-router-dom';
import { APP_PATHS } from '../../types';
import { entityViewConfig } from '../../utils/entityViewConfig';
import CustomDashboardsRoute from '../CustomDashboardsRoute';
import FactorRulesRoute from '../FactorRulesRoute';
import GeneralEntityRoute from '../GeneralEntityRoute';

// TODO: change pages to relevant pages once we implement them
const Risk360 = () => {
  const appPath = APP_PATHS.RISK360;
  return (
    <Route path={appPath}>
      {CustomDashboardsRoute()}
      {GeneralEntityRoute(entityViewConfig.Factor.projectionName)}
      {FactorRulesRoute()}
    </Route>
  );
};
export default Risk360;
