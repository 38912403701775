import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import PropTypes from 'prop-types';
import { useAvContext } from '../context/AvContextProvider.tsx';
import { SEVERITY_LABELS, SeverityIcons } from '../utils/severity.utils.tsx';
import { ellipsis, isNullOrUndefined } from '../utils/Utils';
import AvAvatar from './AvAvatar.tsx';
import { flex } from './AvThemeProvider.tsx';
import AvTooltip from './AvTooltip.tsx';

export function Logo({ type, width, children, transparent, title, iconUrl, style }) {
  const sx = {
    ...flex.center,
    width,
    aspectRatio: '1 / 1',
    backgroundColor: transparent ? 'inherit' : theme => theme.palette.colors.neutrals[300],
    borderRadius: '100%',
    flexShrink: 0,
    '> img': {
      objectFit: 'contain',
      width: width * 0.7,
      height: width * 0.7,
    },
    ...style,
  };

  const onError = ({ target }) => {
    // eslint-disable-next-line no-param-reassign
    target.onerror = null;
    // eslint-disable-next-line no-param-reassign
    target.src = new URL('../assets/logos/GENERIC_UPLOAD.webp', import.meta.url).href;
  };

  const imageUrl = new URL(`/src/assets/logos/${type || 'GENERIC_UPLOAD'}.webp`, import.meta.url).href;
  const iconImageURL = new URL(`/src/assets/${iconUrl}`, import.meta.url).href;
  const logo = <Box sx={sx}>{children || <img src={iconUrl ? iconImageURL : imageUrl} onError={onError} alt={type} />}</Box>;
  return title ? <AvTooltip title={title}>{logo}</AvTooltip> : logo;
}

Logo.propTypes = {
  type: PropTypes.string,
  width: PropTypes.number,
  children: PropTypes.node,
  transparent: PropTypes.bool,
  title: PropTypes.string,
  iconUrl: PropTypes.string,
  style: PropTypes.shape(),
};
Logo.defaultProps = {
  type: undefined,
  width: 24,
  children: undefined,
  transparent: false,
  title: undefined,
  iconUrl: undefined,
  style: {},
};

export const logoStyle = (size, neutralColor = false) => ({
  width: size,
  height: size,
  color: theme => (neutralColor ? theme.palette.colors.neutrals[500] : theme.palette.colors.primary[500]),
  backgroundColor: theme => theme.palette.colors.neutrals[300],
  borderRadius: '50%',
});

export function ItemWithLogo({ variant, type, logoWidth, textStyle, hideText, tooltip, isAvatar }) {
  const { typeNameMap } = useAvContext();
  const logoProps = isAvatar
    ? {}
    : variant === 'destinationsMap'
      ? typeNameMap.destinationsObj[variant][type]
      : variant === 'authTypesMap'
        ? typeNameMap.authenticationSourcesObj[variant][type]
        : typeNameMap.sourcesObj[variant][type];
  const displayName = logoProps?.name || type || 'Unknown';
  const content = (
    <Box sx={{ ...flex.row, gap: 1, lineHeight: `${logoWidth}px`, ...ellipsis, flexShrink: isAvatar ? 0 : undefined }}>
      {isAvatar ? (
        <Logo {...logoProps} width={logoWidth} transparent>
          <AvAvatar displayName={displayName} />
        </Logo>
      ) : (
        <Logo {...logoProps} width={logoWidth} />
      )}
      {!hideText && (
        <AvTooltip>
          <Box component="span" sx={textStyle}>
            {displayName}
          </Box>
        </AvTooltip>
      )}
    </Box>
  );
  return tooltip && hideText ? <AvTooltip title={displayName}>{content}</AvTooltip> : content;
}

ItemWithLogo.propTypes = {
  variant: PropTypes.oneOf(['destinationsMap', 'sourcesMap', 'sourcesMapByName', 'authTypesMap']).isRequired,
  type: PropTypes.string,
  logoWidth: PropTypes.number,
  textStyle: PropTypes.shape(),
  hideText: PropTypes.bool,
  tooltip: PropTypes.bool,
  isAvatar: PropTypes.bool,
};
ItemWithLogo.defaultProps = {
  type: undefined,
  logoWidth: 24,
  textStyle: {},
  hideText: false,
  tooltip: true,
  isAvatar: false,
};

const paddingX = 4;
const SeverityWithIcon = styled('div')(({ theme, color, variant, width, hoverTransition }) => ({
  ...flex.itemsCenter,
  ...(variant === 'contained'
    ? {
        gap: '4px',
        padding: `3px ${paddingX}px`,
        backgroundColor: `${theme.palette[color].main}14`,
        fontWeight: 600,
        borderRadius: '8px',
        fontSize: 12,
        '& > div': {
          display: 'flex',
          '& > svg': {
            width: width * 0.7,
            height: width * 0.7,
          },
        },
        ...(hoverTransition
          ? {
              width: width * 0.7 + paddingX * 2,
              overflow: 'hidden',
              transition: theme.transitions.create(['width'], { duration: theme.transitions.duration.short }),
              '&:hover': { width: '100%' },
              '> *': {
                flexShrink: 0,
                transition: theme.transitions.create(['opacity'], { duration: theme.transitions.duration.short }),
              },
              '&:not(:hover) > div ~ *': { opacity: 0 },
            }
          : {}),
      }
    : {
        gap: '8px',
        '& > div': {
          ...flex.center,
          width,
          height: width,
          borderRadius: width * 0.2,
          color: theme?.palette[color].main,
          backgroundColor: `${theme.palette[color].main}14`,
          '& > svg': {
            width: width * 0.7,
            height: width * 0.7,
          },
        },
      }),
}));
export function SeverityItem({ value, variant, width, showText, numericValue, hoverTransition }) {
  const severityIcon = SeverityIcons[value?.toUpperCase?.()];
  if (!severityIcon) {
    return value;
  }
  return (
    <SeverityWithIcon color={severityIcon.color} variant={variant} width={width} hoverTransition={hoverTransition}>
      <div style={{ flexShrink: 0 }}>{severityIcon.icon}</div>
      {!isNullOrUndefined(numericValue) && <span style={{ whiteSpace: 'nowrap', fontWeight: 600 }}>{numericValue?.toFixed(1)}</span>}
      {showText && <span style={ellipsis}>{SEVERITY_LABELS[value]}</span>}
    </SeverityWithIcon>
  );
}
SeverityItem.propTypes = {
  value: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['contained', 'minimal']),
  width: PropTypes.number,
  showText: PropTypes.bool,
  hoverTransition: PropTypes.bool,
  numericValue: PropTypes.number,
};
SeverityItem.defaultProps = {
  variant: 'minimal',
  width: 24,
  showText: true,
  hoverTransition: false,
  numericValue: undefined,
};

export function StatusWithLogo({ statusText, icon, width, hideTextOnSmallScreens }) {
  const sx = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    svg: {
      width,
      height: width,
      color: theme => theme.palette.colors.neutrals[600],
    },
    width,
    height: width,
    borderRadius: 100,
    flexShrink: 0,
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('laptop'));
  const hideText = isSmallScreen && hideTextOnSmallScreens;
  return (
    <AvTooltip title={hideText ? statusText : ''}>
      <div style={{ display: 'flex', gap: '8px', lineHeight: `${width}px` }}>
        <Box sx={sx}>{icon}</Box>
        {!hideText && <span>{statusText}</span>}
      </div>
    </AvTooltip>
  );
}

StatusWithLogo.propTypes = {
  statusText: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  width: PropTypes.number,
  hideTextOnSmallScreens: PropTypes.bool,
};
StatusWithLogo.defaultProps = {
  width: 22,
  hideTextOnSmallScreens: true,
};
