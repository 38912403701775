import React, { useMemo } from 'react';

interface AvHighlightProps {
  text: string;
  highlights?: string[];
}

const AvHighlight: React.FC<AvHighlightProps> = ({ text = '', highlights = [] }) => {
  const val = useMemo(() => {
    if (!highlights.length) {
      return text;
    }
    try {
      const matchRegex = RegExp(highlights.join('|'), 'ig');
      const matches = [...text.matchAll(matchRegex)];

      return text.split(matchRegex).map((nonBoldText, index, arr) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {nonBoldText}
          {index + 1 !== arr.length && <mark>{matches[index]}</mark>}
        </React.Fragment>
      ));
    } catch {
      return text;
    }
  }, [highlights, text]);
  return val;
};

export default AvHighlight;
