import React from 'react';
import { Box, Button } from '@mui/material';
import { iconSize, noop } from '../utils/Utils';
import { flex } from './AvThemeProvider';
import { ReactComponent as Plus } from '../assets/Plus.svg';

interface AvAddOperandButtonProps {
  operator: string;
  onClick?: () => void;
  isAdd?: boolean;
  disabled?: boolean;
  hideText?: boolean;
  style?: any;
  size?: 'xSmall' | 'small';
}
const AvAddOperandButton: React.FC<AvAddOperandButtonProps> = ({
  operator,
  onClick = noop,
  isAdd = false,
  disabled = false,
  style,
  hideText = false,
  size = 'small',
}) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    sx={theme => ({
      ...styles(theme, operator),
      borderRadius: size === 'xSmall' ? '8px' : '10px',
      ...(size !== 'xSmall' && { p: '2px 8px' }),
      ...style,
      ...(hideText && isAdd && { p: '3px' }),
    })}>
    {isAdd ? <Plus style={{ ...iconSize(16) }} /> : undefined}
    {!hideText ? <Box sx={{ fontSize: size === 'xSmall' ? 11 : 12 }}>{operator.toUpperCase()}</Box> : undefined}
  </Button>
);

export default AvAddOperandButton;

const styles = (theme, operator) => {
  const color = getOperatorColor(theme, operator);

  return {
    ...flex.center,
    alignSelf: 'center',
    gap: '4px',
    lineHeight: '18px',
    cursor: 'pointer',
    px: 1,
    background: theme.palette.white.main,
    border: `1px solid ${theme.palette.colors.neutrals[400]}`,
    fontWeight: 600,
    color,
    transition: theme.transitions.create(['background']),
    ':hover': {
      background: theme.palette.colors.neutrals[200],
      color,
    },
    ':active': {
      border: `1px solid ${theme.palette.colors.neutrals[500]}`,
    },
  };
};

export const getOperatorColor = (theme, operator) => {
  const colors = {
    and: theme.palette.colors.primary[500],
    or: theme.palette.colors.complementary[600],
  };

  return colors[operator];
};
