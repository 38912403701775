import React from 'react';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import AvComposedWidget from './AvComposedWidget';

export function Label({ x, y, payload, active }) {
  const theme = useTheme();
  const isActive = active === payload.index;
  const textStyle = isActive ? { fontWeight: '600', fill: theme.palette.colors.neutrals[800] } : {};
  return (
    <text className={isActive ? 'custom-chart-text' : ''} x={x} y={y + 10} textAnchor="middle" {...textStyle}>
      {payload?.value}
    </text>
  );
}
Label.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape(),
  active: PropTypes.number,
};

Label.defaultProps = {
  y: undefined,
  x: undefined,
  payload: undefined,
  active: undefined,
};

function ActiveTicketsProgress({ data }) {
  const themeHook = useTheme();
  const colors = themeHook.palette.chartColors;

  const buckets = Array.from(new Set(data.map(({ bucket }) => bucket)));
  const statuses = Array.from(new Set(data.map(({ status }) => status))).filter(Boolean);
  const series = statuses.reduce(
    (acc, status, index) => [...acc, { name: status, dataKey: status, color: colors[index], type: 'bar', stackId: 't' }],
    []
  );

  const chartData = buckets.reduce((acc, bucket) => {
    const statusesForBucket = data
      .filter(row => row.bucket === bucket)
      .reduce((acc, row) => {
        acc[row.status] = row.count;
        return acc;
      }, {});

    return [...acc, { name: bucket, ...statusesForBucket }];
  }, []);

  return <AvComposedWidget data={chartData} series={series} isDate={false} xAxisKey="name" />;
}
export default ActiveTicketsProgress;

ActiveTicketsProgress.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};
ActiveTicketsProgress.defaultProps = { data: [] };
