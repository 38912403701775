import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { isValid } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../../context/AvContextProvider';
import useHandleError from '../../hooks/useHandleError';
import useQuerySql from '../../hooks/useQuerySql';
import { FeatureFlags } from '../../types';
import stringToFilter from '../../utils/stringToFIlter';
import { flattenObject, generateOption, getNetworkErrorCode, isNullOrUndefined } from '../../utils/Utils';
import { LogQuery, LogsQuery, LogsQueryCategoryType, QueryType, Tab } from './types';
import { ANY_VALUE, getAliasString, getInterval, getIsValidQuery, getPieSlicerFilter, queriesList, QueryObj } from './Utils';

const dataExplorationOrigin = 'DATA_EXPLORATION';

export const useAccountFacets = ({ isValidTime, onSuccess }) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['account_facets'],
    queryFn: () =>
      api(QUERY_ACCOUNT_FACETS, { options: { req: {} } }).then(({ data, errors }) =>
        onSuccess(errors ? [] : data.logSearchAccountFacetList.fields)
      ),
    placeholderData: keepPreviousData,
    enabled: isValidTime,
  });
};

export const useVeryNewLogs = ({
  queryType,
  query,
  date,
  isValidTime,
  lastSearch,
  pieSlicer,
}: {
  queryType: QueryType;
  query: LogQuery;
  date: Date[];
  isValidTime: boolean;
  lastSearch?: Date;
  pieSlicer: any[];
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const isSql = queryType === QueryType.Advanced && query !== undefined;
  const sql = isValidTime ? (isSql ? query : `SELECT DOCUMENT, source, TIME as timestamp FROM logs ORDER BY timestamp DESC LIMIT 100`) : '';
  const pieSlicerFilter = getPieSlicerFilter(pieSlicer);
  return useQuerySql({
    key: `${date}${query}${lastSearch}${pieSlicer.map(({ fieldName, value }) => `${fieldName}${value}`)}`,
    sql,
    origin: dataExplorationOrigin,
    options: { enabled: isValidTime && getIsValidQuery({ query, queryType }) && (isSql ? !!query : true) && !!lastSearch },
    debounceQueries: false,
    additionalFilter: {
      and: {
        operands: [
          {
            expression: {
              fieldName: 'TIME',
              dateCondition: {
                between: { values: date },
              },
            },
          },
          ...(pieSlicerFilter.length ? [{ or: { operands: pieSlicerFilter } }] : []),
          ...(!isSql && query !== undefined ? [stringToFilter(query)] : []),
        ],
      },
    },
    onSuccess: data =>
      isSql
        ? data.map(({ DOCUMENT, ALL, id, ...v }, index) => ({
            avalorRowId: `${index}${id || ''}`,
            data: flattenObject({ obj: { ...DOCUMENT, ...ALL, ...v } }),
            raw: { ...(DOCUMENT ? { DOCUMENT } : {}), ...v },
          }))
        : data.map(({ DOCUMENT, timestamp, source }, index) => ({
            avalorRowId: index,
            data: flattenObject({ obj: { source, ...DOCUMENT, timestamp } }),
            raw: { source, ...DOCUMENT, timestamp },
          })),
    onError: error =>
      enqueueSnackbar((error?.[0]?.extensions || error).message, {
        variant: 'error',
      }),
  });
};
export const useVeryNewLogsTotals = ({
  queryType,
  query,
  date,
  isValidTime,
  lastSearch,
  pieSlicer,
}: {
  queryType: QueryType;
  query: LogQuery;
  date: Date[];
  isValidTime: boolean;
  lastSearch?: Date;
  pieSlicer: any[];
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const isSql = queryType === QueryType.Advanced && query !== undefined;
  const sql = !isValidTime || isSql ? '' : `SELECT count(*) as totals FROM logs`;
  const pieSlicerFilter = getPieSlicerFilter(pieSlicer);
  return useQuerySql({
    key: `${date}${query}${lastSearch}${pieSlicer.map(({ fieldName, value }) => `${fieldName}${value}`)}`,
    sql,
    origin: dataExplorationOrigin,
    options: { enabled: isValidTime && getIsValidQuery({ query, queryType }) && !isSql && !!lastSearch },
    debounceQueries: false,
    additionalFilter: {
      and: {
        operands: [
          {
            expression: {
              fieldName: 'TIME',
              dateCondition: {
                between: { values: date },
              },
            },
          },
          ...(pieSlicerFilter.length ? [{ or: { operands: pieSlicerFilter } }] : []),
          ...(!isSql && query !== undefined ? [stringToFilter(query)] : []),
        ],
      },
    },
    onSuccess: data => data[0].totals,
    onError: error =>
      enqueueSnackbar((error?.[0]?.extensions || error).message, {
        variant: 'error',
      }),
  });
};

export const useLogsBarQuery = ({
  date,
  queryType,
  query,
  isValidTime,
  enabled = true,
  lastSearch,
}: {
  queryType: QueryType;
  query: LogQuery;
  date: Date[];
  isValidTime: boolean;
  enabled?: boolean;
  lastSearch?: Date;
}) => {
  const isSql = queryType === QueryType.Advanced && query !== undefined;

  const sql =
    !isSql && isValidTime
      ? `SELECT TIME_BUCKET('${date[0].toISOString()}', '${date[1].toISOString()}', '${
          getInterval(100, date).display
        }') as date, COUNT(*) as count FROM logs GROUP BY date ORDER BY date asc`
      : '';

  return useQuerySql({
    key: `${query}${lastSearch}`,
    sql,
    origin: dataExplorationOrigin,
    additionalFilter: {
      and: {
        operands: [
          {
            expression: {
              fieldName: 'TIME',
              dateCondition: {
                between: { values: date },
              },
            },
          },
          ...(!isSql && query !== undefined ? [stringToFilter(query)] : []),
        ],
      },
    },
    options: { enabled: !isSql && isValidTime && getIsValidQuery({ query, queryType }) && enabled && !!lastSearch },
    debounceQueries: false,
    onSuccess: data => data.map(({ date, count }) => ({ date: new Date(`${date}.000Z`).toLocaleString(), count })),
  });
};

export const useLogsPieQuery = ({
  query,
  queryType,
  date,
  groupByField,
  metric,
  top,
  isValidTime,
  enabled = true,
  lastSearch,
}: {
  query: LogQuery;
  queryType: QueryType;
  date: Date[];
  groupByField: string | null;
  metric: string | null;
  top: number;
  isValidTime: boolean;
  enabled?: boolean;
  lastSearch?: Date;
}) => {
  const isSql = queryType === QueryType.Advanced && query !== undefined;
  const alias = getAliasString(groupByField || '');
  const sql =
    !isSql && isValidTime
      ? `SELECT  ${groupByField ? `coalesce(${groupByField}, 'null') AS ${alias},` : ''} ${
          metric ? `COUNT_DISTINCT(${metric})` : 'COUNT(*)'
        } as count FROM logs
            ${groupByField ? `GROUP BY ${alias}` : ''} ORDER BY count DESC LIMIT ${top}`
      : '';

  return useQuerySql({
    key: `${date}${query}${lastSearch}`,
    sql,
    origin: dataExplorationOrigin,
    additionalFilter: {
      and: {
        operands: [
          {
            expression: {
              fieldName: 'TIME',
              dateCondition: {
                between: { values: date },
              },
            },
          },
          ...(!isSql && query !== undefined ? [stringToFilter(query)] : []),
        ],
      },
    },
    options: { placeholderData: keepPreviousData, enabled: enabled && isValidTime && !!lastSearch },
    debounceQueries: false,
    onSuccess: data => {
      if (!groupByField) {
        return data;
      }
      const formattedField = getAliasString(groupByField || '');
      return data.reduce((acc, curr) => {
        const { [formattedField]: value } = curr;
        return [
          ...acc,
          {
            ...curr,
            [formattedField]: typeof value === 'object' ? JSON.stringify(value) : value.toString(),
          },
        ];
      }, []);
    },
  });
};

export const useGetSourcesNameList = ({ enabled, onSuccess = d => d }) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['sourcesListFacets'],
    queryFn: () => api(GET_SOURCES_NAME_LIST).then(({ data }) => onSuccess(data.findDataSourceNamesByAccountId)),
    enabled,
  });
};

export const useGetSourcesList = ({ enabled, onSuccess = d => d }) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['GET_SOURCES_LIST'],
    queryFn: () => api(GET_SOURCES_LIST).then(({ data }) => onSuccess(data.findDataSourceInstancesByAccountId)),
    enabled,
  });
};

export const useGetValuesOptions = ({
  fieldName,
  enabled,
  autocompleteFilter,
}: {
  fieldName?: string | null;
  enabled: boolean;
  autocompleteFilter?: Date[];
}) => {
  const isValidTime = useMemo(() => autocompleteFilter?.every(date => isValid(date)), [autocompleteFilter]);
  const sql = `select distinct ${fieldName} from logs limit 100`;
  const onSuccessSourcesList = data => data.map(v => generateOption(v));
  const sourcesList = useGetSourcesNameList({ onSuccess: onSuccessSourcesList, enabled: fieldName === 'source' });
  const valuesOptions = useQuerySql({
    sql,
    key: `${fieldName}${autocompleteFilter}`,
    origin: dataExplorationOrigin,
    ...(autocompleteFilter
      ? {
          additionalFilter: {
            and: { operands: [{ expression: { fieldName: 'TIME', dateCondition: { between: { values: autocompleteFilter } } } }] },
          },
        }
      : {}),
    options: {
      enabled: !!isValidTime && !!fieldName && fieldName !== ANY_VALUE && fieldName !== 'source' && enabled,
      gcTime: 0,
    },
    onSuccess: data =>
      data.reduce(
        (acc, { [fieldName!]: value }) =>
          (Array.isArray(value) ? value.length : !isNullOrUndefined(value))
            ? [...acc, generateOption(typeof value === 'object' ? JSON.stringify(value) : value.toString())]
            : acc,
        []
      ),
  });

  if (fieldName === 'source') {
    return sourcesList;
  }
  return valuesOptions;
};

// export const useGetFacetOptions = (date: Date[]) => {
//   const sql = `select distinct EXPLODE(OBJECT_KEYS(DOCUMENT)) as facet from logs limit 100`;
//   const isValidTime = useMemo(() => date.every(date => isValid(date)), [date]);
//
//   return useQuerySql({
//     sql,
//     key: `${sql}${date}`,
//     origin: dataExplorationOrigin,
//     additionalFilter: { and: { operands: [{ expression: { fieldName: 'TIME', dateCondition: { between: { values: date } } } }] } },
//     options: { enabled: isValidTime },
//     onSuccess: data =>
//       uniqBy(
//         [generateOption('source'), ...data.reduce((acc, { facet }) => (facet ? [...acc, generateOption(facet)] : acc), [])],
//         ({ value }) => value
//       ),
//   });
// };

export const useRecentQueries = () => {
  const { accountId } = useAvContext();

  const recentQueriesString = localStorage.getItem('recentLogsQueries');
  const recentQueries = recentQueriesString ? JSON.parse(recentQueriesString) : { [accountId]: [] };
  const recentQueriesPerAccount = Array.isArray(recentQueries) ? { [accountId]: recentQueries } : recentQueries;
  const recentQueriesByAccount = recentQueriesPerAccount[accountId] || [];

  const addRecentQuery = query => {
    if (query) {
      const newRecentQueriesByAccount = [...new Set([query, ...recentQueriesByAccount])].slice(0, 5);
      localStorage.setItem('recentLogsQueries', JSON.stringify({ ...recentQueriesPerAccount, [accountId]: newRecentQueriesByAccount }));
    }
  };
  return { recentQueries: recentQueriesByAccount, addRecentQuery };
};

export const useLogsQueries = () => {
  const { api } = useAvContext();
  return useQuery({ queryKey: ['logsQueries'], queryFn: () => api(GET_LOGS_QUERY, { onSuccess: ({ data }) => data.findLogsQueries }) });
};

export const useLogsQueriesCategory = () => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['logsQueriesCategory'],
    queryFn: () => api(GET_LOGS_QUERY_CATEGORY, { onSuccess: ({ data }) => data.findLogsQueriesCategory }),
  });
};

export const useUpdateLogsQuery = () => {
  const { api } = useAvContext();
  const { refetch } = useLogsQueries();
  const { enqueueSnackbar } = useSnackbar();

  const { refetch: refetchCategory } = useLogsQueriesCategory();
  return useMutation({
    mutationFn: ({
      isCreate,
      activeDraftTab,
      onSuccess = d => d,
    }: {
      isCreate: boolean;
      activeDraftTab: Tab;
      onSuccess?: (id) => void;
    }) => {
      const { tabId, title, query, queryType } = activeDraftTab;
      const logsQuery: LogsQuery = {
        ...(isCreate ? {} : { id: tabId }),
        name: title,
        query: query || '',
        queryType,
        ...(activeDraftTab.categoryName
          ? { logsQueryCategory: { name: activeDraftTab.categoryName, id: activeDraftTab.categoryId || undefined } }
          : {}),
      };
      return api(isCreate ? CREATE_LOGS_QUERY : UPDATE_LOGS_QUERY, {
        options: { logsQuery },
        onSuccess: ({ data }) => {
          onSuccess(data);
          refetch();
          refetchCategory();
          enqueueSnackbar(`Successfully ${isCreate ? 'Created' : 'Updated'} Query`, { variant: 'success' });
        },
      });
    },
  });
};

export const useUpdateLogsQueryCategory = () => {
  const { api, featureFlags } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const { refetch: refetchCategory } = useLogsQueriesCategory();
  const handleError = useHandleError(featureFlags[FeatureFlags.ShowServerErrorInToast]);

  return useMutation({
    mutationFn: ({
      category,
      onSuccess = d => d,
      isCreate,
    }: {
      onSuccess?: (id) => void;
      category: LogsQueryCategoryType;
      isCreate: boolean;
    }) =>
      api(isCreate ? CREATE_LOGS_QUERY_CATEGORY : UPDATE_LOGS_QUERY_CATEGORY, {
        options: { logsQueryCategoryDto: category },
        onSuccess: ({ data }) => {
          onSuccess(data);
          refetchCategory();
          enqueueSnackbar(`Successfully Created Query Category`, { variant: 'success' });
        },
        muteErrors: true,
      }).catch(e => {
        if (getNetworkErrorCode(e) === 400) {
          console.warn(e);
          enqueueSnackbar(e.message, { variant: 'error' });
        } else {
          handleError(e);
        }
      }),
  });
};

export const useDeleteLogsQuery = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (ids: string[]) =>
      api(DELETE_LOGS_QUERY, {
        options: { ids },
        onSuccess: () => {
          enqueueSnackbar(`Successfully Deleted Query`, { variant: 'success' });
          onSuccess();
        },
      }),
  });
};
export const useDeleteLogsQueryCategory = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ id, shouldDeleteRelatedQueries }: { id: string; shouldDeleteRelatedQueries: string }) =>
      api(DELETE_LOGS_QUERY_CATEGORY, {
        options: { id, shouldDeleteRelatedQueries },
        onSuccess: () => {
          enqueueSnackbar(`Successfully Deleted Category`, { variant: 'success' });
          onSuccess();
        },
      }),
  });
};

export const useBulkUpdateLogsQueryCategory = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ ids, logsQueryCategoryId }: { ids: string[]; logsQueryCategoryId: string }) =>
      api(BULK_UPDATE_LOGS_QUERY_CATEGORY, {
        options: { ids, logsQueryCategoryId },
        onSuccess: () => {
          enqueueSnackbar(`Successfully Update Logs Queries`, { variant: 'success' });
          onSuccess();
        },
      }),
  });
};

export const topXQueries = 5;
export const useGetTop5SuggestionQueries = () => {
  const { data: sourcesObjList } = useGetSourcesList({ enabled: true });
  const sources = sourcesObjList?.map(source => source.name) || [];

  const top5SuggestionQueries = useMemo(() => {
    const suggestionQueries = queriesList.reduce((acc, queryObj) => {
      if (sources.includes(queryObj.source) && acc.length < topXQueries) {
        return [...acc, queryObj];
      }
      return acc;
    }, [] as QueryObj[]);
    if (suggestionQueries.length === topXQueries) {
      return suggestionQueries;
    }
    const restSuggestionQueries = queriesList.reduce((acc, queryObj) => {
      if (!sources.includes(queryObj.source) && acc.length < topXQueries - suggestionQueries.length) {
        return [...acc, queryObj];
      }
      return acc;
    }, [] as QueryObj[]);
    return [...suggestionQueries, ...restSuggestionQueries];
  }, [sources]);
  return top5SuggestionQueries.map(({ query }) => query);
};

const QUERY_ACCOUNT_FACETS = gql`
  query ($req: Json!) {
    logSearchAccountFacetList(value: $req) {
      fields
    }
  }
`;

const GET_LOGS_QUERY_CATEGORY = gql`
  query findLogsQueriesCategory {
    findLogsQueriesCategory {
      id
      name
    }
  }
`;

const GET_LOGS_QUERY = gql`
  query findLogsQueries {
    findLogsQueries {
      id
      name
      description
      query
      queryType
      updatedByUserId
      updatedAt
      logsQueryCategory {
        id
        name
      }
    }
  }
`;

const CREATE_LOGS_QUERY_CATEGORY = gql`
  mutation createLogsQueryCategory($logsQueryCategoryDto: LogsQueryCategoryInput!) {
    createLogsQueryCategory(logsQueryCategoryDto: $logsQueryCategoryDto) {
      id
    }
  }
`;

const BULK_UPDATE_LOGS_QUERY_CATEGORY = gql`
  mutation bulkUpdateLogsQueryCategory($ids: [String]!, $logsQueryCategoryId: String) {
    bulkUpdateLogsQueryCategory(ids: $ids, logsQueryCategoryId: $logsQueryCategoryId)
  }
`;

const UPDATE_LOGS_QUERY_CATEGORY = gql`
  mutation updateLogsQueryCategory($logsQueryCategoryDto: LogsQueryCategoryInput!) {
    updateLogsQueryCategory(logsQueryCategoryDto: $logsQueryCategoryDto) {
      id
    }
  }
`;

const DELETE_LOGS_QUERY_CATEGORY = gql`
  mutation deleteLogsQueryCategory($id: String!, $shouldDeleteRelatedQueries: Boolean) {
    deleteLogsQueryCategory(id: $id, shouldDeleteRelatedQueries: $shouldDeleteRelatedQueries)
  }
`;

const DELETE_LOGS_QUERY = gql`
  mutation deleteLogsQuery($ids: [String]!) {
    deleteLogsQuery(ids: $ids)
  }
`;

const CREATE_LOGS_QUERY = gql`
  mutation createLogsQuery($logsQuery: LogsQueryInput!) {
    createLogsQuery(logsQuery: $logsQuery) {
      id
    }
  }
`;

const UPDATE_LOGS_QUERY = gql`
  mutation updateLogsQuery($logsQuery: LogsQueryInput!) {
    updateLogsQuery(logsQuery: $logsQuery) {
      id
    }
  }
`;

export const GET_SOURCES_NAME_LIST = gql`
  query {
    findDataSourceNamesByAccountId
  }
`;

const GET_SOURCES_LIST = gql`
  query findDataSourceInstancesByAccountId {
    findDataSourceInstancesByAccountId {
      id
      name
      mappedSourceName
    }
  }
`;
