import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { FeatureFlags, PAGE_PATHS, Permission } from '../types';
import { FactorKind } from '../types/executionRules.types';
import avLazy from '../utils/AvLazy';

const FactorRules = avLazy(() => import('../views/FactorRules/FactorRules'));
const EditFactorRules = avLazy(() => import('../views/FactorRules/EditFactorRules'));
const NewEditFactorRules = avLazy(() => import('../views/FactorRules/NewEditFactorRules'));
const BuiltInFactorRulesRoute = () => {
  const {
    userPermissions: { hasAllowedPermission },
    featureFlags,
  } = useAvContext();
  const newEditScreen = featureFlags[FeatureFlags.NewEditFactorRulesScreen];
  const path = PAGE_PATHS.GLOBAL_FACTOR_RULES;
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <FactorRules kind={FactorKind.BUILT_IN} />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.CREATE })}>
            {newEditScreen ? <NewEditFactorRules kind={FactorKind.BUILT_IN} /> : <EditFactorRules kind={FactorKind.BUILT_IN} />}
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.UPDATE })}>
            {newEditScreen ? <NewEditFactorRules kind={FactorKind.BUILT_IN} /> : <EditFactorRules kind={FactorKind.BUILT_IN} />}
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default BuiltInFactorRulesRoute;
