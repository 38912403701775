import { MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';

interface Props {
  hasPermissions?: boolean;
  onClick: () => void;
  pathName: string;
  title: string;
}

const AvMenuItem: React.FC<Props> = ({ pathName, onClick, hasPermissions, title }) =>
  hasPermissions && (
    <MenuItem onClick={onClick} component={Link} to={pathName}>
      {title}
    </MenuItem>
  );
export default AvMenuItem;
