import React from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import { useAvContext } from '../../context/AvContextProvider';
import { Expression, Filter, OperatorType, StringConditionType } from '../../types/filter.types';
import { ANY_VALUE } from '../../views/Logs/Utils';
import { fieldTypeIconsMap, fieldTypeLabelMap, getFieldKey } from '../../views/ModelManagement/hooks';
import { FieldType, FieldTypeEnum, typeLabelMap } from '../../views/Sources/Mapping/mapping.types';
import AvAddOperandButton from '../AvAddOperandButton';
import { flex } from '../AvThemeProvider';
import OpenSelect from '../OpenSelect';
import Select from '../Select';
import DateTypeBox from './DateTypeBox';
import FilterTypeBox from './FilterTypeBox';
import {
  boxWidth,
  ConditionType,
  defaultValue,
  filterOperatorOptions,
  getCondition,
  getEmptyExpression,
  getFilterOptionsWithLegacy,
  getOperator,
  isNullOperator,
  operatorLabels,
  selectFieldWidth,
  selectorWidth,
  supportParserOperatorOptions,
} from './Utils';
import { ReactComponent as Delete } from '../../assets/Delete.svg';

interface FieldFilterBoxProps {
  size: any;
  isVertical: boolean;
  rootOperator: 'or' | 'and' | 'expression';
  filter: Filter;
  setFilter: any;
  fields: any;
  isLoadingFields?: boolean;
  level: number;
  index: number;
  onDelete: () => void;
  addLogicalExpression: () => void;
  canAddNewFields?: boolean;
  canSelectFieldType?: boolean;
  supportParser?: boolean;
  autocompleteFilter?: Date[];
  operatorOptions?: typeof filterOperatorOptions;
}

interface DeleteAddFilterProps {
  isVertical: boolean;
  size: 'xSmall' | 'small';
  level: number;
  index: number;
  oppositeOperator: string;
  rootOperator: 'or' | 'and' | 'expression';
  onClick: () => void;
  onDelete: () => void;
}

const DeleteAddFilter: React.FC<DeleteAddFilterProps> = ({
  isVertical,
  size,
  level,
  index,
  oppositeOperator,
  rootOperator,
  onClick,
  onDelete,
}) => (
  <Box
    className={isVertical ? 'option' : undefined}
    sx={{
      ...(isVertical
        ? { ...flex.colCenter, flexDirection: 'column' }
        : { ...flex.justifyBetweenCenter, ml: 1, flexGrow: 1, flexDirection: 'row-reverse' }),
      gap: 1,
      opacity: isVertical ? 0 : 1,
    }}>
    <IconButton onClick={onDelete}>
      <Delete />
    </IconButton>
    {!(level === 0 && index === 0) && (
      <AvAddOperandButton
        isAdd
        hideText={isVertical}
        size={size}
        operator={level === 0 ? oppositeOperator : rootOperator}
        onClick={onClick}
      />
    )}
  </Box>
);

const FieldFilterBox: React.FC<FieldFilterBoxProps> = ({
  rootOperator,
  filter,
  setFilter,
  fields,
  isLoadingFields,
  level,
  index,
  size,
  isVertical,
  onDelete,
  addLogicalExpression,
  canAddNewFields,
  canSelectFieldType = true,
  supportParser = false,
  autocompleteFilter,
  operatorOptions = filterOperatorOptions,
}) => {
  const { palette, transitions } = useTheme();
  const {
    accountEntities: { fieldMap },
  } = useAvContext();
  const getExpressionType = (expression: Expression) => {
    const { fieldName, ...condition } = expression.arrayCondition?.underlying || expression;
    return Object.keys(condition)[0] as ConditionType;
  };
  const typeCondition = (filter.expression && getExpressionType(filter.expression)) || ConditionType.string;
  const fieldName = (filter.expression?.arrayCondition?.underlying || filter.expression)?.fieldName;
  const isRepeated = fieldMap[fieldName as string]?.repeated;
  const typeName = `${Object.keys(ConditionType).find(type => ConditionType[type] === typeCondition)!}${isRepeated ? '[]' : ''}`;
  const iconsMap = fieldTypeIconsMap(palette);
  const options = [FieldType.Text, FieldType.Date, FieldType.Number, FieldType.Boolean].map(type => ({
    descriptorType: FieldTypeEnum[type],
    value: ConditionType[typeLabelMap[FieldTypeEnum[type]]],
  }));

  const commonProps = {
    size,
    isVertical,
    fields,
    filter,
    setFilter,
  };
  const oppositeOperator = rootOperator === OperatorType.OR ? OperatorType.AND : OperatorType.OR;
  const operator = getCondition(filter, typeCondition, supportParser ? StringConditionType.EQUALS : undefined);
  const SetOperator = compoundValue => {
    const [value, resolution = 'ANY'] = compoundValue.split('|');
    const [currValue] = operator.split('|');
    const underlying = {
      fieldName,
      [typeCondition]: {
        [value]: isNullOperator(value)
          ? {}
          : isNullOperator(currValue)
            ? defaultValue[typeCondition]
            : filter.expression?.arrayCondition?.underlying[typeCondition]?.[currValue] ?? filter.expression?.[typeCondition]?.[currValue],
      },
    };
    setFilter({ ...filter, expression: isRepeated ? { arrayCondition: { underlying, resolution } } : underlying });
  };

  const deleteAddFilter = (
    <DeleteAddFilter
      size={size}
      isVertical={isVertical}
      level={level}
      index={index}
      oppositeOperator={oppositeOperator}
      rootOperator={rootOperator}
      onClick={addLogicalExpression}
      onDelete={onDelete}
    />
  );

  const SelectComponent = canAddNewFields ? OpenSelect : Select;
  const labelFunc = ({ descriptorType }) => (
    <>
      {iconsMap[getFieldKey(descriptorType)]} {fieldTypeLabelMap[getFieldKey(descriptorType)]?.label}
    </>
  );
  const typeIcon = options.find(({ value }) => value === typeCondition)?.descriptorType || typeName;
  const startAdornment = () =>
    canAddNewFields && canSelectFieldType ? (
      <Select
        variant="outlined"
        size="xSmall"
        showInput={false}
        value={typeCondition}
        onChange={value =>
          setFilter({
            ...filter,
            expression: filter.expression?.[value]
              ? filter.expression
              : getEmptyExpression(
                  filter.expression?.fieldName,
                  value,
                  supportParser ? StringConditionType.EQUALS : undefined,
                  fieldMap[filter.expression!.fieldName!]?.repeated
                ).expression,
          })
        }
        options={options}
        getLabelFunc={labelFunc}
        selectIcon={iconsMap[typeIcon]}
        style={{ width: 34, pl: '3px', ml: '-12px' }}
      />
    ) : (
      <Box sx={{ ...flex.row, mr: '-8px' }}>{iconsMap[isRepeated ? `[${typeIcon}]` : typeIcon]}</Box>
    );

  return (
    <Box
      sx={{
        ':hover .option': {
          opacity: 1,
          transition: transitions.create(['opacity'], { duration: transitions.duration.standard }),
        },
        ...(isVertical ? { ...flex.row, gap: 1 } : {}),
      }}>
      <Box
        sx={{
          ...(isVertical ? flex.colItemsStart : flex.justifyStartCenter),
          p: isVertical ? '10px' : '12px',
          border: `1px solid ${palette.colors.neutrals[300]}`,
          gap: 1,
          width: isVertical ? boxWidth : 850,
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
        }}>
        <SelectComponent
          style={{ width: isVertical ? selectFieldWidth : 250 }}
          size={size}
          isRequired
          placeholder="Select Field"
          value={fieldName}
          groupByFunc={({ group }) => group}
          onChange={(value, { type = FieldType.Text, repeated }) => {
            const typeCondition = ConditionType[typeLabelMap[type]];
            const newExpression = getEmptyExpression(
              value,
              typeCondition,
              supportParser ? (value === ANY_VALUE ? StringConditionType.CONTAINS : StringConditionType.EQUALS) : undefined,
              fieldMap[value]?.repeated
            ).expression;
            setFilter({
              ...filter,
              expression: filter.expression?.[typeCondition] && !repeated ? { ...filter.expression, fieldName: value } : newExpression,
            });
          }}
          options={fields}
          loading={isLoadingFields}
          skeletonLoading
          optionsUseQueryProps={{ options: fields, isLoading: isLoadingFields }}
          startAdornment={fieldName && !supportParser && startAdornment}
        />
        <Select
          size={size}
          style={{ width: isVertical ? selectorWidth : 150, input: { fontWeight: 600 } }}
          isRequired
          muiProps={{ disablePortal: true }}
          placeholder="Select Operator"
          value={getOperator(operator)}
          onChange={SetOperator}
          disabled={typeCondition === ConditionType.date || !fieldName}
          options={
            supportParser
              ? filter.expression?.fieldName === ANY_VALUE
                ? [
                    { value: StringConditionType.CONTAINS, title: operatorLabels.contains },
                    { value: StringConditionType.NOT_CONTAINS, title: operatorLabels.notContains },
                  ]
                : supportParserOperatorOptions.string
              : getFilterOptionsWithLegacy(operatorOptions[typeName], operator) || []
          }
        />
        {typeCondition === ConditionType.date ? (
          <DateTypeBox {...commonProps} />
        ) : typeName ? (
          <FilterTypeBox
            autocompleteFilter={autocompleteFilter}
            typeCondition={typeCondition as any}
            options={supportParser ? supportParserOperatorOptions.string : operatorOptions[typeName] || []}
            supportParser={supportParser}
            {...commonProps}
          />
        ) : (
          <Box>Not Implemented</Box>
        )}
        {!isVertical && deleteAddFilter}
      </Box>
      {isVertical && deleteAddFilter}
    </Box>
  );
};

export default FieldFilterBox;
