import { Parser } from 'node-sql-parser';
import { Expression, OperatorType, StringConditionType } from '../types/filter.types';

const stringToFilter = (query: string, options = {}) => {
  const sql = `select * from log ${query ? `where ${query}` : ''}`;
  const parser: any = new Parser();
  try {
    const { where } = parser.astify(sql);
    const { object } = getFilterObject({ where, isFirst: true, options });
    return object || false;
  } catch {
    return false;
  }
};

export default stringToFilter;

const getValueAndType = ({ value, operator = 'equals', options }) => {
  if (value === null) {
    return { processedValue: {}, type: operator === '=' ? StringConditionType.EMPTY : StringConditionType.NOT_EMPTY };
  }
  if (operator.toUpperCase() === 'LIKE') {
    if (value.length >= 2 && value[0] === '%' && value[value.length - 1] === '%') {
      return { processedValue: value.slice(1, value.length - 1), type: StringConditionType.CONTAINS };
    }
    if (value[0] === '%') {
      return { processedValue: value.slice(1), type: StringConditionType.ENDS };
    }
    if (value[value.length - 1] === '%') {
      return { processedValue: value.slice(0, value.length - 1), type: StringConditionType.STARTS };
    }
    return { processedValue: value, type: StringConditionType.EQUALS };
  }
  if (operator.toUpperCase() === 'NOT LIKE') {
    if (value.length >= 2 && value[0] === '%' && value[value.length - 1] === '%') {
      return { processedValue: value.slice(1, value.length - 1), type: StringConditionType.NOT_CONTAINS };
    }
    if (value[0] === '%' || value[value.length - 1] === '%') {
      return { processedValue: value, type: '' };
    }
    return { processedValue: value, type: StringConditionType.NOT_EQUAL };
  }

  if (operator.toUpperCase() === 'IN' && options.allowIn) {
    return { processedValue: { values: Array.isArray(value) ? value.map(val => val.value) : [value] }, type: StringConditionType.IN };
  }
  if (operator === '!=') {
    return { processedValue: value, type: StringConditionType.NOT_EQUAL };
  }
  if (operator === '=') {
    return { processedValue: value, type: StringConditionType.EQUALS };
  }
  return { processedValue: value, type: '' };
};

const getExpression = (where, options) => {
  const { left, operator, right } = where;
  if (
    (right.value === null && ['=', '!='].includes(operator)) ||
    (where?.type === 'binary_expr' &&
      ['single_quote_string', 'double_quote_string', 'expr_list'].includes(where?.right?.type) &&
      (where?.left?.type === 'column_ref' || where?.left?.type === 'double_quote_string'))
  ) {
    const { processedValue, type } = getValueAndType({ value: right.value, operator, options });
    if (!type) {
      return false;
    }
    const fieldName = left.column ? `${left.db ? `${left.db}.` : ''}${left.table ? `${left.table}.` : ''}${left.column}` : left.value;
    const expression: Expression = {
      fieldName,
      stringCondition: {
        [type]: processedValue,
      },
    };
    return { expression };
  }
  return false;
};

const getOpposite = (op: OperatorType) => (op === OperatorType.AND ? OperatorType.OR : OperatorType.AND);
const getOperatorOrUndefined = operator => (Object.keys(OperatorType).includes(operator) ? OperatorType[operator] : undefined);

const getFilterObject = ({ where, isFirst = false, mainOperator = OperatorType.AND, options = {} }): any => {
  const expression = getExpression(where, options);
  if (expression) {
    if (isFirst) {
      const operator = getOperatorOrUndefined(where.operator) || OperatorType.AND;
      if (mainOperator === operator) {
        return {
          mainOperator: getOpposite(operator),
          object: {
            [mainOperator]: { operands: [expression] },
          },
        };
      }
      return false;
    }
    return { object: expression, mainOperator };
  }
  const left = where?.left;
  const right = where?.right;
  const tempOperands: any[] = [];
  const tempMainOperator: string[] = [];
  if (left || right) {
    if (left) {
      const { object: leftPart, mainOperator: mainOperatorLeft } = getFilterObject({
        where: left,
        isFirst: !!where?.left?.parentheses,
        mainOperator: getOperatorOrUndefined(left.operator) || OperatorType[where.operator],
      });
      if (!leftPart) {
        return false;
      }
      tempOperands.push(...(Array.isArray(leftPart) ? leftPart : [leftPart]));
      tempMainOperator.push(mainOperatorLeft);
    }
    if (right) {
      const { object: rightPart, mainOperator: mainOperatorRight } = getFilterObject({
        where: right,
        isFirst: !!where?.right?.parentheses,
        mainOperator: getOperatorOrUndefined(right.operator) || OperatorType[where.operator],
      });
      if (!rightPart) {
        return false;
      }
      tempOperands.push(...(Array.isArray(rightPart) ? rightPart : [rightPart]));
      tempMainOperator.push(mainOperatorRight);
    }
    if (tempOperands.length) {
      const operator: OperatorType = Object.keys(OperatorType).includes(where?.operator) ? OperatorType[where.operator] : OperatorType.AND;
      if (isFirst) {
        if (!tempMainOperator.filter(v => v !== operator).length) {
          return {
            mainOperator: getOpposite(operator),
            object: {
              [operator]: {
                operands: tempOperands,
              },
            },
          };
        }
        return false;
      }
      if (!tempMainOperator.filter(v => v !== operator).length) {
        return { object: tempOperands, mainOperator };
      }
      return false;
    }
  }
  return false;
};
