import React, { ReactNode } from 'react';
import { DocumentNode } from 'graphql/language';
import { WorkflowType } from '../../context/SnackBar.types';

export enum SortType {
  ASC = 'asc',
  DESC = 'desc',
}
export enum GroupHeaderRowType {
  GROUP = 'GROUP',
  SUB_GROUP = 'SUB_GROUP',
  STANDALONE = 'STANDALONE',
}

export interface HeadCellType {
  id: string;
  label?: React.ReactNode;
  filter?: any;
  sx?: any;
  type?: string;
  initWidth?: number;
  disableSortBy?: boolean;
  sort?: SortType;
  formatter?: (data, row, setRows, index) => ReactNode;
  groupHeaderType?: GroupHeaderRowType;
  isKey?: boolean;
  hidden?: boolean;
  initSort?: SortType;
  style?: any;
  editable?: boolean;
  options?: any;
  getValue?: (row, colId?) => any;
  getFilterOptionFunc?: (row) => any;
  hasTooltip?: boolean;
  rowStyle?: any;
  linkProps?: LinkPropsType;
}
export type LinkPropsType = {
  shouldShowLink: (row) => boolean;
  onClickLink: (row) => void;
  tooltip?: string;
};

export interface PaginationProps {
  onPageChange: (pageNum: number) => void;
  totalRows?: number;
  pageNum: number;
}

interface TotalCell {
  formatter?: (data) => any;
  id: string;
  type?: string;
}

export interface ColumnIconButtonProp {
  onClick: (colId?) => void;
  icon: React.ReactNode;
  disableFirstColumn?: boolean;
}

interface NoDataProps {
  headline?: string;
}

export interface GroupedHeadCell {
  cells: any[];
  innerCols: number;
}

export interface AvTableProps {
  storageKey?: string;
  resizable?: boolean;
  groupedHeadCells?: GroupedHeadCell;
  headCells?: HeadCellType[];
  rows?: Record<string, unknown>[];
  onSelectAllRows?: (b: boolean) => void;
  hasHeaderFilters?: boolean;
  setRows?: (rows: any[]) => void;
  selectedKeys?: (number | string)[];
  activeKey?: number | string;
  isSelectionControlled?: boolean;
  onSelected?: (newVal, rows) => void;
  onActive?: (newVal) => void;
  onScroll?: (e, containerRef) => void;
  allowKeyboardNavigation?: boolean;
  deleteProps?: DeleteProps;
  loading?: boolean;
  multiSelect?: boolean;
  allowEdit?: boolean;
  allowCreate?: boolean;
  showHeader?: boolean;
  pagination?: boolean;
  onEdit?: (row, field, value) => void;
  onSort?: (property: string, direction: SortType | null, event) => void;
  customActions?: ((selected, selectedRows, setRows, handleSelected) => ReactNode)[];
  inlineActions?: ((row) => void)[];
  customGlobalActions?: (() => ReactNode)[];
  markedCells?: Record<string, any>;
  expandedRowObj?: {
    component: (...args: any[]) => any;
    shouldExpand?: (row) => boolean;
  };
  innerSelectLink?: (id, row) => string;
  innerEditLink?: (id) => string;
  enableSelectionColor?: boolean;
  rowLimit?: number;
  size?: 'large' | 'small' | 'xSmall' | 'tiles' | 'medium';
  sx?: Record<string, any>;
  tableContainerSx?: Record<string, any>;
  rowGroupByField?: (row) => any;
  loadMoreLimit?: number;
  totalProps?: TotalsProps;
  paginationProps?: PaginationProps;
  columnIconButtonProp?: ColumnIconButtonProp;
  onCancelColumn?: (v) => void;
  customNewButton?: React.ReactNode;
  noDataProps?: NoDataProps;
  disableUseEffect?: boolean;
  shouldDisplayNull?: boolean;
}

export interface TotalsProps {
  row?: any;
  label?: string;
  colspan?: number;
  cells?: TotalCell[];
}

export interface DeleteProps {
  uri?: string;
  query?: DocumentNode;
  workflowType?: WorkflowType;
  onDeleteSuccess?: () => void;
  onDelete?: (v) => void;
  getName?: (v) => string;
  message?: string;
  isConfirmDeletion?: boolean;
  title?: string;
}
