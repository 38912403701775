import React from 'react';
import { APP_PATHS, ROLES } from '../../types';
import { App } from '../../types/sideBar.types';
import { ReactComponent as AppAlerts } from '../../assets/apps/Alerts.svg';
import { ReactComponent as Assets } from '../../assets/apps/Assets.svg';
import { ReactComponent as Findings } from '../../assets/apps/Findings.svg';
import { ReactComponent as Incident } from '../../assets/apps/Incident.svg';
import { ReactComponent as Risk360 } from '../../assets/apps/Risk360.svg';

export const APPBAR_HEIGHT = 56;

export const appsOptions: App[] = [
  {
    id: APP_PATHS.VULNERABILITIES,
    name: 'Vulnerabilities',
    description: 'Vulnerability Prioritization Tool',
    icon: <Findings />,
    backgroundColor: 'linear-gradient(45deg, rgba(241, 178, 9, 0.35) 0%, rgba(252, 219, 53, 0.35) 100%)',
    rolesPermission: [ROLES.RND_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
  },
  {
    id: APP_PATHS.DETECTIONS,
    name: 'Detections',
    description: 'Event Detections',
    icon: <AppAlerts />,
    backgroundColor: 'linear-gradient(271deg, rgba(241, 119, 1, 0.25) 0%, rgba(250, 209, 169, 0.25) 100%)',
    rolesPermission: [ROLES.RND_ADMIN, ROLES.EDITOR, ROLES.SIEM_USER, ROLES.ADMIN],
  },
  {
    id: APP_PATHS.INCIDENTS,
    name: 'Incidents',
    description: 'Incident Management',
    icon: <Incident />,
    backgroundColor: 'linear-gradient(271.19deg, rgba(148, 139, 255, 0.3) 18.91%, rgba(212, 211, 255, 0.3) 141.41%)',
    rolesPermission: [ROLES.RND_ADMIN, ROLES.EDITOR, ROLES.ADMIN],
  },
  {
    id: APP_PATHS.RISK360,
    name: 'Risk360',
    description: 'Risk360',
    icon: <Risk360 />,
    backgroundColor: 'linear-gradient(271deg, rgba(85, 136, 251, 0.35) 18.91%, rgba(201, 220, 255, 0.35) 141.41%)',
    rolesPermission: [ROLES.RND_ADMIN, ROLES.EDITOR, ROLES.ADMIN],
  },
  {
    id: APP_PATHS.CAASM,
    name: 'CAASM',
    description: 'Cyber Asset Management',
    icon: <Assets />,
    backgroundColor: 'linear-gradient(271deg, rgba(83, 168, 255, 0.35) 0%, rgba(196, 225, 255, 0.35) 100%)',
    rolesPermission: [ROLES.RND_ADMIN], // TODO: deprecated
  },
];
