import React from 'react';
import { Route } from 'react-router-dom';
import { NotFound } from '../components/ErrorBoundary';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { APP_PATHS, PAGE_PATHS } from '../types';
import avLazy from '../utils/AvLazy';
import { entityViewConfig } from '../utils/entityViewConfig';

const UiConfigSettings = avLazy(() => import('../views/Settings/UiConfigSettings/UiConfigSettings'));
const AuditLogsUiConfig = avLazy(() => import('../views/Settings/UiConfigSettings/AuditLogsUiConfig'));

const AssetsSettings = () => {
  const {
    userPermissions: { hasAllowedPermission, isInternalRole },
  } = useAvContext();
  const getHasAllowedPermission = path => hasAllowedPermission({ path, app: APP_PATHS.CAASM });
  return (
    <>
      <Route path={PAGE_PATHS.SETTINGS}>
        <Route path="" element={<NotFound />} />
        <Route
          path={PAGE_PATHS.UI_CONFIG}
          element={
            <ProtectedRoute isAllowed={getHasAllowedPermission(PAGE_PATHS.UI_CONFIG)}>
              <UiConfigSettings projectionName={entityViewConfig.Asset.projectionName} />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path={`${PAGE_PATHS.UI_CONFIG}/audit/:path`}
        element={
          <ProtectedRoute isAllowed={isInternalRole}>
            <AuditLogsUiConfig />
          </ProtectedRoute>
        }
      />
    </>
  );
};

export default AssetsSettings;
