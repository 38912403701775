import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, SxProps } from '@mui/material';
import { noop } from '../../utils/Utils';
import { getTimeOptions } from '../../views/Rules/rules.options';
import AvMessagePopover from '../../views/Tickets/AvMessagePopover';
import { flex } from '../AvThemeProvider';
import AvToggle from '../AvToggle';
import Select from '../Select';
import {
  CronEnum,
  customFrequencyOptions,
  customFrequencyOptionsLimitedLookback,
  CustomFrequencyTypes,
  CustomFrequencyTypesNames,
  dayHours,
  defaultCron,
  defaultFrequencyTypes,
  FrequencyTypes,
  getCronFromObject,
  getCronType,
  halfDayHours,
  hourMinutes,
  minutesToTimeObject,
  weekOptions,
} from './Utils';
import { ReactComponent as Attention } from '../../assets/Attention.svg';

type CronParams = { frequency?: FrequencyTypes; week: string[]; time: string[]; customTime?: any };
const emptyCronParams = {
  frequency: FrequencyTypes.NONE,
  week: ['0'],
  time: [`${new Date().getHours()}:0`],
  customTime: { type: CustomFrequencyTypes.HOURS },
};
function getCronParams(cron: string | null, hideCustomDays): CronParams {
  if (!cron) {
    return emptyCronParams;
  }
  const cronArray = (cron || defaultCron).split(' ');
  const min: string = cronArray[CronEnum.MINUTE];
  const hour: string = cronArray[CronEnum.HOUR];
  const weekDays: string = cronArray[CronEnum.WEEKDAYS];
  const cronType = getCronType(cronArray);
  return {
    frequency: cronType.frequency,
    week: cron && !['*', '?'].includes(weekDays) ? weekDays.split(',') : ['0'],
    time: cron && hour !== '*' && min !== '*' ? hour.split(',').map(h => `${h}:${min}`) : ['0:0'],
    customTime:
      cronType.customFrequencyTypes && cronType.value
        ? { value: cronType.value, type: cronType.customFrequencyTypes }
        : { value: 1, type: hideCustomDays ? CustomFrequencyTypes.HOURS : CustomFrequencyTypes.DAYS },
  };
}

interface SchedulerSettingProps {
  cron: string | null;
  setCron: Dispatch<string | null>;
  lookBackInMinutes?: number;
  setLookBackInMinutes?: Dispatch<SetStateAction<number>>;
  cronOptions?: { allowNone?: boolean; hideCustomDays?: boolean };
  limitLookback?: boolean;
  isMultipleHours?: boolean;
  frequencyLabel?: string;
  customTimeTypes?: CustomFrequencyTypesNames[];
  sx?: SxProps;
}
const SchedulerSetting: React.FC<SchedulerSettingProps> = ({
  cron,
  setCron,
  lookBackInMinutes = 0,
  setLookBackInMinutes = noop,
  cronOptions: { allowNone, hideCustomDays } = {},
  limitLookback = false,
  isMultipleHours = false,
  frequencyLabel = 'Frequency',
  customTimeTypes = Object.keys(CustomFrequencyTypes),
  sx = {},
}) => {
  const cronParams: CronParams = getCronParams(allowNone && !cron ? cron! : cron || defaultCron, hideCustomDays);
  const [frequency, setFrequency] = useState<FrequencyTypes | undefined>(cronParams.frequency);
  const [week, setWeek] = useState<string[]>(cronParams.week);
  const [time, setTime] = useState<string[]>(cronParams.time);
  const [customTime, setCustomTime] = useState(cronParams.customTime);

  useEffect(() => {
    setFrequency(cronParams.frequency);
    setWeek(cronParams.week);
    setTime(cronParams.time);
    setCustomTime(cronParams.customTime);
  }, [cron]);

  const lookbackCategoriesOptions =
    limitLookback &&
    frequency === FrequencyTypes.CUSTOM &&
    (customTime.type === CustomFrequencyTypes.HOURS || customTime.type === CustomFrequencyTypes.MINUTES)
      ? Object.values(CustomFrequencyTypes).filter(v => v !== CustomFrequencyTypes.DAYS)
      : Object.values(CustomFrequencyTypes);

  const lookBackValuesOptions =
    customTime.type === CustomFrequencyTypes.MINUTES && limitLookback
      ? customFrequencyOptionsLimitedLookback[minutesToTimeObject(lookBackInMinutes).type]
      : customFrequencyOptions[minutesToTimeObject(lookBackInMinutes).type];

  const onChangeCustomTime = field => value => setCustomTime({ ...customTime, [field]: value });

  useEffect(() => {
    setCron(getCronFromObject(cron, time, frequency, week, customTime));
  }, [week, time, frequency, customTime]);

  const handleSetCustomTimeUpdate = type => {
    setCustomTime({ type, value: customFrequencyOptions[type][0].value });
    if (limitLookback && type === CustomFrequencyTypes.HOURS && lookBackInMinutes >= dayHours * hourMinutes) {
      setLookBackInMinutes((dayHours - 1) * hourMinutes);
    } else if (limitLookback && type === CustomFrequencyTypes.MINUTES && lookBackInMinutes >= halfDayHours * hourMinutes) {
      setLookBackInMinutes((halfDayHours - 1) * hourMinutes);
    }
  };

  const getCustomTimeTypes = () =>
    Object.keys(CustomFrequencyTypes)
      .filter(key => customTimeTypes.includes(key) && (!hideCustomDays || key !== CustomFrequencyTypesNames.DAYS))
      .map(key => CustomFrequencyTypes[key]);

  return (
    <Box sx={{ ...flex.colJustifyCenter, gap: '10px', margin: 0, ...sx } as SxProps}>
      <Select
        label={frequencyLabel}
        muiProps={isMultipleHours ? undefined : { sx: { width: 150 } }}
        value={frequency}
        onChange={setFrequency}
        options={Object.values(FrequencyTypes).filter(v => allowNone || v !== FrequencyTypes.NONE)}
        getValueFunc={v => v}
        getLabelFunc={v => v}
        isRequired
        size="small"
      />
      {frequency === FrequencyTypes.WEEKLY && (
        <AvToggle label="Repeat On" value={week} options={weekOptions} multiSelect onChange={setWeek} size="smallOutline" isRequired />
      )}
      {[FrequencyTypes.WEEKLY, FrequencyTypes.DAILY].includes(frequency!) && (
        <Select
          label="Time (UTC)"
          muiProps={isMultipleHours ? undefined : { sx: { width: 150 } }}
          value={isMultipleHours ? time : time[0]}
          onChange={val => setTime(isMultipleHours ? (val.length ? val : ['0:0']) : [val])}
          options={getTimeOptions(false, isMultipleHours ? 60 : undefined)}
          isRequired
          isMultiple={isMultipleHours}
          size="small"
        />
      )}
      {frequency === FrequencyTypes.CUSTOM && (
        <Box sx={{ ...flex.itemsEnd, gap: 2 }}>
          <Select
            label="Every"
            muiProps={{ sx: { width: 70 } }}
            value={customTime.value}
            onChange={v => onChangeCustomTime('value')(v)}
            options={customFrequencyOptions[customTime.type]}
            isRequired
            size="small"
          />
          <Box sx={{ ...flex.center, gap: 1 }}>
            <Select
              muiProps={{ sx: { width: 110 } }}
              value={customTime.type}
              onChange={type => handleSetCustomTimeUpdate(type)}
              options={getCustomTimeTypes()}
              getValueFunc={v => v}
              getLabelFunc={v => v}
              isRequired
              size="small"
            />
            <AvMessagePopover
              message="Starting automatically within the next 15 min"
              icon={<Attention />}
              sx={{ height: 40, borderRadius: '8px', ...flex.center }}
              asTooltip
            />
          </Box>
        </Box>
      )}
      {lookBackInMinutes && setLookBackInMinutes ? (
        <Box sx={{ ...flex.itemsEnd, gap: 2 }}>
          <Select
            label="Data from the Last"
            muiProps={{ sx: { width: 150 } }}
            value={lookBackInMinutes / defaultFrequencyTypes[minutesToTimeObject(lookBackInMinutes).type]}
            onChange={v => setLookBackInMinutes(v * defaultFrequencyTypes[minutesToTimeObject(lookBackInMinutes).type])}
            options={lookBackValuesOptions}
            isRequired
            size="small"
          />
          <Select
            style={{ paddingTop: 3 }}
            muiProps={{ sx: { width: 110 } }}
            value={minutesToTimeObject(lookBackInMinutes).type}
            onChange={type => setLookBackInMinutes(customFrequencyOptions[type][0].value * defaultFrequencyTypes[type])}
            options={lookbackCategoriesOptions}
            getValueFunc={v => v}
            getLabelFunc={v => v}
            isRequired
            size="small"
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default SchedulerSetting;
