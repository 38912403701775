import { getEmptyFilterExpression } from '../../../components/filters/Utils';
import { Permission } from '../../../types';
import { GroupingRuleSetTypes } from './types';

export const emptyRule = () => ({
  id: crypto.randomUUID(),
  isNew: true,
  name: '',
  condition: getEmptyFilterExpression(),
  entityTitleFormat: '',
  grouping: { fieldGrouping: { fields: [] } },
});

export function getProjectionToTriggered(projectionName: string) {
  switch (projectionName) {
    case GroupingRuleSetTypes.Ticket:
      return GroupingRuleSetTypes.Finding;
    case GroupingRuleSetTypes.Incident:
      return GroupingRuleSetTypes.Alert;
    default:
      throw new Error(`Invalid projection name: ${projectionName}`);
  }
}

export const isOnReadMode = (hasPermissionsMap: Partial<Record<Permission, boolean>>) =>
  hasPermissionsMap.READ && !hasPermissionsMap.CREATE && !hasPermissionsMap.UPDATE && !hasPermissionsMap.DELETE;

export const getNextCloneName = (names, targetName, nextNumber = 1) => {
  const baseName = targetName.includes(' - clone') ? targetName.split(' - clone')[0].trim() : targetName;

  const clonePattern = new RegExp(`^${baseName} - clone \\((\\d+)\\)$`);

  // Extract existing clone numbers for the given base name
  const cloneNumbers = names
    .map(name => {
      const match = name.match(clonePattern);
      return match ? parseInt(match[1], 10) : null; // Only capture the clone number
    })
    .filter(num => num !== null)
    .sort((a, b) => a - b); // Sort clone numbers in ascending order

  // Recursive case: if the current clone number exists, try the next one
  if (cloneNumbers.includes(nextNumber)) {
    return getNextCloneName(names, targetName, nextNumber + 1);
  }

  // Base case: return the name with the next available clone number
  return `${baseName} - clone (${nextNumber})`;
};

export const removeKey = (key, { [key]: _, ...rest }) => rest;

export const removeIdFromRuleSet = ruleSet => {
  const newRuleSet = removeKey('id', ruleSet);
  newRuleSet.rules = newRuleSet.rules.map(r => ({ ...removeKey('id', r) }));
  return newRuleSet;
};

export const isValidUUID = uuid => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};

export const isRuleSetValid = ruleSet =>
  !!ruleSet.rules?.length && ruleSet.rules.every(rule => rule.name && !!rule.grouping?.fieldGrouping?.fields?.length);
