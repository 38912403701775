import { gql } from '@apollo/client';
import { useMutation } from '@tanstack/react-query';
import { useAvContext } from '../context/AvContextProvider';

type OperationMode = 'DELETE' | 'UPDATE';

const UPDATE_ACCOUNT_DEFAULT_VIEW = gql`
  mutation updateAccountDefaultView($accountViewDto: AccountDefaultViewDtoInput!) {
    updateAccountDefaultView(accountViewDto: $accountViewDto) {
      accountId
      viewId
      screenType
      __typename
    }
  }
`;

const DELETE_ACCOUNT_DEFAULT_VIEW = gql`
  mutation deleteAccountDefaultView($accountViewDto: AccountDefaultViewDtoInput!) {
    deleteAccountDefaultView(accountViewDto: $accountViewDto)
  }
`;

const operations = {
  DELETE: DELETE_ACCOUNT_DEFAULT_VIEW,
  UPDATE: UPDATE_ACCOUNT_DEFAULT_VIEW,
};

const useUpdateAccountDefaultView = (mode: OperationMode) => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: ({ accountViewDto, onError, onSuccess }: { accountViewDto; onError?; onSuccess }) =>
      api(operations[mode], { options: { accountViewDto }, onError, onSuccess }),
  });
};

export default useUpdateAccountDefaultView;
