import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  Divider,
  Drawer,
  drawerClasses,
  IconButton,
  LinearProgress,
  Skeleton,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { LayoutContext } from '../context/LayoutContext';
import { noop } from '../utils/Utils';
import { drawerWidth } from '../views/Tickets/ticket.types';
import AvMenu from './AvMenu';
import { flex } from './AvThemeProvider';
import CopyToClipboard, { CopyType } from './CopyToClipboard';
import { APPBAR_HEIGHT } from './newNavigation/appbar.constants';
import { ReactComponent as Maximize } from '../assets/Maximize.svg';
import { ReactComponent as Minimize } from '../assets/Minimize.svg';
import { ReactComponent as X } from '../assets/X.svg';

const closeButton = <X style={{ width: 22, height: 22 }} />;
const maximizeIcon = <Maximize style={{ width: 16, height: 16, transform: 'rotate(90deg)' }} />;
const minimizeIcon = <Minimize style={{ width: 16, height: 16, transform: 'rotate(90deg)' }} />;

interface AvSidePanelProps {
  children?: React.ReactNode;
  storageKey: string;
  maxWidthOffset?: number;
  topNavRightComponent?: React.ReactElement;
  minWidth?: number;
  maxWidth?: number;
  onClose?: () => void;
  isOpen?: boolean;
  footerComponent?: React.ReactElement;
  headComponent?: React.ReactElement;
  showLoader?: boolean;
  anchor?: 'right' | 'left';
  menuOptions?: {
    title: string;
    onClick(...args: unknown[]): unknown;
  }[];
  maximizedControlled?: boolean;
}
const AvSidePanel: React.FC<AvSidePanelProps> = ({
  children,
  storageKey,
  maxWidthOffset = 0,
  topNavRightComponent,
  maximizedControlled = undefined,
  minWidth = 660,
  maxWidth = 2000,
  onClose = noop,
  isOpen = false,
  footerComponent,
  headComponent,
  showLoader,
  anchor = 'right',
  menuOptions,
}) => {
  const headerComponentActionsRef = useRef<HTMLDivElement>(null);
  const [maximizedInner, setMaximizedInner] = useState(false);
  const headerActionsElWidth = headerComponentActionsRef?.current?.clientWidth || 0;
  const maximized = maximizedControlled || maximizedInner;
  return (
    <ClickAwayListener
      onClickAway={(e: any) =>
        e.target.parentElement?.tagName !== 'A' && e.target.tagName !== 'A' && e.target !== document.body && onClose()
      }>
      <Drawer
        anchor={anchor}
        open={isOpen}
        disableAutoFocus
        disableRestoreFocus
        onClose={() => onClose()}
        hideBackdrop
        sx={{
          [`&.${drawerClasses.root}`]: { right: 0, left: 'auto', width: 'fit-content' },
          [`.${drawerClasses.paper}`]: { overflow: 'inherit' },
        }}>
        <BoxResizable
          storageKey={storageKey}
          sx={{
            pt: `${APPBAR_HEIGHT}px`,
            width: maximized ? `calc(100vw - ${maxWidthOffset}px)` : undefined,
            height: '100%',
            maxWidth: `calc(100vw - ${maxWidthOffset}px)`,
          }}
          anchor={anchor}
          minWidth={minWidth}
          maxWidth={maxWidth}
          disabled={maximized}>
          <Box sx={{ ...flex.col, pt: '20px', height: '100%', '> *': { px: 2.5, flexShrink: 0 } }}>
            <Box sx={{ ...flex.itemsStart, mb: '20px' }}>
              <Box
                sx={{
                  '.ticket-title-container, .back-breadcrumb-title': { maxWidth: `calc(100% - ${headerActionsElWidth}px)` },
                  width: '100%',
                }}>
                {headComponent}
              </Box>
              <Box ref={headerComponentActionsRef} sx={{ ...flex.row, position: 'absolute', right: 24 }}>
                {topNavRightComponent ?? <Box />}
                <Box sx={{ ...flex.row, gap: '10px', ml: '10px' }}>
                  {menuOptions && <AvMenu options={menuOptions} />}
                  <Divider flexItem orientation="vertical" sx={{ height: 24, alignSelf: 'center' }} />
                  <Box sx={{ ...flex.itemsCenter, gap: 1.5 }}>
                    <IconButton size="small" onClick={() => setMaximizedInner(!maximized)}>
                      {maximized ? minimizeIcon : maximizeIcon}
                    </IconButton>
                    <IconButton size="small" onClick={() => onClose()}>
                      {closeButton}
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
            {headComponent && showLoader ? (
              <LinearProgress sx={{ height: '3px', mt: '-2px' }} />
            ) : (
              <Divider sx={!headComponent ? { pb: '15px' } : undefined} />
            )}
            <Box sx={{ ...flex.col, gap: 1, overflow: 'auto', flexGrow: 1, flexShrink: 1 }}>{children}</Box>
            {footerComponent && (
              <Box
                sx={{
                  ...flex.justifyEndCenter,
                  gap: 1,
                  '&:not(:empty)': { height: 52, padding: '10px 32px', boxShadow: '0px -4px 18px rgba(18, 18, 27, 0.1)', zIndex: 1 },
                }}>
                {footerComponent}
              </Box>
            )}
          </Box>
        </BoxResizable>
      </Drawer>
    </ClickAwayListener>
  );
};
export default AvSidePanel;

interface BoxResizableProps {
  children?: React.ReactNode;
  storageKey: string;
  sx?: SxProps;
  minWidth: number;
  maxWidth: number;
  disabled?: boolean;
  anchor?: 'right' | 'left';
}
const BoxResizable: React.FC<BoxResizableProps> = ({
  children,
  storageKey,
  sx = {},
  minWidth,
  maxWidth,
  disabled = false,
  anchor = 'right',
  ...boxProps
}) => {
  const theme = useTheme();
  const {
    layoutWidths: { [storageKey]: width = drawerWidth },
    setLayoutWidths,
  } = useContext(LayoutContext);
  const [tempWidth, setTempWidth] = useState(width);
  const getNewWidth = (e: React.MouseEvent | React.TouchEvent | any) =>
    Math.min(Math.max(document.body.offsetWidth - (e.changedTouches?.[0] || e).clientX, minWidth), maxWidth);

  const updateLayoutWidths = (storageKey, newWidth) => {
    setLayoutWidths(storageKey, newWidth);
    window.dispatchEvent(new Event('onSidePanelResize'));
  };

  const handleMouseDown = () => {
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('touchend', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
    document.addEventListener('touchmove', handleMouseMove, { passive: false });
  };

  const handleMouseUp = (e: MouseEvent | TouchEvent) => {
    if (storageKey) {
      updateLayoutWidths(storageKey, getNewWidth(e));
    }
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('touchend', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
    document.removeEventListener('touchmove', handleMouseMove, { passive: false } as AddEventListenerOptions);
  };

  const handleMouseMove = useCallback(e => {
    setTempWidth(getNewWidth(e));
    e.preventDefault();
  }, []);

  return (
    <Box sx={{ ...sx, width: (sx as any).width || tempWidth, position: 'relative' }} {...boxProps}>
      <Box
        sx={{
          width: 8,
          height: '100%',
          position: 'absolute',
          ...(anchor === 'right' ? { left: 0 } : { right: 0 }),
          borderLeft: '2px solid transparent',
          cursor: 'ew-resize',
          transition: theme.transitions.create(['border-color'], {
            duration: theme.transitions.duration.short,
          }),
          pointerEvents: disabled ? 'none' : undefined,
          '&:hover': { borderColor: theme.palette.colors.primary[300] },
        }}
        onMouseDown={e => {
          handleMouseDown();
          e.preventDefault();
        }}
        onTouchStart={handleMouseDown}
        onDoubleClick={() => {
          updateLayoutWidths(storageKey, drawerWidth);
          setTempWidth(drawerWidth);
        }}
      />
      {children}
    </Box>
  );
};

const pressKey = code => window.dispatchEvent(new KeyboardEvent('keydown', { code }));

interface NavigationBarProps {
  id: string;
}
export const NavigationBar: React.FC<NavigationBarProps> = ({ id }) => {
  useEffect(() => {
    const registerTicketNav = (event: KeyboardEvent) => {
      if (event.code === 'ArrowLeft') {
        pressKey('ArrowUp');
      } else if (event.code === 'ArrowRight') {
        pressKey('ArrowDown');
      }
    };
    window.addEventListener('keydown', registerTicketNav);

    return () => window.removeEventListener('keydown', registerTicketNav);
  }, []);

  return (
    <Box sx={{ ...flex.itemsCenter }}>
      <Box sx={{ ...flex.center, gap: '4px', minWidth: 80 }}>
        <Typography variant="h7" sx={{ ...flex.row, gap: 1 }}>
          ID / {id ?? <Skeleton width={60} />}
        </Typography>
        <CopyToClipboard copyData={window.location.href} copyType={CopyType.LINK} />
      </Box>
    </Box>
  );
};

export const AvSidePanelTabsSkeleton = () => (
  <Box sx={{ ...flex.row, height: '100%' }}>
    <Box sx={{ width: 180, gap: 1, pr: '20px' }}>
      <Skeleton width="100%" height={32} />
      <Skeleton width="100%" height={32} />
      <Skeleton width="100%" height={32} />
      <Skeleton width="100%" height={32} />
    </Box>
    <Divider flexItem orientation="vertical" />
    <Box />
  </Box>
);
