import React, { Component } from 'react';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { noop } from '../utils/Utils';
import FatalError from '../assets/FatalError.svg';
import NotFoundSVG from '../assets/NotFound.svg';

const notFoundStyle = {
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  animation: 'fadeIn 0.5s',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: '24px',
  backgroundColor: theme => theme.palette.background.default,
  h3: {
    color: theme => theme.palette.colors.neutrals[600],
    marginBottom: 0,
  },
  button: {
    color: theme => theme.palette.primary.main,
    fontWeight: 600,
  },
};
// TODO merge with NoDataFound component
export function NotFound({ text, image, buttonLabel, onclick }) {
  const navigate = useNavigate();
  const navi = () => navigate('/');
  return (
    <main>
      <Box sx={notFoundStyle}>
        <img src={image} alt="not found" style={{ marginBottom: '56px' }} />
        <Typography variant="h3">{text}</Typography>
        <Button variant="text" onClick={onclick || navi}>
          {buttonLabel}
        </Button>
      </Box>
    </main>
  );
}
NotFound.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  buttonLabel: PropTypes.string,
  onclick: PropTypes.func,
};
NotFound.defaultProps = {
  text: 'Page Not Found',
  image: NotFoundSVG,
  buttonLabel: 'Return to Homepage',
  onclick: undefined,
};

class ErrorBoundaryClass extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const { hasError } = prevState;
    if (hasError && prevProps.location !== location) {
      this.setState(prev => ({ ...prev, hasError: false }));
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    this.props.onError();
  }

  render() {
    const { hasError } = this.state;
    const { children, fallbackComponent } = this.props;

    if (hasError) {
      return fallbackComponent || <NotFound text="Something Went Wrong..." image={FatalError} />;
    }
    return children;
  }
}
ErrorBoundaryClass.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape().isRequired,
  fallbackComponent: PropTypes.node,
  onError: PropTypes.func,
};

ErrorBoundaryClass.defaultProps = {
  fallbackComponent: undefined,
  onError: noop,
};

export function ErrorBoundary({ children, ...props }) {
  const location = useLocation();
  return (
    <ErrorBoundaryClass location={location} {...props}>
      {children}
    </ErrorBoundaryClass>
  );
}
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallbackComponent: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  fallbackComponent: undefined,
};
