// eslint-disable-next-line import/prefer-default-export
import React from 'react';
import { differenceInMinutes } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { PAGE_PATHS } from '../types';

// hook to check the app version on route change
// eslint-disable-next-line import/prefer-default-export
export const useAppVersion = () => {
  // check if a version update (refresh) needed on route change
  const location = useLocation();
  const mode = import.meta.env.MODE;
  const version = import.meta.env.VITE_VERSION;
  const pagesToIgnore = [PAGE_PATHS.MODEL_MANAGEMENT]; // we need to think of a solution for keeping state for model management that will last refreshes
  React.useLayoutEffect(() => {
    if (!location.state && mode !== 'development' && pagesToIgnore.every(p => !location.pathname.includes(p))) {
      fetch('/version.json')
        .then(res => res.json())
        .then(data => {
          // if new version file was uploaded
          const lastForcedRefresh = sessionStorage.getItem('last_forced_version_refresh');
          // prevent infinite loop in case of an error with updating the version
          const isRefreshedinTheLastMinutes = lastForcedRefresh ? differenceInMinutes(new Date(), new Date(+lastForcedRefresh)) < 5 : false;
          const newVersionExist = data.version !== version;
          if (newVersionExist && !isRefreshedinTheLastMinutes) {
            sessionStorage.setItem('last_forced_version_refresh', `${Date.now()}`);
            window.location.reload();
          }
        })
        .catch(() => {});
    }
  }, [location.pathname]);
};
