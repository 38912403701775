import React from 'react';
import { useParams } from 'react-router-dom';
import { ItemWithLogo } from '../../components/ItemWithLogo';
import AuditLogs from '../AuditLogs/AuditLogs';
import { AuditEntityType } from '../AuditLogs/types';
import { useGetIntegrationById } from './hooks';

interface Props {
  isMapping?: boolean;
}
const NotificationTargetAuditLogs: React.FC<Props> = ({ isMapping }) => {
  const { id: instanceId, idMapping: mappingId } = useParams();
  const { data: integrationData, isLoading } = useGetIntegrationById(instanceId);

  const title = (
    <>
      <ItemWithLogo type={integrationData?.name} variant="destinationsMap" logoWidth={40} hideText />
      Audit Logs {isMapping ? `${integrationData?.name} Integration Mapping` : integrationData?.name}
    </>
  );
  return (
    <AuditLogs
      id={isMapping ? mappingId : instanceId}
      entityType={isMapping ? AuditEntityType.INTEGRATION_INSTANCE_MAPPING : AuditEntityType.INTEGRATION_INSTANCE}
      title={title}
      isQueryLoading={isLoading}
    />
  );
};

export default NotificationTargetAuditLogs;
