import React, { useEffect, useState } from 'react';
import { isExportMode } from '../utils/exportDashboards.utils';

interface Props {
  root?: any;
  rootMargin?: string;
  threshold?: number;
}
const useElementOnScreen = ({ root = null, rootMargin = '0px', threshold = 0.05 }: Props) => {
  const ref = React.useRef<any>(null);
  const [isVisible, setIsVisible] = useState(false);
  const callback = entries => {
    const [entry] = entries;
    setIsVisible(prev => (prev ? true : entry.isIntersecting));
  };
  useEffect(() => {
    const observer = new IntersectionObserver(callback, { root, rootMargin, threshold });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, isVisible]);
  return { ref, isVisible: isExportMode() || isVisible };
};
export default useElementOnScreen;
