import React from 'react';
import { FormControlLabel, Switch, SxProps } from '@mui/material';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';
import AvTooltip from './AvTooltip';

interface SwitchToggleProps {
  label?: string;
  value?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  tooltipOnDisabled?: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  sx?: SxProps;
}

const SwitchToggle: React.FC<SwitchToggleProps> = ({
  label,
  value = false,
  onChange = () => {},
  disabled,
  tooltipOnDisabled,
  labelPlacement = 'end',
  sx = {},
}) => (
  <AvTooltip title={tooltipOnDisabled} disabled={!disabled || !tooltipOnDisabled}>
    <FormControlLabel
      className="switchToggle"
      sx={{ width: 'fit-content', gap: '10px', margin: 0, ...sx }}
      control={<Switch checked={value} onChange={({ target }) => onChange(target.checked)} />}
      label={label}
      disabled={disabled}
      labelPlacement={labelPlacement}
    />
  </AvTooltip>
);
export default SwitchToggle;
