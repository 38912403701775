import React from 'react';
import { useLocation } from 'react-router-dom';
import ZendeskAuthentication from '../hooks/zendeskAuthentication';

interface KBConnectProps {
  children: any;
}

const ZendeskAuthProvider: React.FC<KBConnectProps> = ({ children }) => {
  const location = useLocation();
  const isZendeskAuthRequired = location.pathname.includes('/zendesk/token');

  return isZendeskAuthRequired ? ZendeskAuthentication(children) : children;
};

export default ZendeskAuthProvider;
