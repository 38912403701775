import React, { useEffect, useState } from 'react';
import { IconButton, useTheme } from '@mui/material';
import { ReactComponent as Maximize } from '../assets/Maximize.svg';
import { ReactComponent as Minimize } from '../assets/Minimize.svg';

const maximizeIcon = <Maximize style={{ width: 20, height: 20, transform: 'rotate(90deg)' }} />;
const minimizeIcon = <Minimize style={{ width: 20, height: 20, transform: 'rotate(90deg)' }} />;

const getInitialStyle = container => {
  const { height, top, right, bottom, left } = getComputedStyle(container);
  return { ...container.style, exactHeight: height, exactInset: `${top} ${right} ${bottom} ${left}` };
};
export default function useFullScreenToggle(container: HTMLElement | null) {
  const theme = useTheme();
  const [maximized, setMaximized] = useState(false);
  const [minimizedStyle, setMinimizedStyle] = useState<CSSStyleDeclaration & { exactHeight; exactInset }>({} as any);
  const transitionDuration = theme.transitions.duration.standard;

  const minimize = (shouldTransition = true) => {
    const { height, top, right, bottom, left } = getComputedStyle(container!);
    const { style } = container!;
    style.setProperty('height', height);
    style.setProperty('inset', `${top} ${right} ${bottom} ${left}`);
    setTimeout(() => {
      container!.classList.remove('avalor-full-screen');
      [...style].forEach(key => style.setProperty(key, minimizedStyle[key]));
      if (shouldTransition) {
        style.setProperty('transition', `all ${transitionDuration}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`);
      }
      style.setProperty('inset', minimizedStyle.exactInset);
      style.setProperty('height', minimizedStyle.exactHeight);
      setTimeout(() => {
        style.setProperty('height', minimizedStyle.height || '');
        style.setProperty('inset', minimizedStyle.inset || '');
        style.setProperty('transition', minimizedStyle.transition || '');
      }, transitionDuration);
    });
  };

  useEffect(() => {
    if (container) {
      if (maximized) {
        const initialStyle = getInitialStyle(container);
        setMinimizedStyle(initialStyle);
        container.style.setProperty('inset', initialStyle.exactInset);
        container.style.setProperty('height', initialStyle.exactHeight);
        container.style.setProperty('transition', `all ${transitionDuration}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`);
        setTimeout(() => {
          container.classList.add('avalor-full-screen');
        });
      } else if (minimizedStyle.exactHeight) {
        minimize();
      }
    }

    const onResize = () => {
      if (maximized) {
        minimize(false);
        setMaximized(false);
      }
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [container, maximized]);

  const onClick = e => {
    e.stopPropagation();
    setMaximized(!maximized);
  };
  return {
    maximized,
    fullScreenToggle: <IconButton onClick={onClick}>{maximized ? minimizeIcon : maximizeIcon}</IconButton>,
  };
}
