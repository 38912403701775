import { gql } from '@apollo/client';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../context/AvContextProvider';
import { downloadURI } from '../utils/Utils';

const GET_PRESIGNED_URL = gql`
  query ($objectKeys: String!) {
    getPresignedUrl(objectKeys: $objectKeys)
  }
`;

const GET_QUERY_PRESIGNED_URL = gql`
  query ($wfId: String!, $runId: String!) {
    getExportQueryPresignedUrl(wfId: $wfId, runId: $runId)
  }
`;

export enum PresignedUrlType {
  REGULAR = 'REGULAR',
  QUERY = 'QUERY',
}

export const gplPresignedUrlType = {
  [PresignedUrlType.REGULAR]: GET_PRESIGNED_URL,
  [PresignedUrlType.QUERY]: GET_QUERY_PRESIGNED_URL,
};

export const usePresignedUrl = (type: PresignedUrlType = PresignedUrlType.REGULAR) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<Record<string, string>, string, PresignedUrlObjectKeys | PresignedUrlWorkflowResult>({
    mutationFn: value => api(gplPresignedUrlType[type], { options: value }),
    onError: (err: any) => {
      enqueueSnackbar(`Failed to download: ${err.message}`, { variant: 'error' });
    },
    onSuccess: ({ data, errors }) => {
      if (!errors) {
        downloadURI(Object.values(data)[0]);
      }
    },
  });
};

type PresignedUrlObjectKeys = { objectKeys: string };

type PresignedUrlWorkflowResult = { wfId: string; runId: string };
