import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';

// eslint-disable-next-line import/prefer-default-export
export const createRoutes = (rootPath, hasAllowedPermission, list) => (
  <>
    {list.map(({ path, element, permissions, routes }) => (
      <Route
        key={path}
        path={path}
        element={
          routes ? undefined : (
            <ProtectedRoute isAllowed={permissions.some(permission => hasAllowedPermission({ path: rootPath, permission }))}>
              {element()}
            </ProtectedRoute>
          )
        }>
        {routes && createRoutes(rootPath, hasAllowedPermission, routes)}
      </Route>
    ))}
  </>
);
