import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';
import avLazy from '../utils/AvLazy';

const LookupTables = avLazy(() => import('../views/LookupTables/LookupTables'));
const EditLookupTables = avLazy(() => import('../views/LookupTables/EditLookupTables'));
const AuditLogsLookupTables = avLazy(() => import('../views/LookupTables/AuditLogsLookupTables'));

const LookupTableRoute = () => {
  const {
    userPermissions: { hasAllowedPermission, isInternalRole },
  } = useAvContext();

  const path = PAGE_PATHS.LOOKUP_TABLES;
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <LookupTables />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.CREATE })}>
            <EditLookupTables />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE })}>
            <EditLookupTables />
          </ProtectedRoute>
        }
      />
      <Route
        path="audit/:id"
        element={
          <ProtectedRoute isAllowed={isInternalRole}>
            <AuditLogsLookupTables />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default LookupTableRoute;
