import { useMemo } from 'react';
import { useAvContext } from '../context/AvContextProvider';
import { FieldListObject } from '../context/context.type';

const useGlobalInteractiveFilters = (): FieldListObject[] => {
  const { aggProjs } = useAvContext().accountEntities;
  return useMemo(
    () =>
      Object.values(
        Object.values(aggProjs).reduce(
          (allFieldsToFilterBy, { fieldList: { INTERACTIVE } }) => ({
            ...allFieldsToFilterBy,
            ...INTERACTIVE.reduce((projFieldsToFilterBy, field) => {
              const key = `${field.mainProjId.name} - ${field.group} - ${field.name}`;
              return field.visibilityConfig.config.hidden
                ? projFieldsToFilterBy
                : {
                    ...projFieldsToFilterBy,
                    [key]: {
                      ...field,
                      otherAliases: [...(allFieldsToFilterBy[key]?.otherAliases ?? []), field.value],
                    },
                  };
            }, {}),
          }),
          {}
        )
      ),
    []
  );
};

export default useGlobalInteractiveFilters;
