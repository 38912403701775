import React from 'react';
import { Box, Typography } from '@mui/material';
import { flex } from './AvThemeProvider';
import { dotStyle, stringToColor } from '../utils/Utils';

interface AvAvatarProps {
  displayName?: string;
}

const AvAvatar: React.FC<AvAvatarProps> = ({ displayName = 'Unknown' }) => (
  /* @ts-ignore */
  <Box component="span" sx={{ ...flex.center, backgroundColor: stringToColor(displayName), ...dotStyle(undefined, '100%') }}>
    <Typography sx={{ fontWeight: 600, fontSize: 12, color: ({ palette }) => palette.white.main, textTransform: 'uppercase' }}>
      {displayName[0]}
    </Typography>
  </Box>
);
export default AvAvatar;
