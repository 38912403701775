import React, { Dispatch, SetStateAction } from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { flex } from '../AvThemeProvider';

interface SelectAllRowsButtonProps {
  width: number;
  colspan: number;
  totalRows: number;
  currentPageRows: number;
  onClearSelection: () => void;
  onSelectAllRows: () => void;
  isClickedAll: boolean;
  setAllClicked: Dispatch<SetStateAction<boolean>>;
}

const SelectAllRowsButton: React.FC<SelectAllRowsButtonProps> = ({
  colspan,
  totalRows,
  currentPageRows,
  onClearSelection,
  onSelectAllRows,
  width,
  isClickedAll,
  setAllClicked,
}) => (
  <TableRow>
    <TableCell colSpan={colspan} align="center" sx={{ background: theme => theme.palette.colors.neutrals[300] }}>
      <Box sx={{ ...flex.center, gap: 1, width }}>
        {!isClickedAll ? (
          <>
            You selected all {currentPageRows} rows on this page.{' '}
            <Button
              size="xSmall"
              sx={{ color: theme => theme.palette.primary.main }}
              onClick={() => {
                onSelectAllRows();
                setAllClicked(true);
              }}>
              {' '}
              Select all {totalRows.toLocaleString()} rows
            </Button>
          </>
        ) : (
          <>
            You selected all {totalRows}{' '}
            <Button size="xSmall" sx={{ color: theme => theme.palette.primary.main }} onClick={onClearSelection}>
              Clear Selection
            </Button>
          </>
        )}
      </Box>
    </TableCell>
  </TableRow>
);

export default SelectAllRowsButton;
