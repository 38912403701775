import { useCallback, useMemo } from 'react';
import { gql } from '@apollo/client';
import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import { useAvContext } from '../context/AvContextProvider';
import { QueryObjectProto } from '../types/QueryObjectProto.types';
import { cleanEmptyFilters } from '../utils/filterUtils';
import useDebounce from './useDebounce';

const QUERY = gql`
  query queryObjectProto($queryRequest: QueryRequestInput!) {
    queryObjectProto(queryRequest: $queryRequest)
  }
`;

export default function useQueryObjectProto({
  queryObject,
  onSuccess = d => d,
  onError = undefined,
  showTotals = false,
  totalRowCount = false,
  options = {},
  muteErrors = undefined,
  debounceQueries = false,
}: {
  queryObject?: QueryObjectProto | QueryObjectProto[];
  origin?: string;
  showTotals?: boolean;
  totalRowCount?: boolean;
  onSuccess?: (d: any[], index: number) => any[];
  onError?: (error) => void;
  options?: Partial<QueryObserverOptions>;
  muteErrors?: boolean;
  debounceQueries?: boolean;
}): {
  isLoading: boolean;
  data: any;
  isPreviousData: boolean;
  isRefetching: boolean;
  totals?: Record<string, number> | (Record<string, number> | undefined)[];
  totalRowCount?: number;
  refetch: () => Promise<Awaited<any>[]>;
  errors: (any[] | undefined)[];
} {
  const { api } = useAvContext();
  const { debouncedValue, isDebouncing } = useDebounce(
    {
      queriesObject: Array.isArray(queryObject)
        ? queryObject.map(cleanQueryObjectProtoFilters)
        : [queryObject ? cleanQueryObjectProtoFilters(queryObject) : undefined],
      options,
      showTotals,
      totalRowCount,
    },
    debounceQueries ? 500 : 0
  );

  const queries = debouncedValue.queriesObject.map((query, index) => ({
    queryKey: [JSON.stringify(query)],
    queryFn: ({ signal }: { signal?: AbortSignal }) =>
      api(QUERY, {
        options: {
          queryRequest: {
            query,
          },
        },
        onError,
        signal,
        muteErrors,
      })
        .then(data => {
          const flattenedData = data?.data?.queryObjectProto?.rows?.map(row => ({ ...row.data, id: row.id }));
          if (data === undefined || data.errors) {
            throw data?.errors || new Error(`Network Error: ${debouncedValue.queriesObject[index]}`);
          }
          return {
            data: flattenedData?.length ? onSuccess(flattenedData, index) : flattenedData,
          };
        })
        .catch(e => {
          if (!signal?.aborted) {
            if (muteErrors) {
              onError?.(e);
            } else {
              throw e;
            }
          }
        }),
    ...debouncedValue.options,
    enabled:
      (debouncedValue.options.enabled || debouncedValue.options.enabled === undefined) &&
      (!!query?.dims?.length || !!query?.metrics?.length),
  }));

  // @ts-ignore
  const results: {
    data: { data?: any };
    refetch: () => Promise<any>;
    isLoading: boolean;
    isRefetching: boolean;
    isPreviousData: boolean;
    error?: any[];
  }[] = useQueries({ queries });
  const refetch = useCallback(() => Promise.all(results.map(r => r.refetch())), [results]);
  return useMemo(
    () => ({
      data:
        !results.length || (queries.length > 1 && results.some(({ data: { data } }) => !data?.length))
          ? undefined
          : results.length === 1
            ? results[0].data?.data
            : results.map(({ data }) => data?.data),
      isLoading: results.some(
        result =>
          result.isLoading || (isDebouncing && debouncedValue.queriesObject.find(query => !query?.dims?.length && !query?.metrics?.length))
      ),
      errors: results.map(result => result.error).filter(e => !!e) || [],
      refetch,
      isRefetching: results.some(
        result =>
          result.isRefetching ||
          (isDebouncing && debouncedValue.queriesObject.find(query => !!query?.dims?.length || !!query?.metrics?.length))
      ),
      isPreviousData: results.some(result => result.isPreviousData),
      isDebouncing,
    }),
    [results]
  );
}

export const cleanQueryObjectProtoFilters = (query: QueryObjectProto): QueryObjectProto => ({
  ...query,
  metrics: query.metrics.map(m => ({ ...m, agg: { ...m.agg, filter: cleanEmptyFilters(m.agg.filter) } })),
  filter: cleanEmptyFilters(query.filter),
});
