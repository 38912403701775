import React from 'react';
import { Box, Typography } from '@mui/material';
import { flex } from '../components/AvThemeProvider.tsx';
import Funnel from '../components/Widgets/Funnel.tsx';
import SourcesStatus from '../components/Widgets/SourcesStatus';
import StatusWithChartOverview from '../components/Widgets/StatusWithChartOverview';
import Widget from '../components/Widgets/Widget';
import { useWidgetData } from '../utils/dashboardDataUtils';
import { ReactComponent as Asset } from '../assets/AssetCircle.svg';
import { ReactComponent as Finding } from '../assets/FindingCircle.svg';
import { ReactComponent as Ticket } from '../assets/TicketCircle.svg';

function DashboardOverviewPage() {
  const widgetData = useWidgetData();

  return (
    <Box sx={{ ...flex.col, gap: 2 }}>
      <Typography variant="h3" sx={{ mb: 1 }}>
        Overview
      </Typography>
      <Box sx={theme => ({ ...flex.row, gap: 2, alignItems: 'start', [theme.breakpoints.down('laptop')]: { flexDirection: 'column' } })}>
        <Box sx={theme => ({ ...flex.col, gap: 2, width: '55%', [theme.breakpoints.down('laptop')]: { width: '100%' } })}>
          <Widget height={414} {...widgetData.sources} sql={widgetData.sources.sql()} titleMargin={0}>
            <SourcesStatus />
          </Widget>
        </Box>
        <Box sx={theme => ({ ...flex.col, gap: 2, width: '45%', [theme.breakpoints.down('laptop')]: { width: '100%' } })}>
          <Widget height={414} {...widgetData.findingDuplication} sql={widgetData.findingDuplication.sql()}>
            <Funnel />
          </Widget>
        </Box>
      </Box>
      <Box sx={{ ...flex.row, gap: 2 }}>
        <Widget height={118} {...widgetData.assetAssignment} sql={widgetData.assetAssignment.sql()} hideTitle>
          <StatusWithChartOverview
            title={widgetData.assetAssignment.title}
            formatFunc={count => `${Math.round(count)}%`}
            icon={<Asset />}
          />
        </Widget>
        <Widget height={118} {...widgetData.findingsAssignment} sql={widgetData.findingsAssignment.sql()} hideTitle>
          <StatusWithChartOverview
            title={widgetData.findingsAssignment.title}
            formatFunc={count => `${Math.round(count)}%`}
            icon={<Finding />}
          />
        </Widget>
        <Widget height={118} {...widgetData.ticketsAssignment} sql={widgetData.ticketsAssignment.sql} hideTitle>
          <StatusWithChartOverview
            title={widgetData.ticketsAssignment.title}
            formatFunc={count => `${Math.round(count)}%`}
            icon={<Ticket />}
          />
        </Widget>
      </Box>
    </Box>
  );
}

export default DashboardOverviewPage;
