import React from 'react';
import { alpha, Box, useTheme } from '@mui/material';
import { App } from '../../types/sideBar.types';
import { iconSize } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import { ActionButton } from '../Table/Utils';
import { ReactComponent as StarFull } from '../../assets/StarFull.svg';
import { ReactComponent as StarLine } from '../../assets/StarLine.svg';

const starFullIcon = theme => <StarFull style={{ color: theme.palette.colors.complementary[500], ...iconSize(20) }} />;
const starLineIcon = <StarLine style={iconSize(20)} />;

interface AppItemProps extends App {
  isSelected: boolean;
  isFavorite?: boolean;
  disableHover?: boolean;
  showDescription?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  toggleFavoriteApp?: () => void;
  appIconSize?: number;
}
export const AppItem: React.FC<AppItemProps> = ({
  name,
  icon,
  appIconSize,
  isSelected,
  onClick,
  disableHover,
  showDescription,
  isFavorite = true,
  toggleFavoriteApp,
  description,
  backgroundColor,
}) => {
  const theme = useTheme();
  return (
    <Box sx={theme => appIconStyle(theme, isSelected, disableHover, appIconSize)} onClick={onClick}>
      {toggleFavoriteApp &&
        ActionButton(
          isFavorite ? 'Remove from favorite' : 'Add to Favorite',
          toggleFavoriteApp,
          isFavorite ? starFullIcon(theme) : starLineIcon
        )}
      <Box sx={{ backgroundColor: (theme: any) => theme.palette.colors.neutrals[100], borderRadius: '8px' }}>
        <Box sx={{ p: '5px', background: backgroundColor, borderRadius: '8px', ...flex.center }}>{icon}</Box>
      </Box>
      <Box sx={{ ...flex.col }}>
        <Box sx={{ fontWeight: 600, color: (theme: any) => theme.palette.colors.neutrals[800] }}>{name}</Box>
        {showDescription && <Box sx={{ fontWeight: 400, color: (theme: any) => theme.palette.colors.neutrals[600] }}>{description}</Box>}
      </Box>
    </Box>
  );
};
const appIconStyle = (theme, isSelected, disableHover, appIconSize = 22) => ({
  ...flex.center,
  pl: '4px',
  pr: '12px',
  py: '4px',
  gap: '8px',
  borderRadius: '10px',
  cursor: 'pointer',
  color: theme.palette.colors.neutrals[600],
  border: `1px solid transparent`,
  transition: theme.transitions.create(['border-color'], {
    duration: theme.transitions.duration.shortest,
  }),
  svg: {
    ...iconSize(appIconSize),
  },
  ':hover': !disableHover && {
    borderColor: theme.palette.colors.neutrals[350],
  },
  ...(isSelected
    ? {
        borderColor: theme.palette.colors.neutrals[100],
        background: 'linear-gradient(45.32deg, rgba(197, 198, 214, 0.4) -3.85%, rgba(238, 238, 243, 0.4) 93.41%)',
        ':hover': !disableHover && {
          background: 'linear-gradient(45.32deg, rgba(197, 198, 214, 0.6) -3.85%, rgba(238, 238, 243, 0.6) 93.41%)',
        },
      }
    : {}),
});

export const appBarItemSelectedStyle = theme => ({
  svg: {
    color: theme.palette.colors.primary[500],
    ...iconSize(22),
  },
  backgroundColor: alpha(theme.palette.colors.primary[500], 0.1),
  color: theme.palette.colors.neutrals[800],
  ':hover': {
    backgroundColor: alpha(theme.palette.colors.primary[500], 0.15),
    color: theme.palette.colors.neutrals[800],
  },
});
export const appBarItemHoveredStyle = theme => ({
  ':hover': {
    backgroundColor: theme.palette.colors.neutrals[300],
    color: theme.palette.colors.neutrals[700],
    svg: {
      color: theme.palette.colors.neutrals[600],
    },
  },
});
