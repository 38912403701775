import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAvContext } from '../context/AvContextProvider';
import { SearchContext } from '../context/SearchContextProvider';
import { FeatureFlags } from '../types';

const transformFilter = filter =>
  Object.keys(filter).reduce((obj, key) => {
    const filteredKey = key.replace('amp;', '');
    return { ...obj, [filteredKey]: filter[key]?.length === 0 ? '' : filter[key] };
  }, {});

// eslint-disable-next-line import/prefer-default-export
export const useCustomSearchParams = ({
  defaultFilter = {},
  dateType = [],
  shouldBeArray,
  contextSearchKey = '',
}: {
  defaultFilter?: Record<string, string | string[] | Date | Date[] | number | null>;
  dateType?: string[];
  shouldBeArray?: (key: string) => boolean;
  contextSearchKey?: string;
}) => {
  const { featureFlags } = useAvContext();
  const location = useLocation();
  const { searchContext, setSearchContext } = useContext(SearchContext);
  const [search, setSearch] = useSearchParams(transformFilter(defaultFilter));

  useEffect(() => {
    if (searchContext[contextSearchKey]) {
      setSearch(transformFilter(searchContext[contextSearchKey]), { replace: true });
    }
  }, [location.search]);

  const setSearchParam = useCallback(
    filter => {
      if (contextSearchKey && featureFlags[FeatureFlags.KeepSessionFilters]) {
        setSearchContext(prev => ({ ...prev, [contextSearchKey]: { ...defaultFilter, ...filter } }));
      }
      setSearch(transformFilter(filter), { replace: true });
    },
    [setSearch, defaultFilter]
  );

  const entriesObj = useMemo(
    () =>
      searchContext[contextSearchKey as string] || {
        ...defaultFilter,
        ...Array.from(search.entries()).reduce((acc, [key, val]) => {
          const value = dateType.includes(key) ? (!val ? '' : new Date(val)) : val;
          const accVal = acc[key];
          if (accVal) {
            return { ...acc, [key]: [...(Array.isArray(accVal) ? accVal : [accVal]), value] };
          }
          return { ...acc, [key]: shouldBeArray?.(key) ? (value === '' ? [] : [value === 'null' ? null : value]) : value };
        }, {}),
      },
    [search, searchContext]
  );

  return [entriesObj, setSearchParam];
};

export const constructSearchParams = (filters: Record<string, string[]>) =>
  `?${Object.entries(filters)
    .map(([key, values]) => values.map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&'))
    .join('&')}`;
