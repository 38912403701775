import React from 'react';
import { Route } from 'react-router-dom';
import { NotFound } from '../components/ErrorBoundary';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';
import avLazy from '../utils/AvLazy';

const ContentPermissions = avLazy(() => import('../views/ContentPermissions/ContentPermissions'));
const AuditLogsContentPermissions = avLazy(() => import('../views/ContentPermissions/AuditLogsContentPermissions'));
const EditCustomRoles = avLazy(() => import('../views/CustomRoles/EditCustomRoles'));
const CustomRoles = avLazy(() => import('../views/CustomRoles/CustomRoles'));
const AuditLogsCustomRoles = avLazy(() => import('../views/CustomRoles/AuditLogsCustomRoles'));
const PermissionsRoute = () => {
  const {
    userPermissions: { hasAllowedPermission, isInternalRole },
  } = useAvContext();
  const contentPermissionsPath = PAGE_PATHS.CONTENT_PERMISSIONS;
  const rolesPath = PAGE_PATHS.ROLES;
  return (
    <Route path={PAGE_PATHS.PERMISSIONS}>
      <Route path="" element={<NotFound />} />
      <Route path={contentPermissionsPath}>
        <Route
          path=""
          element={
            <ProtectedRoute isAllowed={hasAllowedPermission({ path: contentPermissionsPath })}>
              <ContentPermissions />
            </ProtectedRoute>
          }
        />
        <Route
          path="create/"
          element={
            <ProtectedRoute isAllowed={hasAllowedPermission({ path: contentPermissionsPath, permission: Permission.CREATE })}>
              <ContentPermissions />
            </ProtectedRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <ProtectedRoute isAllowed={hasAllowedPermission({ path: contentPermissionsPath, permission: Permission.UPDATE })}>
              <ContentPermissions />
            </ProtectedRoute>
          }
        />
        <Route
          path="audit/:id"
          element={
            <ProtectedRoute isAllowed={isInternalRole}>
              <AuditLogsContentPermissions />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path={rolesPath}>
        <Route
          path=""
          element={
            <ProtectedRoute isAllowed={hasAllowedPermission({ path: rolesPath })}>
              <CustomRoles />
            </ProtectedRoute>
          }
        />
        <Route
          path="create/"
          element={
            <ProtectedRoute isAllowed={hasAllowedPermission({ path: rolesPath, permission: Permission.CREATE })}>
              <EditCustomRoles />
            </ProtectedRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <ProtectedRoute
              isAllowed={
                hasAllowedPermission({ path: rolesPath }) || hasAllowedPermission({ path: rolesPath, permission: Permission.UPDATE })
              }>
              <EditCustomRoles />
            </ProtectedRoute>
          }
        />
        <Route
          path="audit/:id"
          element={
            <ProtectedRoute isAllowed={isInternalRole}>
              <AuditLogsCustomRoles />
            </ProtectedRoute>
          }
        />
      </Route>
    </Route>
  );
};

export default PermissionsRoute;
