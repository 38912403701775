import { BaseWidget, WidgetCategory } from './base.types';

export enum TileSubType {
  Tile = 'TILE',
  Score = 'SCORE',
}

export type TileWidget = BaseWidget & {
  category: WidgetCategory.Tile;
  type: TileSubType;
};

export type TileTypeWidget = TileWidget & {
  type: TileSubType.Tile;
};

export type ScoreTypeWidget = TileWidget & {
  type: TileSubType.Score;
};

export type TileCategoryWidgetTypes = TileTypeWidget | ScoreTypeWidget;
