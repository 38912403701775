export const getAccountIdFromPathName = pathname => pathname?.split?.('/')?.[pathname.indexOf('AVA-') > -1 ? 2 : 1];
export const getAppIdFromPathName = pathname => pathname?.split?.('/')?.[pathname.indexOf('AVA-') > -1 ? 3 : 2];

export const getBranchPrefix = () => {
  const regex = /\/AVA-(\d+)\//;
  const match = window.location.pathname.match(regex);
  if (match) {
    return `AVA-${match[1]}`;
  }
  return '';
};

export const getPathNameByAccount =
  (accountId, selectedApp: string) =>
  (path, rest = '', newSelectedApp = selectedApp) =>
    `${getBranchPrefix()}/${accountId}/${newSelectedApp}/${path}${rest}`;

export const getAccountFromLS = () => localStorage.getItem('accountId');
export const setAccountFromLS = accountId => localStorage.setItem('accountId', accountId);
